import React, { Fragment } from 'react';
import {
    FlexStartRow,
    getTenantSetting,
    onFieldChange,
    toasty,
} from '../common/forms/FormElements';
import { Address } from '../address/Address';
import { EmployeePayRate, EmployeePayRateForm } from './EmployeePayRateForm';
import { Can } from '../Can';
import CommonContext, {
    ApiRoutes,
    ApplicationPermissions,
    AppNavPaths,
} from '../Common';
import { Employee, EmployeeLocationTypes } from './Employee';
import { EmployeeAddressInfo } from './EmployeeAddressInfo';
import { EmployeeComplianceInfo } from './EmployeeComplianceInfo';
import EmployeeComplianceForm from './EmployeeComplianceForm';
import './EmployeeForm.scss';
import { isEqual, debounce } from 'lodash-es';
import { EmployeePayRateInfo } from './EmployeePayRateInfo';
import {
    EmployeeRestriction,
    EmployeeRestrictionForm,
} from './EmployeeRestrictionForm';
import { EmployeeRestrictionInfo } from './EmployeeRestrictionInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTasks, faUser } from '@fortawesome/free-solid-svg-icons';
import {
    AppPageForm,
    FlexCenterRow,
    FormCheckbox,
    FormGroupColumn,
    FormLabel,
    GroupedRow,
    SubHeading,
} from '../common/forms/FormElements';
import { EmployeeLongTermStatusInfo } from './EmployeeLongTermStatusInfo';
import { EmployeeLongTermStatusForm } from './EmployeeLongTermStatusForm';
import { LinearProgress } from '@material-ui/core';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { Button, Col, FormGroup } from 'reactstrap';
import { Prompt, withRouter } from 'react-router-dom';
import AddressFormNew from '../address/AddressFormNew';
import { handleFormSaveError } from '../common/forms/ValidationError';
import authService from '../api-authorization/AuthorizeService';
import { getTenantUserProfile } from '../common/TenantUserProfile';
import EmployeeHistoryForm from './EmployeeHistoryForm';

//#region CONSTANTS
const AccountStatus = {
    Active: 1,
    Inactive: 2,
    NewHire: 3,
};

//#endregion

class EmployeeForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.addressFormRef = React.createRef();
        this.complianceFormRef = React.createRef();
        this.employeeLongTermStatusFormRef = React.createRef();
        this.employeeHistoryFormRef = React.createRef();

        this.state = {
            addressFormTitle: '',
            addressFormValidated: false,
            companies: [],
            employee: new Employee(),
            formOpened: false,
            formValidated: false,
            groups: [],
            loading: true,
            dispatchCompanies: [],
            expandedDispatchCompanies: [],
            onboarding: false,
            payRateFormValidated: false,
            restrictionFormValidated: false,
            saving: false,
            selectedAddress: null,
            selectedPayRate: null,
            selectedRestriction: null,
            selectedEmployeeCompliance: null,
            statuses: [],
            showAddressForm: false,
            showPayRateForm: false,
            showRestrictionForm: false,
            showComplianceForm: false,
            showEmployeeLongTermStatusForm: false,
            showEmployeeHistoryForm: false,
            perms: [],
            isAdmin: false,
            longTermStatusManagementEnabled: false,
            employeeLongTermStatuses: [],
            selectedEmployeeLongTermStatus: null,
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onAddAddress = this.onAddAddress.bind(this);
        this.onEditAddress = this.onEditAddress.bind(this);
        this.onSaveAddress = this.onSaveAddress.bind(this);
        this.saveEmployee = this.saveEmployee.bind(this);
        this.saveNewEmployee = this.saveNewEmployee.bind(this);
        this.onEditPayRate = this.onEditPayRate.bind(this);
        this.onSavePayRate = this.onSavePayRate.bind(this);
        this.onEditRestriction = this.onEditRestriction.bind(this);
        this.onSaveRestriction = this.onSaveRestriction.bind(this);
        this.onAddEmployeeCompliance = this.onAddEmployeeCompliance.bind(this);
        this.onEditEmployeeCompliance =
            this.onEditEmployeeCompliance.bind(this);
        this.onSaveCompliance = this.onSaveCompliance.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
        this.getTenantSetting = getTenantSetting.bind(this);
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    };

    componentWillUnmount = async () => {
        await authService.unsubscribe(this._subscription);
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps &&
            this.props.match.params.id !== (prevProps.match.params ?? {}).id
        ) {
            this.populateState();
        }
    };

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            let employee = null;
            const { id } = { ...this.props.match.params };
            const { search } = { ...this.props.location };
            const { currentUser, userTenant, userPermissions } =
                await getTenantUserProfile();
            const { tenantSettings } = { ...userTenant };
            const userRoles = currentUser.roles.map((x) => x.name);
            const userIsAdmin = userRoles.includes('Administrator');

            //Are we onboarding?
            const onboardingRouted =
                !!search && new URLSearchParams(search).get('newHire');

            //Turn on onboarding iff routing + tenant has it enabled.
            const onboarding =
                tenantSettings.employeeOnboardingEnabled && onboardingRouted;

            //If onboarding a new emp, first check the user has the perm.
            if (
                !!onboarding &&
                !id &&
                (!tenantSettings.employeeOnboardingEnabled ||
                    !userPermissions.includes(
                        ApplicationPermissions.employee_onboarding
                    ))
            ) {
                this.props.history.push(AppNavPaths.NoPermission);
                return false;
            }

            //If not onboarding a new emp, make sure the user has the regular emp add perm.
            if (
                !onboarding &&
                !id &&
                !userPermissions.includes(ApplicationPermissions.employee_add)
            ) {
                this.props.history.push(AppNavPaths.NoPermission);
                return false;
            }

            //Permissions for new emp good, get the rest of the data.
            let [
                groups,
                employeeResponse,
                companies,
                statuses,
                employeeLongTermStatuses,
            ] = await Promise.all([
                util.fetch.js(ApiRoutes.auth.groups()),
                !!id
                    ? util.fetch.get(
                          ApiRoutes.employee.byId(id),
                          util.fetch.format.none
                      )
                    : new Employee(),
                util.fetch.js(ApiRoutes.company.payrollCompanies()),
                util.fetch.js(ApiRoutes.employeeAccountStatuses.all()),
                !!id
                    ? util.fetch.js(
                          ApiRoutes.employeeLongTermStatus.allForEmployee(id)
                      )
                    : [],
            ]);

            //Handle any issues when fetching the employee.
            if (!!id && !employeeResponse.ok) {
                //Handle erroneous links entered by the user.
                if (employeeResponse.status === 404)
                    this.props.history.push(AppNavPaths.NotFound);
                else this.props.history.push(AppNavPaths.ServerError);
                return false;
            } else {
                employee = !!id
                    ? await employeeResponse.json()
                    : employeeResponse;

                // temporary variable (dbGroups) used as source of truth to define if employee can be impersonanted
                // user probably should not be able to impersonate selected employee until 
                // new set of employee groups stored in database
                const dbGroups = employee.groups ?? [];
                employee.dbGroups = [...dbGroups];
            }

            /**
             * For the onboarding process:
             *  - lock the emp's dispatch to the current user's dispatch company
             *  - lock the emp's parent company to the current user's parent company
             *  - default the user's group to the default group in tenant settings
             *  - set the initial account status
             **/
            if (!!onboarding && !id && !employee.id) {
                employee.companyId = currentUser.companyId; //parent company
                employee.dispatchCompanyId = currentUser.dispatchCompanyId; //dispatch company
                employee.accountStatus = statuses.find(
                    (x) => x.id === AccountStatus.NewHire
                );

                let empGroup = groups.find(
                    (x) =>
                        x.id === tenantSettings.employeeOnboardingDefaultGroup
                );
                employee.groups = [empGroup];
                employee.dbGroups = [empGroup];
            }

            if (employee.accountStatus?.id !== AccountStatus.NewHire) {
                //Don't display unconfirmed status if not onboarding.
                statuses = statuses.filter(
                    (x) => x.id !== AccountStatus.NewHire
                );
            }

            let dispatchCompanies = [];

            //List dispatch companies for an existing, non-onboarded emp
            //Or for global admins (who dont have dispatch companies assigned) get the tenant dispatch companies.
            //Or list dispatch companies for the user onboarding the employee.
            if (!!id && !!employee.companyId) {
                dispatchCompanies = await util.fetch.js(
                    ApiRoutes.company.dispatchCompaniesByCompanyId(
                        employee.companyId
                    )
                );
            } else if (userIsAdmin) {
                dispatchCompanies = await util.fetch.js(
                    ApiRoutes.company.dispatchCompanies()
                );
            } else if (employee.accountStatus?.id === AccountStatus.NewHire) {
                dispatchCompanies = await util.fetch.js(
                    ApiRoutes.company.dispatchCompaniesByCompanyId(
                        currentUser.tenantId
                    )
                );
            }

            let expandedDispatchCompanies = await util.fetch.js(
                ApiRoutes.company.dispatchCompanies()
            );

            let availableEmployeeComplianceTypes = [];
            let selectedEmployeeCompliances = [];

            const complianceEnabled = tenantSettings.complianceEnabled;
            const longTermStatusManagementEnabled =
                tenantSettings.employeeLongTermStatusManagementEnabled;

            // bugbug: should we restrict compliance availability for onboarding/new hires?

            if (!!employee.id && !!complianceEnabled) {
                [
                    availableEmployeeComplianceTypes,
                    selectedEmployeeCompliances,
                ] = await Promise.all([
                    util.fetch.js(
                        ApiRoutes.complianceTypes.allEmployeeComplianceTypes()
                    ),
                    util.fetch.js(
                        ApiRoutes.employeeCompliances.allForEmployee(
                            employee.id
                        )
                    ),
                ]);
            }

            this.setState((state) => {
                return {
                    formValidated: false,
                    addressFormValidated: false,
                    payRateFormValidated: false,
                    restrictionFormValidated: false,
                    saving: false,
                    selectedAddress: null,
                    selectedPayRate: null,
                    selectedRestriction: null,
                    companies: companies,
                    employee: employee,
                    groups: groups,
                    loading: false,
                    originalData: employee,
                    dispatchCompanies: dispatchCompanies,
                    statuses: statuses,
                    perms: userPermissions,
                    currentUser: currentUser,
                    isAdmin: userIsAdmin,
                    onboarding: onboarding,
                    availableEmployeeComplianceTypes,
                    selectedEmployeeCompliances,
                    complianceEnabled,
                    expandedDispatchCompanies: expandedDispatchCompanies,
                    employeeLongTermStatuses,
                    longTermStatusManagementEnabled,
                };
            });
        }
    }

    //#region METHODS
    onChange = onFieldChange;

    onGroupChange = (value) => {
        let { employee } = { ...this.state };

        employee.groups = value;

        //If we don't have a group selected with expanded dispatching,
        //make sure we clear out any previous selections.
        if (!this.showExpandDispatching())
            employee.expandedDispatchCompanies = [];

        this.setState({ employee: employee });
    };

    onDispatchCompanyChanged = (value) => {
        this.setState((state) => {
            var emp = state.employee;
            if (!!this.state.onboarding && !!this.state.isAdmin) {
                emp.companyId = value.parentCompanyId;
            }
            emp.dispatchCompanyId = value.id;
            return { employee: emp };
        });
    };

    onExpandedDispatchCompaniesChanged = (values) => {
        let { employee } = { ...this.state };
        if ((values ?? []).length > 0)
            employee.expandedDispatchCompanies = values.map((x) => x.id);
        else employee.expandedDispatchCompanies = [];
        this.setState({ employee: employee });
    };

    onCompanyChanged = async (item) => {
        let dComps = await util.fetch.js(
            ApiRoutes.company.dispatchCompaniesByCompanyId(item.id)
        );

        this.setState((state) => {
            var emp = state.employee;
            emp.companyId = item.id;
            return { employee: emp, dispatchCompanies: dComps };
        });
    };

    onAccountStatusChanged = (selection) => {
        this.setState((state) => {
            let { employee, statuses } = state;
            employee.accountStatus = statuses.find(
                (s) => s.id === selection.id
            );
            if (employee.accountStatus.id == AccountStatus.Inactive) {
                employee.systemAdded = false;
            }
            return { employee: employee };
        });
    };

    onSMSNotifyChanged = (e) => {
        var emp = this.state.employee;
        emp.smsNotify = e.target.checked;
        this.setState((state) => {
            return { employee: emp };
        });
    };

    onEmailNotifyChanged = (e) => {
        var emp = this.state.employee;
        emp.emailNotify = e.target.checked;
        this.setState({ employee: emp });
    };

    onIncludeImportChanged = (e) => {
        var emp = this.state.employee;
        emp.systemAdded = e.target.checked;
        this.setState({ employee: emp });
    };

    onExcludeDispatchChanged = (e) => {
        var emp = this.state.employee;
        emp.excludeDispatch = e.target.checked;
        this.setState({ employee: emp });
    };

    onAddAddress = (type) => {
        let { employee } = this.state;
        let new_address = new Address();
        //This is a new address, embed the emp Id and company.
        new_address.employeeId = employee.id;
        new_address.name = `Home - ${employee.firstName} ${employee.lastName}`;
        new_address.employeeLocationTypeId =
            type === 'home'
                ? EmployeeLocationTypes.Home
                : EmployeeLocationTypes.Routing;
        new_address.employeeLocationType = {
            id:
                type === 'home'
                    ? EmployeeLocationTypes.Home
                    : EmployeeLocationTypes.Routing,
            name: type === 'home' ? 'Employee' : 'Employee Routing',
        }; //TODO: remove inline ints
        let formTitle = `New ${type === 'home' ? 'Home' : 'Routing'} Address`;
        this.addressFormRef.current.open(new_address, formTitle);
    };

    //Address table edit click handler
    onEditAddress(address) {
        let formTitle = `Edit ${
            address.employeeLocationType.id === EmployeeLocationTypes.Home
                ? 'Home'
                : 'Routing'
        } Address`;
        this.addressFormRef.current.open({ ...address }, formTitle);
    }

    //After address save callback
    onSaveAddress = (address) => {
        let { employee } = this.state;
        employee.addresses = util.array.upsert(
            address,
            employee.addresses,
            'id'
        );
        this.setState({ employee: employee, selectedAddress: null });
    };

    onAddPayRate = () => {
        let { employee } = this.state;
        let new_pay_rate = new EmployeePayRate();
        new_pay_rate.employeeId = employee.id;
        this.setState({
            selectedPayRate: new_pay_rate,
            showPayRateForm: true,
            payRateFormValidated: false,
        });
        this.context.setFormOpened(true);
    };

    onEditPayRate(payRate) {
        this.context.setFormOpened(true);
        this.setState({
            selectedPayRate: util.object.clone(payRate),
            showPayRateForm: true,
            payRateFormValidated: false,
        });
    }

    onViewChangeHistory = () => {
        this.context.setFormOpened(true);

        const { employee } = this.state;

        this.employeeHistoryFormRef.current.open(employee);

        this.setState({ showEmployeeHistoryForm: true });
    };

    onClickImpersonation = async () => {
        let { employee } = this.state;

        try {
            await util.fetch.put(
                ApiRoutes.employee.toggleImpersonation(),
                { EmployeeId: employee.id },
                util.fetch.format.none
            );

            window.location.href = '/';
        } catch (error) {
            this.notifyError(error.toString());
        }
    };

    onClickCancelImpersonation = async () => {
        let { employee } = this.state;

        try {
            await util.fetch.put(
                ApiRoutes.employee.cancelImpersonation(),
                { EmployeeId: employee.id },
                util.fetch.format.json
            );

            this.notifySuccess();
            this.populateState();
        } catch (error) {
            this.notifyError(error.toString());
        }
    }

    onCloseChangeHistory = () => {
        this.context.setFormOpened(false);

        this.setState({ showEmployeeHistoryForm: false });
    };

    onPayRateChange = (e) => {
        let { selectedPayRate } = this.state;
        selectedPayRate[e.target.name] = e.target.value;
        this.setState({ selectedPayRate: selectedPayRate });
    };

    onSavePayRate() {
        let { selectedPayRate, employee } = this.state;
        let existing = employee.payRates.find(
            (x) => x.id === selectedPayRate.id
        );

        if (!!existing)
            employee.payRates[employee.payRates.indexOf(existing)] =
                selectedPayRate;
        else employee.payRates.push(util.object.clone(selectedPayRate));

        this.setState({
            employee: employee,
            showPayRateForm: false,
            selectedPayRate: null,
            payRateFormValidated: false,
        });

        this.context.setFormOpened(false);
    }

    onAddRestriction = () => {
        let { employee } = this.state;
        let new_restriction = new EmployeeRestriction();
        new_restriction.employeeId = employee.id;
        this.setState({
            selectedRestriction: new_restriction,
            showRestrictionForm: true,
            restrictionFormValidated: false,
        });
        this.context.setFormOpened(true);
    };

    onEditRestriction = async (restriction) => {
        this.context.setFormOpened(true);
        let cloneRestriction = util.object.clone(restriction);

        this.setState({
            selectedRestriction: cloneRestriction,
            showRestrictionForm: true,
            restrictionFormValidated: false,
        });
    };

    onRestrictionChange = (e) => {
        let { selectedRestriction } = this.state;
        e.target.value =
            e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        selectedRestriction[e.target.name] = e.target.value;
        this.setState({ selectedRestriction: selectedRestriction });
    };

    onSelectedRestrictionCompanyChanged = async (value) => {
        this.setState((state) => {
            let { selectedRestriction } = this.state;
            selectedRestriction.companyId = (value ?? {}).id ?? '';
            selectedRestriction.companyName = (value ?? {}).companyName ?? '';
            return { selectedRestriction: selectedRestriction };
        });
    };

    onSelectedRestrictionForemenChanged = (value) => {
        this.setState((state) => {
            let { selectedRestriction } = this.state;
            selectedRestriction.foremenId = (value ?? {}).id ?? '';
            selectedRestriction.foremenName = (value ?? {}).fullName ?? '';
            return { selectedRestriction: selectedRestriction };
        });
    };

    onSaveRestriction() {
        let { selectedRestriction, employee } = this.state;
        let existing = employee.restrictions.find(
            (x) => x.id === selectedRestriction.id
        );

        if (!!existing)
            employee.restrictions[employee.restrictions.indexOf(existing)] =
                selectedRestriction;
        else employee.restrictions.push(util.object.clone(selectedRestriction));

        this.setState({
            employee: employee,
            showRestrictionForm: false,
            selectedRestriction: null,
            restrictionFormValidated: false,
        });

        this.context.setFormOpened(false);
    }

    onAddEmployeeCompliance = () => {
        let { employee } = this.state;

        this.complianceFormRef.current.openEmployee(employee.id);
        this.setState({
            showComplianceForm: true,
            employeeId: employee.id,
            selectedEmployeeCompliance: null,
        });
    };

    onEditEmployeeCompliance = (suppliedCompliance) => {
        if (suppliedCompliance.id) {
            this.complianceFormRef.current.openEmployeeCompliance(
                suppliedCompliance.id
            );
            this.setState({
                showComplianceForm: true,
            });
        }
    };

    onSaveCompliance = async () => {
        let { employee } = this.state;

        let selectedEmployeeCompliances = await util.fetch.js(
            ApiRoutes.employeeCompliances.allForEmployee(employee.id)
        );

        this.setState({
            selectedEmployeeCompliances,
            showComplianceForm: false,
            selectedEmployeeCompliance: null,
        });

        this.context.setFormOpened(false);
    };

    toggleEmployeeComplianceForm = (show) =>
        this.setState(
            (state) => ((state.showEmployeeComplianceForm = show), state)
        );

    onActiveClicked = (e) => {
        this.setState((state) => {
            var emp = state.employee;
            emp.userDetails.active = true;
            return { employee: emp };
        });
    };

    onInactiveClicked = (e) => {
        this.setState((state) => {
            var emp = state.employee;
            emp.userDetails.active = false;
            return { employee: emp };
        });
    };

    async updateEmployee() {
        let { employee } = this.state;
        var emp = await util.fetch.js(ApiRoutes.employee.byId(employee.id));
        this.setState({ employee: emp });
    }

    onSubmit() {
        let { employee } = { ...this.state };
        const { tenant } = { ...this.context };
        const { employeeOnboardingEnabled } = { ...tenant.tenantSettings };

        if (
            !!employeeOnboardingEnabled &&
            employee.accountStatus?.id === AccountStatus.NewHire &&
            !employee.id
        ) {
            //Validation
            if (!employee.userName) {
                toasty.error(
                    'Please generate the username before saving the employee.'
                );
                return false;
            }
        }

        /** Revert temporary hashes used in row keys. */
        for (let addr of employee.addresses) {
            addr.id = addr.id.constructor === String ? null : addr.id;
        }

        //Clear any fluent api errors
        this.clearSaving();

        this.setState((state) => {
            return { errors: {}, saving: true };
        });

        if (this.props.location.pathname === AppNavPaths.EmployeeNew)
            this.saveNewEmployee(employee);
        else this.saveEmployee(employee);
    }

    onAddEmployeeLongTermStatus = () => {
        let { employee } = this.state;

        this.employeeLongTermStatusFormRef.current.openEmployee(employee.id);
        this.setState({
            showEmployeeLongTermStatusForm: true,
            employeeId: employee.id,
            selectedEmployeeLongTermStatus: null,
        });
    };

    onEditEmployeeLongTermStatus = (suppliedLongTermStatus) => {
        if (suppliedLongTermStatus.id) {
            this.employeeLongTermStatusFormRef.current.openEmployeeLongTermStatus(
                suppliedLongTermStatus
            );
            this.setState({
                showEmployeeLongTermStatusForm: true,
            });
        }
    };

    onSaveLongTermStatus = async () => {
        let { employee } = this.state;

        const employeeLongTermStatuses = await util.fetch.js(
            ApiRoutes.employeeLongTermStatus.allForEmployee(employee.id)
        );

        this.setState({
            employeeLongTermStatuses,
            showEmployeeLongTermStatusForm: false,
            selectedEmployeeLongTermStatus: null,
        });

        this.context.setFormOpened(false);
    };

    toggleEmployeeLongTermStatusForm = (show) =>
        this.setState(
            (state) => ((state.showEmployeeLongTermStatusForm = show), state)
        );

    clearSaving = () =>
        this.setState((state) => {
            return { saving: false, errors: {} };
        });

    notifySuccess = () =>
        toasty.success('Employee Saved', `Employee saved successfully.`);
    notifyError = (message) => toasty.error('Save Unsuccessful', message);
    handleSaveError = (err) => handleFormSaveError(this, err);

    saveNewEmployee = async (employee) => {
        try {
            let response = await util.fetch.post(
                ApiRoutes.employee.create(),
                employee,
                util.fetch.format.none
            );
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    if (employee.accountStatus.id === AccountStatus.NewHire) {
                        this.props.history.push(`${AppNavPaths.Employees}`);
                    } else {
                        var employeeId = serviceResponse.data;
                        this.props.history.push(
                            `${AppNavPaths.Employee}/${employeeId}`
                        );
                    }
                } else {
                    this.notifyError(serviceResponse.message);
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    };

    saveEmployee = async (employee) => {
        try {
            let response = await util.fetch.put(
                ApiRoutes.employee.update(employee.id),
                employee,
                util.fetch.format.none
            );
            if (response.redirected) {
                window.location.href = response.url;
            } else if (!!response.ok) {
                var serviceResponse = await response.json();
                if (serviceResponse.result === 'SUCCESS') {
                    this.notifySuccess();
                    this.populateState();
                } else {
                    this.notifyError(serviceResponse.message);
                }
            }
        } catch (error) {
            this.notifyError(error.toString());
        } finally {
            this.clearSaving();
        }
    };

    onFirstNameChanged = debounce(async (event) => {
        const value = event.target.value;
        const { employee } = { ...this.state };

        employee.firstName = value;

        await this.setState({ employee: { ...employee } });

        if (!!value && !!employee.lastName) this.onGetUsername();
    }, 500);

    onLastNameChanged = debounce(async (event) => {
        const value = event.target.value;
        const { employee } = { ...this.state };

        employee.lastName = value;

        await this.setState({ employee: { ...employee } });

        //anytime the name changes, the user needs to get a generated username again.
        if (!!value && !!employee.firstName) this.onGetUsername();
    }, 500);

    onGetUsername = async () => {
        const { employee } = { ...this.state };
        const username = `${employee.firstName.charAt(0)}${employee.lastName}`
            .replace(/[^a-zA-Z0-9]/g, '')
            .toLowerCase();
        try {
            const suggestion = await util.fetch.js(
                ApiRoutes.employee.suggestUsername(username)
            );
            employee.userName = suggestion;
            await this.setState({ employee: { ...employee } });
        } catch {
            toasty.error(
                'There was a server error when retrieving a username suggestion.  Please try your request again or contact support.'
            );
            return null;
        }
    };

    onMobilePhoneChanged = (event) => {
        const value = event.target.value;
        const { employee } = { ...this.state };
        employee.mobilePhone = value;
        if (!!value && !!util.validation.phone(value))
            employee.smsNotify = true;
        this.setState({ employee: { ...employee } });
    };

    onEmailChanged = (event) => {
        const value = event.target.value;
        const { employee } = { ...this.state };
        employee.email = value;
        if (!!value) employee.emailNotify = true;
        this.setState({ employee: { ...employee } });
    };
    //#endregion

    //#region RENDERING
    getReadOnlyLabel = (text) => (
        <span className="ml-3 pb-3 text-muted font-weight-bold"> {text}</span>
    );

    passwordFields() {
        //Only required for new employees.
        //If password is empty for an existing employee, that's OK - password will be unchanged.
        const isPasswordRequired = !!!this.state.employee.id;

        const helpMessage = isPasswordRequired
            ? null
            : "Leave blank if you do not want to reset the user's password.  If you change a password, the user will be required to update it on next login.";

        return (
            <>
                <FormGroup>
                    <FormLabel
                        htmlFor="password"
                        text="Password"
                        required={isPasswordRequired}
                        helpMessage={helpMessage}
                    />
                    <input
                        id="password"
                        name="employee.password"
                        autoComplete="new-password"
                        type="password"
                        className="form-control"
                        required={isPasswordRequired}
                        onChange={this.onChange}
                        defaultValue={this.state.employee.password}
                    />
                    <small className="invalid-feedback text-danger" hidden>
                        A Password is required.
                    </small>
                </FormGroup>
                <FormGroup hidden={!isPasswordRequired}>
                    <FormLabel
                        htmlFor="confirmPassword"
                        text="Confirm Password"
                        required={isPasswordRequired}
                    />
                    <input
                        id="confirmPassword"
                        name="employee.confirmPassword"
                        autoComplete="new-password"
                        type="password"
                        className="form-control"
                        required={isPasswordRequired}
                        onChange={this.onChange}
                        defaultValue={this.state.employee.confirmPassword}
                    />
                    <small className="invalid-feedback text-danger" hidden>
                        Password Confirmation is required and must match the
                        password.
                    </small>
                </FormGroup>
            </>
        );
    }

    hasAvailableCompliance = () => {
        const { employee, availableEmployeeComplianceTypes } = this.state;

        const employeeGroupIds = employee.groups.map((g) => g.id);

        return availableEmployeeComplianceTypes.some((ec) =>
            ec.complianceApplicationUserGroups.some((caug) =>
                employeeGroupIds.includes(caug.applicationUserGroupId)
            )
        );
    };

    //Show multi dispatch selection if at least one group has been toggled as Expand Dispatching.
    showExpandDispatching = () => {
        const { employee } = { ...this.state };
        return (
            (
                (employee?.groups ?? []).filter((x) => !!x.expandDispatching) ??
                []
            ).length > 0
        );
    };

    canBeImpersonated = () => {
        const { employee, groups } = this.state;
        let canBeImpersonated = false;
        if (employee?.dbGroups && groups) {
            canBeImpersonated = employee.dbGroups.some((group) =>
                groups?.some(
                    (systemGroup) =>
                        systemGroup.id === group.id &&
                        (systemGroup.isImpersonatedGroup ||
                            systemGroup.isFieldGroup)
                )
            );
        }

        return canBeImpersonated;
    };

    render() {
        const {
            expandedDispatchCompanies,
            companies,
            loading,
            originalData,
            employee,
            groups,
            dispatchCompanies,
            statuses,
            perms,
            isAdmin,
            selectedEmployeeCompliances,
            availableEmployeeComplianceTypes,
            selectedEmployeeCompliance,
            complianceEnabled,
            longTermStatusManagementEnabled,
            employeeLongTermStatuses,
            selectedEmployeeLongTermStatus,
            showEmployeeLongTermStatusForm,
            showEmployeeHistoryForm,
        } = { ...this.state };

        const employeeArray = [employee];

        if (employee) {
            employeeArray[0].displayName = `${employee.lastName}, ${employee.firstName}`;
        }

        if (
            !!loading ||
            !this.context?.tenant?.tenantSettings ||
            !groups.length ||
            !perms.length
        ) {
            return <LinearProgress variant="indeterminate" color="secondary" />;
        } else {
            const adminSelected = (employee.groups || [])
                .map((v, i) => v.groupName)
                .includes('Administrator');
            const { employeeOnboardingEnabled } = {
                ...this.context.tenant.tenantSettings,
            };
            const { employeeImportSwitchEnabled } = {
                ...this.context.tenant.tenantSettings,
            };
            const new_emp = (employee.id ?? 0) <= 0;
            const pwdFields = this.passwordFields();
            const isOnboarding =
                !!employeeOnboardingEnabled &&
                employee.accountStatus?.id === AccountStatus.NewHire;
            const saveEmployeeButtonText = !new_emp
                ? 'Save'
                : !!isOnboarding
                ? 'Save New Hire'
                : 'Save New Employee';

            //field editing permissions
            const canEdit = {
                group: !!perms.includes(
                    ApplicationPermissions.employee_group_edit
                ),
                name: !!perms.includes(
                    ApplicationPermissions.employee_name_edit
                ),
                username: !!perms.includes(
                    ApplicationPermissions.employee_username_edit
                ),
                company: !!perms.includes(
                    ApplicationPermissions.employee_payroll_company_edit
                ),
                dispatchCompany: !!perms.includes(
                    ApplicationPermissions.employee_dispatch_company_edit
                ),
                number: !!perms.includes(
                    ApplicationPermissions.employee_number_edit
                ),
                notifications: !!perms.includes(
                    ApplicationPermissions.employee_notifications_edit
                ),
                import: !!perms.includes(
                    ApplicationPermissions.employee_importupdates_edit
                ),
                excludeDispatch: !!perms.includes(
                    ApplicationPermissions.employee_exclude_dispatch
                ),
                status: !!perms.includes(
                    ApplicationPermissions.employee_accountstatus_edit
                ),
                password: !!perms.includes(
                    ApplicationPermissions.employee_password_edit
                ),
                homeAddress: !!perms.includes(
                    ApplicationPermissions.employee_home_address_edit
                ),
                routingAddress: !!perms.includes(
                    ApplicationPermissions.employee_routing_address_edit
                ),
                compliances: !!perms.includes(
                    ApplicationPermissions.applicationusercompliance_edit
                ),
            };

            const canView = {
                employeeHistory: perms.includes(
                    ApplicationPermissions.employee_view_history
                ),
            };

            //For expanded dispatch options, no need to include employee's default dispatch in the list.  Filter it out.
            const expandedDispatchOptions =
                (expandedDispatchCompanies ?? []).filter(
                    (x) => x.id !== employee.dispatchCompanyId
                ) ?? [];
            const selectedExpandedDispatchCompanies =
                expandedDispatchOptions.filter((x) =>
                    (employee?.expandedDispatchCompanies ?? []).includes(x.id)
                );

            return (
                <Fragment>
                    <AppPageForm
                        formId={'empForm'}
                        formHeadingIcon={faUser}
                        formHeading={new_emp ? 'New Employee' : 'Edit Employee'}
                        formName={'empForm'}
                        formRef={this.formRef}
                        onSubmit={this.onSubmit}
                        setIsValidated={(value) => {
                            this.setState({ formValidated: value });
                        }}
                        isValidated={this.state.formValidated}
                        saving={this.state.saving}
                        errors={this.state.errors}
                        loading={this.state.loading}
                    >
                        <SubHeading className="pt-3">
                            {!!isOnboarding
                                ? `Onboarding Information`
                                : `General Information`}

                            {Boolean(!new_emp && canView.employeeHistory) && (
                                <Button
                                    className="ml-5"
                                    color="primary"
                                    onClick={this.onViewChangeHistory}
                                >
                                    <FontAwesomeIcon icon={faTasks} />
                                    View Change History
                                </Button>
                            )}

                            {Boolean(!new_emp) && (
                                <Can I="toggle" a="impersonation">
                                    {Boolean(employee.impersonating) ? (
                                        <Button
                                            className="ml-5"
                                            color="warning"
                                            onClick={
                                                this.onClickCancelImpersonation
                                            }
                                        >
                                            Cancel Impersonation
                                        </Button>
                                    ) : (
                                        <>
                                            {this.canBeImpersonated() && (
                                                <Button
                                                    className="ml-5"
                                                    color="info"
                                                    onClick={
                                                        this
                                                            .onClickImpersonation
                                                    }
                                                >
                                                    Impersonate
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </Can>
                            )}
                        </SubHeading>
                        <GroupedRow>
                            <FormGroupColumn>
                                {!isOnboarding && (
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="selectUserGroup"
                                            text="Groups"
                                            required={true}
                                        />
                                        <ValidatedSelect
                                            id="groups"
                                            name="groups"
                                            isMulti
                                            required
                                            isDisabled={!canEdit.group}
                                            options={groups}
                                            value={employee.groups ?? ''}
                                            getOptionLabel={(option) =>
                                                option.groupName
                                            }
                                            getOptionValue={(option) =>
                                                option.id
                                            }
                                            onChange={
                                                !!canEdit.group
                                                    ? this.onGroupChange
                                                    : undefined
                                            }
                                            validationMessage="At least one group selection is required."
                                        />
                                    </FormGroup>
                                )}
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="inputFirstName"
                                        text="First Name"
                                        required={true}
                                    />
                                    <input
                                        id="firstName"
                                        name="employee.firstName"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!new_emp && !canEdit.name}
                                        onChange={
                                            !!isOnboarding
                                                ? (event) => {
                                                      event.persist();
                                                      this.onFirstNameChanged(
                                                          event
                                                      );
                                                  }
                                                : !!canEdit.name
                                                ? this.onChange
                                                : undefined
                                        }
                                        defaultValue={employee.firstName}
                                    />
                                    <small
                                        className="invalid-feedback text-danger"
                                        hidden
                                    >
                                        First name is required.
                                    </small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="inputLastName"
                                        text="Last Name"
                                        required={true}
                                    />
                                    <input
                                        id="lastName"
                                        name="employee.lastName"
                                        autoComplete="off"
                                        className="form-control"
                                        required
                                        disabled={!new_emp && !canEdit.name}
                                        onChange={
                                            !!isOnboarding
                                                ? (event) => {
                                                      event.persist();
                                                      this.onLastNameChanged(
                                                          event
                                                      );
                                                  }
                                                : !!canEdit.name
                                                ? this.onChange
                                                : undefined
                                        }
                                        defaultValue={employee.lastName}
                                    />
                                    <small
                                        className="invalid-feedback text-danger"
                                        hidden
                                    >
                                        Last name is required.
                                    </small>
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="userName"
                                        text="Username"
                                        required={true}
                                    />
                                    {!!isOnboarding &&
                                        (!employee.lastName ||
                                            !employee.firstName) && (
                                            <FlexStartRow className="pl-3 pb-3 text-warning">
                                                <i className="mr-2 fa fa-exclamation-triangle"></i>
                                                <small>
                                                    Complete the user's first
                                                    name and last name to
                                                    generate the username.
                                                </small>
                                            </FlexStartRow>
                                        )}
                                    {!!isOnboarding &&
                                        employee.lastName &&
                                        employee.firstName &&
                                        employee.userName &&
                                        this.getReadOnlyLabel(
                                            employee.userName
                                        )}
                                    {!isOnboarding && (
                                        <>
                                            <input
                                                id="userName"
                                                disabled={!canEdit.username}
                                                name="employee.userName"
                                                autoComplete="off"
                                                className="form-control"
                                                required
                                                onChange={
                                                    !!canEdit.username
                                                        ? this.onChange
                                                        : undefined
                                                }
                                                defaultValue={
                                                    employee.userName ?? ''
                                                }
                                            />
                                            <small className="invalid-feedback text-danger">
                                                Username is required.
                                            </small>
                                        </>
                                    )}
                                </FormGroup>
                                {!isOnboarding && (
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="companySelect"
                                            text="Company"
                                            required={true}
                                        />
                                        <ValidatedSelect
                                            id={`companySelect`}
                                            name={'companySelect'}
                                            required
                                            isDisabled={!canEdit.company}
                                            options={companies}
                                            value={
                                                (companies ?? []).find(
                                                    (x) =>
                                                        x.id ===
                                                        employee.companyId
                                                ) ?? ''
                                            }
                                            getOptionValue={(option) =>
                                                option.id
                                            }
                                            getOptionLabel={(option) =>
                                                option.companyName
                                            }
                                            onChange={
                                                !!canEdit.company
                                                    ? this.onCompanyChanged
                                                    : undefined
                                            }
                                        />
                                    </FormGroup>
                                )}

                                <FormGroup>
                                    <FormLabel
                                        htmlFor="dispatchCompany"
                                        text="Home Dispatching"
                                        required={true}
                                    />
                                    <ValidatedSelect
                                        id="dispatchCompany"
                                        required={
                                            (dispatchCompanies ?? []).length
                                        }
                                        name="dispatchCompany"
                                        isDisabled={
                                            !isAdmin &&
                                            (!!isOnboarding ||
                                                !employee.companyId ||
                                                !(dispatchCompanies ?? [])
                                                    .length ||
                                                !canEdit.dispatchCompany)
                                        }
                                        options={dispatchCompanies}
                                        value={
                                            (dispatchCompanies ?? []).find(
                                                (x) =>
                                                    x.id ===
                                                    employee.dispatchCompanyId
                                            ) ?? ''
                                        }
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) =>
                                            option.companyName
                                        }
                                        onChange={
                                            !!canEdit.dispatchCompany
                                                ? this.onDispatchCompanyChanged
                                                : undefined
                                        }
                                    />
                                </FormGroup>
                                {
                                    //If the employee belongs to at least one group with an expanded dispatching flag set,
                                    //allow assignment of additional dispatching (if they have the permissions).
                                    !!this.showExpandDispatching() && (
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="expandedDispatchCompanies"
                                                text="Expanded Dispatching"
                                                required={false}
                                            />
                                            <ValidatedSelect
                                                id="expandedDispatchCompanies"
                                                name="expandedDispatchCompanies"
                                                options={
                                                    expandedDispatchOptions
                                                }
                                                isMulti
                                                required={false}
                                                value={
                                                    selectedExpandedDispatchCompanies
                                                }
                                                getOptionValue={(option) =>
                                                    option.id
                                                }
                                                getOptionLabel={(option) =>
                                                    option.companyName
                                                }
                                                onChange={
                                                    !!canEdit.dispatchCompany
                                                        ? this
                                                              .onExpandedDispatchCompaniesChanged
                                                        : undefined
                                                }
                                            />
                                        </FormGroup>
                                    )
                                }
                                {((!!canEdit.number && !!new_emp) ||
                                    !new_emp) && (
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="employeeNumber"
                                            text="Employee Number"
                                        />
                                        <input
                                            id="employeeNumber"
                                            name="employee.employeeNumber"
                                            autoComplete="off"
                                            className="form-control"
                                            disabled={!canEdit.number}
                                            onChange={
                                                !canEdit.number
                                                    ? undefined
                                                    : this.onChange
                                            }
                                            defaultValue={
                                                employee.employeeNumber
                                            }
                                        />
                                        <small className="invalid-feedback text-danger">
                                            Employee Number is required.
                                        </small>
                                    </FormGroup>
                                )}

                                {Boolean(employee.id) && (
                                    <FormGroup>
                                        <FormLabel text="Roster Eligibility Date"></FormLabel>
                                        <input
                                            id="eligibleDate"
                                            name="employee.eligibleDate"
                                            className="form-control"
                                            disabled={true}
                                            readOnly={true}
                                            value={employee.eligibleDate}
                                        />
                                    </FormGroup>
                                )}
                            </FormGroupColumn>
                            <FormGroupColumn>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="mobilePhone"
                                        text="Phone"
                                        required={
                                            !isOnboarding &&
                                            !!employee.smsNotify
                                        }
                                    />
                                    <input
                                        id="mobilePhone"
                                        placeholder="ex: 555-555-5555"
                                        name="employee.mobilePhone"
                                        pattern={
                                            util.validation.patterns.htmlPhone
                                        }
                                        autoComplete="off"
                                        type="tel"
                                        className="form-control"
                                        disabled={!canEdit.notifications}
                                        onChange={
                                            !!canEdit.notifications
                                                ? this.onMobilePhoneChanged
                                                : undefined
                                        }
                                        defaultValue={employee.mobilePhone}
                                        required={
                                            !isOnboarding &&
                                            !!employee.smsNotify
                                        }
                                    />
                                    <small className="invalid-feedback text-danger">
                                        {!isOnboarding &&
                                            `A valid phone number is required when SMS notifications are enabled.`}
                                        {!!isOnboarding &&
                                            `Ensure the phone number is valid. (555-555-5555)`}
                                    </small>
                                    {!!employee.mobilePhone && (
                                        <FormCheckbox
                                            className="mt-2 pl-2"
                                            id={'chkSmsNotify'}
                                            checked={
                                                employee.smsNotify ?? false
                                            }
                                            disabled={!canEdit.notifications}
                                            readOnly={!canEdit.notifications}
                                            onChange={
                                                !!canEdit.notifications
                                                    ? this.onSMSNotifyChanged
                                                    : undefined
                                            }
                                            labelText={
                                                'Enable SMS Notifications'
                                            }
                                        />
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="email"
                                        text="Email"
                                        required={true}
                                    />
                                    <input
                                        id="email"
                                        placeholder="ex: employee@provider.com"
                                        name="employee.email"
                                        autoComplete="off"
                                        type="email"
                                        required
                                        className="form-control"
                                        disabled={!canEdit.notifications}
                                        onChange={
                                            !!canEdit.notifications
                                                ? this.onEmailChanged
                                                : undefined
                                        }
                                        defaultValue={employee.email ?? ''}
                                    />
                                    <small className="invalid-feedback text-danger">
                                        Employee email is required.
                                    </small>
                                    {!!employee.email && (
                                        <FormCheckbox
                                            className="mt-2 pl-2"
                                            id={'chkEmailNotify'}
                                            disabled={!canEdit.notifications}
                                            readOnly={!canEdit.notifications}
                                            checked={
                                                employee.emailNotify ?? false
                                            }
                                            onChange={
                                                !!canEdit.notifications
                                                    ? this.onEmailNotifyChanged
                                                    : undefined
                                            }
                                            labelText={
                                                'Enable Email Notifications'
                                            }
                                        />
                                    )}
                                </FormGroup>
                                {!isOnboarding && (
                                    <FormGroup>
                                        <FormLabel text="Configuration" />
                                        {!!employeeImportSwitchEnabled && (
                                            <FormCheckbox
                                                className="mt-2 mb-2"
                                                id={'chkIncludeImport'}
                                                checked={
                                                    employee.systemAdded ??
                                                    false
                                                }
                                                disabled={!canEdit.import}
                                                readOnly={!canEdit.import}
                                                onChange={
                                                    !!canEdit.import
                                                        ? this
                                                              .onIncludeImportChanged
                                                        : undefined
                                                }
                                                labelText={
                                                    'Include with Import Updates'
                                                }
                                            />
                                        )}
                                        <FormCheckbox
                                            className="mt-2 mb-2"
                                            id={'chkExcludeDispatch'}
                                            checked={
                                                employee.excludeDispatch ??
                                                false
                                            }
                                            disabled={!canEdit.excludeDispatch}
                                            readOnly={!canEdit.excludeDispatch}
                                            onChange={
                                                !!canEdit.excludeDispatch
                                                    ? this
                                                          .onExcludeDispatchChanged
                                                    : undefined
                                            }
                                            labelText={
                                                'Exclude from Dispatch Board'
                                            }
                                        />
                                    </FormGroup>
                                )}
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="accountStatus"
                                        text="Account Status"
                                        required={!isOnboarding}
                                    />
                                    <ValidatedSelect
                                        isDisabled={
                                            !!isOnboarding || !canEdit.status
                                        }
                                        id="accountStatus"
                                        name="accountStatus"
                                        options={statuses}
                                        value={
                                            (statuses ?? []).find(
                                                (x) =>
                                                    x.id ===
                                                    employee.accountStatus.id
                                            ) ?? ''
                                        }
                                        getOptionLabel={(option) =>
                                            option.description
                                        }
                                        getOptionValue={(option) => option.id}
                                        onChange={
                                            !!canEdit.status
                                                ? this.onAccountStatusChanged
                                                : undefined
                                        }
                                        required={!isOnboarding}
                                        validationMessage="Account status (Active/Inactive) is required."
                                    />
                                    {!!isOnboarding && (
                                        <small className="ml-3 pt-1 text-info d-flex flex-row flex-nowrap align-items-start">
                                            <i className="fa fa-lg fa-exclamation-circle pr-1 pt-1" />
                                            <span style={{ paddingTop: '2px' }}>
                                                When the employee receives an
                                                employee number, the status will
                                                migrate to Active.
                                            </span>
                                        </small>
                                    )}
                                </FormGroup>
                                {pwdFields}
                            </FormGroupColumn>
                        </GroupedRow>
                        {!new_emp && !isOnboarding && (
                            <>
                                <Can I="view" a="employee_pay">
                                    <SubHeading>Pay Rate</SubHeading>
                                    <GroupedRow>
                                        <Col sm="12">
                                            <EmployeePayRateInfo
                                                onAddPayRate={this.onAddPayRate}
                                                onEditPayRate={
                                                    this.onEditPayRate
                                                }
                                                payRates={
                                                    this.state.employee.payRates
                                                }
                                            />
                                        </Col>
                                    </GroupedRow>
                                </Can>

                                <SubHeading>Addresses</SubHeading>
                                <GroupedRow>
                                    <Col sm="12">
                                        <EmployeeAddressInfo
                                            userPermissions={perms}
                                            onAddAddress={this.onAddAddress}
                                            addresses={
                                                this.state.employee.addresses
                                            }
                                            showRoutingAddress={!adminSelected}
                                            onEditAddress={this.onEditAddress}
                                        />
                                    </Col>
                                </GroupedRow>

                                <Can I="view" a="employee_restriction">
                                    <SubHeading>Restrictions</SubHeading>
                                    <GroupedRow>
                                        <Col sm="12">
                                            <EmployeeRestrictionInfo
                                                onAddRestriction={
                                                    this.onAddRestriction
                                                }
                                                restrictions={
                                                    this.state.employee
                                                        .restrictions
                                                }
                                                onEditRestriction={
                                                    this.onEditRestriction
                                                }
                                            />
                                        </Col>
                                    </GroupedRow>
                                </Can>

                                {complianceEnabled &&
                                    this.hasAvailableCompliance() && (
                                        <>
                                            <Can
                                                I="view"
                                                a="applicationusercompliance"
                                            >
                                                <SubHeading>
                                                    Compliances
                                                </SubHeading>
                                                <GroupedRow>
                                                    <Col sm="12">
                                                        <EmployeeComplianceInfo
                                                            onAddEmployeeCompliance={
                                                                this
                                                                    .onAddEmployeeCompliance
                                                            }
                                                            selectedEmployeeCompliances={
                                                                selectedEmployeeCompliances
                                                            }
                                                            onEditEmployeeCompliance={
                                                                this
                                                                    .onEditEmployeeCompliance
                                                            }
                                                        />
                                                    </Col>
                                                </GroupedRow>
                                            </Can>
                                        </>
                                    )}

                                {longTermStatusManagementEnabled && (
                                    <Can I="view" a="employeelongtermstatus">
                                        <SubHeading>
                                            Long-Term Statuses
                                        </SubHeading>
                                        <GroupedRow>
                                            <Col sm="12">
                                                <EmployeeLongTermStatusInfo
                                                    onAddEmployeeLongTermStatus={
                                                        this
                                                            .onAddEmployeeLongTermStatus
                                                    }
                                                    employeeLongTermStatuses={
                                                        employeeLongTermStatuses
                                                    }
                                                    onEditEmployeeLongTermStatus={
                                                        this
                                                            .onEditEmployeeLongTermStatus
                                                    }
                                                />
                                            </Col>
                                        </GroupedRow>
                                    </Can>
                                )}
                            </>
                        )}
                        <FlexCenterRow className="mb-3">
                            <Button
                                size="sm"
                                type="submit"
                                color="primary"
                                name="empForm"
                            >
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faSave}
                                />
                                {saveEmployeeButtonText}
                            </Button>
                        </FlexCenterRow>
                    </AppPageForm>

                    {Boolean(employee.id && canView.employeeHistory) && (
                        <EmployeeHistoryForm
                            ref={this.employeeHistoryFormRef}
                            show={showEmployeeHistoryForm}
                            onClose={this.onCloseChangeHistory}
                        />
                    )}

                    {!isOnboarding && (
                        <>
                            {!!(
                                canEdit.homeAddress || canEdit.routingAddress
                            ) && (
                                <AddressFormNew
                                    id="empAddressForm"
                                    ref={this.addressFormRef}
                                    contractUsesPrevailingWage={false}
                                    onSaveCallback={(address) =>
                                        this.onSaveAddress(address)
                                    }
                                />
                            )}

                            <Can do="edit" on="employee_pay">
                                <EmployeePayRateForm
                                    payRate={this.state.selectedPayRate}
                                    show={this.state.showPayRateForm}
                                    onClose={() =>
                                        this.setState({
                                            showPayRateForm: false,
                                        })
                                    }
                                    onSaveCallback={this.onSavePayRate}
                                    onChange={this.onPayRateChange}
                                    setIsValidated={(value) => {
                                        this.setState({
                                            payRateFormValidated: value,
                                        });
                                    }}
                                />
                            </Can>

                            <Can do="edit" on="employee_restriction">
                                <EmployeeRestrictionForm
                                    restriction={this.state.selectedRestriction}
                                    show={this.state.showRestrictionForm}
                                    onClose={() =>
                                        this.setState({
                                            showRestrictionForm: false,
                                        })
                                    }
                                    onSaveCallback={this.onSaveRestriction}
                                    onChange={this.onRestrictionChange}
                                    onCompanyChanged={
                                        this.onSelectedRestrictionCompanyChanged
                                    }
                                    onForemenChanged={
                                        this.onSelectedRestrictionForemenChanged
                                    }
                                    setIsValidated={(value) => {
                                        this.setState({
                                            restrictionFormValidated: value,
                                        });
                                    }}
                                />
                            </Can>

                            <Can do="edit" on="applicationusercompliance">
                                <EmployeeComplianceForm
                                    allEmployeeComplianceTypes={
                                        availableEmployeeComplianceTypes
                                    }
                                    onClose={this.onSaveCompliance}
                                    ref={this.complianceFormRef}
                                    show={this.state.showComplianceForm}
                                    toggleShow={
                                        this
                                            .toggleEmployeeComplianceForm /* getter/setter for form open state */
                                    }
                                    allKnownEmployees={employeeArray}
                                    employeeCompliance={
                                        selectedEmployeeCompliance
                                    }
                                    onSaveCallback={this.onSaveCompliance}
                                />
                            </Can>

                            <Can do="edit" on="employeelongtermstatus">
                                <EmployeeLongTermStatusForm
                                    onClose={this.onSaveLongTermStatus}
                                    ref={this.employeeLongTermStatusFormRef}
                                    show={showEmployeeLongTermStatusForm}
                                    toggleShow={
                                        this.toggleEmployeeLongTermStatusForm
                                    }
                                    employeeLongTermStatus={
                                        selectedEmployeeLongTermStatus
                                    }
                                    onSaveCallback={this.onSaveLongTermStatus}
                                />
                            </Can>
                        </>
                    )}
                    <Prompt
                        when={
                            !this.state.saving &&
                            !isEqual(originalData, employee)
                        }
                        message="You have unsaved changes, are you sure you want to leave?"
                    />
                </Fragment>
            );
        }
    }
    //#endregion
}

export default withRouter(EmployeeForm);
