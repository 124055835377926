import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBan,
    faUserClock,
    faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
} from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    FormCheckbox,
    FlexBetweenRow,
    FormLabel,
    onFieldChange,
    onReactSelectChanged,
    toasty,
    FlexStartRow,
    FormBlocker,
} from '../common/forms/FormElements';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { faMinusCircle } from '@fortawesome/fontawesome-free-solid';
import { Timesheet } from './Timesheet';
import cls from 'classnames';
import _ from 'lodash-es';
import { ChargeTypeEligbility } from '../contracts/Contract';
import AddressFormNew from '../address/AddressFormNew';
import { Address } from '../address/Address';
import { ChargeTypeUnits } from '../chargeType/ChargeType';
import NumericInput from '../common/forms/NumericInput';
import { Badge } from 'reactstrap';
import * as moment from 'moment';
import TimeEntry from '../common/forms/TimeEntry';
import { getTenantUserProfile } from '../common/TenantUserProfile';

const WorkOrderType = {
    Required: 1,
    Optional: 2,
    NotApplicable: 3,
};

export default class TimesheetItem extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.addressFormRef = React.createRef();

        let stateBase = Object.assign(
            {
                jobLocationOverride: new Address(),
                addChargeDropdownOpen: false,
                timesheet: {},
                chargeTypes: [],
                optionalChargeTypes: [],
                timesheetValid: false,
                visibleChargeTypeIds: [],
                saving: false,
                allowDefaultChargeTypeValues: false,
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => this.populateState();

    async populateState() {
        const { userTenant } = await getTenantUserProfile();
        const { tenantSettings } = { ...userTenant };
        const allowDefaultChargeTypeValues =
            tenantSettings.allowDefaultChargeTypeValues;

        this.setState({ allowDefaultChargeTypeValues });
    }

    onChange = onFieldChange;
    onSelectChange = onReactSelectChanged;
    handleSaveError = (err) => handleFormSaveError(this, err);

    open = async (timesheetId) => {
        await this.setState({ loading: true });
        await this.populateState();

        const { allowDefaultChargeTypeValues } = this.state;

        let timesheet = null;

        this.resetForm();

        if (!!timesheetId) {
            timesheet = await util.fetch.js(
                ApiRoutes.timesheet.byId(timesheetId)
            );
        } else {
            timesheet = new Timesheet();
        }

        const timesheetDetails = _.groupBy(
                timesheet.timesheetDetails,
                (asn) => asn.chargeTypeName
            ),
        
        chargeTypeNames = Object.keys(timesheetDetails);

        if (allowDefaultChargeTypeValues) {
            timesheet.timesheetDetails.forEach((td) => {
                if (td.chargeTypeDefaultValue && !td.value) {
                    td.value = td.chargeTypeDefaultValue;
                }
            });
        }

        const [chargeTypes, requiredChargeTypeIds, optionalChargeTypes] = [
            //Stands up same as all? functionality
            chargeTypeNames
                .map((tsd, i) => {
                    const ts = timesheetDetails[tsd][0];
                    return {
                        id: ts.chargeTypeId,
                        name: ts.chargeTypeName,
                        unitsId: ts.chargeTypeUnitsId,
                        isOptional:
                            ts.contractChargeTypeEligibilityId ===
                            ChargeTypeEligbility.Optional,
                        sameAll: false,
                        include: ts.include,
                        isSurcharge: ts.isSurcharge,
                        resourceTypeId: ts.chargeType.resourceTypeId
                    };
                })
                .filter((x) => !!x),
            //Display all required/specified charge types immediately
            chargeTypeNames
                .map((tsd, i) => {
                    if (!!timesheetDetails[tsd][0].include)
                        return timesheetDetails[tsd][0].chargeTypeId;
                    return null;
                })
                .filter((x) => !!x),
            //Save optional charge types until the user adds them.
            chargeTypeNames
                .map((tsd, i) => {
                    let ts = timesheetDetails[tsd][0];
                    if (
                        !ts.include &&
                        ts.contractChargeTypeEligibilityId ===
                            ChargeTypeEligbility.Optional
                    )
                        return {
                            id: timesheetDetails[tsd][0].chargeTypeId,
                            name: timesheetDetails[tsd][0].chargeTypeName,
                        };
                    return null;
                })
                .filter((x) => !!x),
        ];

        this.setState({
            timesheet: timesheet,
            chargeTypes: chargeTypes,
            visibleChargeTypeIds: requiredChargeTypeIds,
            optionalChargeTypes: optionalChargeTypes,
        });

        this.props.toggleShow(true);
        this.context.setFormOpened(true);

        await this.setState({ loading: false });
    };

    onClose = () => {
        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    };

    resetForm = () => {
        this.setState({
            formValidated: false,
        });
    };

    toggleAddChargeDropdownOpen = () =>
        this.setState(
            (state) => (
                (state.addChargeDropdownOpen = !state.addChargeDropdownOpen),
                state
            )
        );

    onAddChargeTypeToTimesheet = (chargeType) => {
        let { optionalChargeTypes, visibleChargeTypeIds, chargeTypes } = {
            ...this.state,
        };
        let updatedChargeTypeIndex = chargeTypes.indexOf(
            chargeTypes.find((c) => c.id === chargeType.id)
        );
        _.remove(optionalChargeTypes, chargeType);
        visibleChargeTypeIds.push(chargeType.id);
        chargeTypes[updatedChargeTypeIndex].include = true;
        this.setState({
            optionalChargeTypes: optionalChargeTypes,
            visibleChargeTypeIds: visibleChargeTypeIds,
            chargeTypes: chargeTypes,
        });
    };

    onRemoveChargeTypeFromTimesheet = (chargeType) => {
        let { optionalChargeTypes, visibleChargeTypeIds, chargeTypes } = {
            ...this.state,
        };
        let updatedChargeTypeIndex = chargeTypes.indexOf(
            chargeTypes.find((c) => c.id === chargeType.id)
        );
        optionalChargeTypes.push(chargeType);
        let updatedChargeTypes = visibleChargeTypeIds.filter(
            (ct) => ct !== chargeType.id
        );
        chargeTypes[updatedChargeTypeIndex].include = false;
        this.setState({
            optionalChargeTypes: optionalChargeTypes,
            visibleChargeTypeIds: updatedChargeTypes,
            chargeTypes: chargeTypes,
        });
    };

    toggleDetailNotApplicable = (resources = []) => {
        const { timesheet } = this.state;
        
        const timesheetDetails = timesheet.timesheetDetails.map(detail => {
            const resource = resources.findIndex(x => x.id === detail.id);

            if (resource === -1) {
                return detail;
            }

            return {
                ...detail,
                start: '',
                end: '',
                value: 0,
                notApplicable: !detail.notApplicable,
            };
        });

        this.setState({ timesheet: { ...timesheet, timesheetDetails }});
    };

    onChargeChange = (resources, ev) => {
        const { timesheet } = this.state,
            val = ev.target.value,
            name = ev.target.name;

        //We display the options in 12-hour format, but the db has 24.
        let timeValue = !!ev.target.value
            ? moment(val, ['h:mm A']).format('HH:mm')
            : '';

        const timesheetDetails = timesheet.timesheetDetails.map(detail => {
            const resource = resources.findIndex(x => x.id === detail.id);

            if (resource === -1) {
                return detail;
            }

            return {
                ...detail,
                [name]: detail.chargeTypeUnitsId == ChargeTypeUnits.Flat ? val : timeValue,
            };
        });
        
        this.setState({ timesheet: { ...timesheet, timesheetDetails } });
    };

    onAddJobLocationAddress = () => {
        let { jobLocationOverride } = { ...this.state };
        this.addressFormRef.current.open(
            jobLocationOverride,
            'Timesheet Job Location Override',
            false,
            false
        );
    };

    onSaveAddress = (address) => {
        this.setState(
            (state) => (
                (state.timesheet.jobLocation = address.addressLine()), state
            )
        );
    };

    //Handler for populating the same entered time values to all values of that charge type
    //("Lazy user mode")
    onAllCheckChanged = (e) => {
        let { timesheet, chargeTypes } = this.state;

        chargeTypes.find((x) => x.name == e.target.name).sameAll =
            e.target.checked;

        if (e.target.checked === true) {
            let details = timesheet.timesheetDetails.filter(
                (x) => x.chargeTypeName == e.target.name
            );
            let first = details[0];
            details.forEach((x) => {
                x.start = first.start;
                x.end = first.end;
            });
        }

        this.setState({ chargeTypes: chargeTypes, timesheet: timesheet });
    };

    onSubmitTimesheet = async () => {
        let { saving } = { ...this.state };
        if (!!saving) {
            return false;
        } else {
            await this.setState({ saving: true });
            this.saveTimesheet();
        }
    };

    saveTimesheet = () => {
        let { timesheet, chargeTypes } = { ...this.state };

        timesheet.timesheetDetails = timesheet.timesheetDetails.map((d) => {
            d.include = chargeTypes.find(
                (c) => c.id === d.chargeTypeId
            ).include;
            return d;
        });

        let setupCharges = timesheet.timesheetDetails.filter(
            (x) => x.chargeType.resourceTypeId == 5 && x.include == true && (x.isSurcharge == null || x.isSurcharge == false)
        );

        if (timesheet.contractSetupChargesRequired && setupCharges.length == 0) {
            toasty.error('Timesheet must have at least one setup charge.');
            this.setState({ saving: false });
            return;
        }

        util.fetch
            .post(ApiRoutes.timesheet.update(), timesheet)
            .then((updatedTimesheet) => {
                this.setState({ timesheet: { ...updatedTimesheet } });
                toasty.success(
                    'Timesheet Saved',
                    'Timesheet updated successfully.'
                );
                this.onClose();
            })
            .catch(() => toasty.error('Error saving timesheet.'))
            .finally(() => this.setState({ saving: false }));
    };

    groupTimesheetDetails = () => {
        const { timesheet } = this.state;

        const timesheetDetails = _.groupBy(
            timesheet.timesheetDetails,
            (asn) => asn.chargeTypeName
        );
        
        return _.mapValues(timesheetDetails, chargeGroup => {
            let i = 0;
            let totalAFADs = 0;

            const len = (chargeGroup ?? []).length;
    
            const results = [];
    
            for(; i < len; i += 1) {
                const detail = chargeGroup[i];
                let description = detail.equipmentName ?? detail.employeeFullName;
    
                const resources = [detail];
    
                if (detail.equipment?.equipmentType?.equipmentTypeAFADType?.isAFAD) {
                    totalAFADs += 1;
    
                    if (detail.chargeType.baseConfig.numAFADsInSet > 1) {
                        // Group everything under the first item in the set and discard all other items in the set
                        const isFirstInSet = totalAFADs % detail.chargeType.baseConfig.numAFADsInSet == 1;
    
                        if (!isFirstInSet) {
                            continue;
                        }
    
                        let j = 1;
                                    
                        for(; j < detail.chargeType.baseConfig.numAFADsInSet; j += 1) {
                            var next = chargeGroup[i + j];
    
                            if (!next?.equipment?.equipmentType?.equipmentTypeAFADType?.isAFAD) {
                                break;
                            }
    
                            description = `${description}, ${next.equipmentName}`;
                            resources.push(next);
                        }
                    }
                }
    
                results.push({ ...detail, resources, description });
            }
    
            return results;
        });
    };

    render() {
        let {
            addChargeDropdownOpen,
            chargeTypes,
            formValidated,
            optionalChargeTypes,
            timesheet,
            validationMessage,
            saving,
            allowDefaultChargeTypeValues,
        } = this.state;

        if (!timesheet) return null;

        const timesheetDetails = this.groupTimesheetDetails();

        const wonPlaceholder =
            timesheet.workOrderTypeId === WorkOrderType.Required
                ? '(Required)'
                : '(Optional)';
        const disabled = !!timesheet.isComplete;
        const notesLength = ((timesheet ?? {}).notes ?? '').length;

        return (
            <>
                <SlideForm
                    loading={this.state.loading}
                    show={this.props.show}
                    id={'timesheetItem'}
                    formIcon={faUserClock}
                    formTitle={
                        !!parseInt((timesheet ?? {}).id ?? 0) > 0
                            ? 'Edit Timesheet'
                            : 'Add Timesheet'
                    }
                    ref={this.formRef}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={formValidated}
                    onSubmit={this.onSubmitTimesheet}
                    onClose={this.onClose}
                    onSave={this.saveTimesheet}
                    onDelete={this.onDelete}
                    errors={this.state.errors}
                    onClearErrors={this.onClearErrors}
                    validationMessage={validationMessage}
                    readOnly={disabled}
                >
                    <FormBlocker show={!!saving} showProgress={true} />
                    <div className="timesheetDetails">
                        <h5 className="d-flex flex-row align-items-center text-muted justify-content-center">
                            {`${timesheet.customerName}${
                                !!timesheet.subcontractorName
                                    ? ` - ${timesheet.subcontractorName}`
                                    : ''
                            } - ${timesheet.jobNumber}`}
                            <Badge className="ml-1" size="lg">
                                {timesheet.timesheetSequence}
                            </Badge>
                        </h5>

                        <div id="validationMessages" className="pt-2 pb-2">
                            <small className="text-danger">
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    size="lg"
                                    className="mr-1"
                                />
                                Your submission contains invalid entries.
                            </small>
                        </div>
                        {!!timesheet.isComplete && (
                            <div className="site-alert site-alert-success text-center">
                                This timesheet was completed.
                            </div>
                        )}
                        <FlexBetweenRow>
                            {timesheet.workOrderTypeId !==
                                WorkOrderType.NotApplicable /*NA*/ && (
                                <>
                                    <FormGroup>
                                        <FormLabel
                                            required={
                                                timesheet.workOrderTypeId ==
                                                WorkOrderType.Required
                                            }
                                            htmlFor="workOrderNumberTimesheet"
                                            text="Work Order #"
                                        />
                                        <FlexStartRow>
                                            <input
                                                placeholder={wonPlaceholder}
                                                disabled={disabled}
                                                id="workOrderNumberTimesheet"
                                                required={
                                                    timesheet.workOrderTypeId ==
                                                    WorkOrderType.Required
                                                }
                                                name="timesheet.workOrderNumber"
                                                className={cls(
                                                    'form-control form-control-sm'
                                                )}
                                                value={
                                                    timesheet.workOrderNumber ??
                                                    ''
                                                }
                                                onChange={(ev) => {
                                                    let value = ev.target.value;
                                                    this.setState(
                                                        (state) => (
                                                            (state.timesheet.workOrderNumber =
                                                                value),
                                                            state
                                                        )
                                                    );
                                                }}
                                            />
                                        </FlexStartRow>
                                    </FormGroup>
                                </>
                            )}
                            {!!optionalChargeTypes.length &&
                                !timesheet.isComplete && (
                                    <FormGroup>
                                        <label
                                            className="control-label"
                                            style={{ opacity: 0 }}
                                        >
                                            Add Setup Charge
                                        </label>
                                        <Dropdown
                                            isOpen={addChargeDropdownOpen}
                                            toggle={
                                                this.toggleAddChargeDropdownOpen
                                            }
                                        >
                                            <DropdownToggle
                                                size="sm"
                                                id="addChargeDropdown"
                                                color="outline-primary"
                                            >
                                                <i className="fa fa-plus-circle fa-lg mr-1" />
                                                Add Setup Charge
                                        </DropdownToggle>
                                        <DropdownMenu className="shadow">
                                            {optionalChargeTypes.map((ct) => (
                                                <DropdownItem
                                                    key={ct.id}
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        this.onAddChargeTypeToTimesheet(
                                                            ct
                                                        )
                                                    }
                                                >
                                                    {ct.name}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                        </Dropdown>
                                    </FormGroup>
                                )}
                        </FlexBetweenRow>
                        <FlexStartRow>
                            <FormGroup className="w-100">
                                <FormLabel
                                    htmlFor="jobLocation"
                                    text="Job Location Override"
                                />

                                <input
                                    disabled={disabled}
                                    placeholder={'(Optional)'}
                                    id="jobLocation"
                                    name="timesheet.jobLocation"
                                    className={cls(
                                        'form-control form-control-sm'
                                    )}
                                    value={timesheet.jobLocation ?? ''}
                                    onChange={(ev) => {
                                        let value = ev.target.value;
                                        this.setState(
                                            (state) => (
                                                (state.timesheet.jobLocation =
                                                    value),
                                                state
                                            )
                                        );
                                    }}
                                />
                            </FormGroup>
                        </FlexStartRow>
                        {!!chargeTypes &&
                            chargeTypes.map(
                                (chargeType) =>
                                    !!chargeType.include &&
                                    !chargeType.isSurcharge && (
                                        <div
                                            key={chargeType.name}
                                            className="crewTimesheetDetails table-responsive"
                                        >
                                            <FlexBetweenRow>
                                                <span className="pl-1 timesheetHeader text-muted">
                                                    {chargeType.name}
                                                    
                                                    {chargeType.id === 1 && ( //min charge type is 1
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faExclamationCircle
                                                            }
                                                            title="Minimum charge type from cancellation"
                                                            className="text-danger ml-2 cursor-pointer"
                                                        />
                                                    )}
                                                    {
                                                        //Only allow optional charge types to be removed once added.
                                                        !!chargeType.isOptional && (
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faMinusCircle
                                                                }
                                                                title="Remove this charge type"
                                                                className="text-danger ml-2 cursor-pointer"
                                                                onClick={() =>
                                                                    this.onRemoveChargeTypeFromTimesheet(
                                                                        chargeType
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    }
                                                </span>
                                                {!timesheet.isComplete &&
                                                    chargeType.id !== 1 &&
                                                    chargeType.resourceTypeId != 5 && (
                                                        <FormCheckbox
                                                            small={true}
                                                            className="m-1"
                                                            id={`${chargeType.name}`}
                                                            name={`${chargeType.name}`}
                                                            checked={
                                                                chargeType.sameAll
                                                            }
                                                            onChange={
                                                                this
                                                                    .onAllCheckChanged
                                                            }
                                                            labelText="Same For All?"
                                                        />
                                                    )}
                                                {chargeType.resourceTypeId == 5 &&
                                                    <div style={{height: '25px'}} >&nbsp;</div>
                                                }
                                            </FlexBetweenRow>
                                            <table className="table table-sm crewTimesheetTable borderless">
                                                {chargeType.resourceTypeId != 5 &&
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th className="not-applicable"></th>
                                                            <th className="not-applicable">
                                                                N/A
                                                            </th>
                                                            {chargeType.unitsId ===
                                                                ChargeTypeUnits.Flat ? (
                                                                <th className="units-value"></th>
                                                            ) : (
                                                                <>
                                                                    <th className="start-time">
                                                                        START
                                                                    </th>
                                                                    <th className="end-time">
                                                                        STOP
                                                                    </th>
                                                                </>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                }
                                                <tbody>
                                                    {timesheetDetails[
                                                        chargeType.name
                                                    ].map(
                                                        (
                                                            detail,
                                                            detailIndex
                                                        ) => {
                                                            let timeSpan = 0.0;
                                                            let chargeExceedsThreshold = false;
                                                            let breakExceedsThreshold = false;

                                                            if (
                                                                detail.start !=
                                                                    null &&
                                                                detail.start
                                                                    .length >
                                                                    0 &&
                                                                (detail.hoursExceededThreshold >
                                                                    0 ||
                                                                    detail.breakHoursExceededThreshold >
                                                                        0)
                                                            ) {
                                                                let start =
                                                                    moment(
                                                                        detail.start,
                                                                        'hh:mm'
                                                                    );
                                                                let end =
                                                                    moment(
                                                                        detail.end,
                                                                        'hh:mm'
                                                                    );
                                                                let dur =
                                                                    moment.duration(
                                                                        end.diff(
                                                                            start
                                                                        )
                                                                    );

                                                                timeSpan =
                                                                    dur._data
                                                                        .hours +
                                                                    (dur._data
                                                                        .minutes >
                                                                    0
                                                                        ? dur
                                                                              ._data
                                                                              .minutes /
                                                                          60
                                                                        : 0);

                                                                chargeExceedsThreshold =
                                                                    !detail.isBreak &&
                                                                    detail.hoursExceededThreshold >
                                                                        0 &&
                                                                    timeSpan >=
                                                                        detail.hoursExceededThreshold;

                                                                breakExceedsThreshold =
                                                                    detail.isBreak &&
                                                                    detail.breakHoursExceededThreshold >
                                                                        0 &&
                                                                    timeSpan >=
                                                                        detail.breakHoursExceededThreshold;
                                                            }

                                                            return (
                                                                <tr
                                                                    key={
                                                                        detail.id
                                                                    }
                                                                    className={
                                                                        !!detail.start &&
                                                                        !!detail.end &&
                                                                        detail.start >
                                                                            detail.end
                                                                            ? 'ag-cell-danger'
                                                                            : ''
                                                                    }
                                                                >
                                                                    <td>
                                                                        <small>
                                                                            {detail.description}
                                                                        </small>
                                                                    </td>
                                                                    <td className="not-applicable">
                                                                        {chargeExceedsThreshold && (
                                                                            <i
                                                                                title="Hours Exceed Suggested Threshold"
                                                                                className="fa fa-exclamation-triangle text-warning mr-1"
                                                                            ></i>
                                                                        )}
                                                                        {breakExceedsThreshold && (
                                                                            <i
                                                                                title="Hours Exceed Suggested Threshold"
                                                                                className="fa fa-exclamation-triangle text-warning mr-1"
                                                                            ></i>
                                                                        )}
                                                                    </td>
                                                                    <td className="not-applicable">
                                                                        {chargeType.resourceTypeId != 5 &&
                                                                            <FontAwesomeIcon
                                                                                size="lg"
                                                                                className={cls(
                                                                                    'cursor-pointer icon-check',
                                                                                    {
                                                                                        checked:
                                                                                            detail.notApplicable,
                                                                                    }
                                                                                )}
                                                                                disabled={
                                                                                    disabled ||
                                                                                    detail.timesheetDetailStatusId ===
                                                                                    4 ||
                                                                                    detail.disallowNA
                                                                                }
                                                                                icon={
                                                                                    faBan
                                                                                }
                                                                                title="Toggle whether or not start and stop time applies for this charge type."
                                                                                onClick={
                                                                                    !!timesheet.isComplete ||
                                                                                        detail.timesheetDetailStatusId ===
                                                                                        4 ||
                                                                                        detail.disallowNA
                                                                                        ? () =>
                                                                                            false
                                                                                        : () => {
                                                                                            this.toggleDetailNotApplicable(detail.resources)
                                                                                        }
                                                                                }
                                                                            />
                                                                        }
                                                                    </td>
                                                                    {detail.chargeTypeUnitsId ===
                                                                    ChargeTypeUnits.Flat ? (
                                                                        <td className="units-value">
                                                                            <NumericInput
                                                                                className="form-control form-control-sm"
                                                                                name="value"
                                                                                disabled={
                                                                                    !!detail.notApplicable ||
                                                                                    disabled ||
                                                                                    detail.timesheetDetailStatusId ===
                                                                                        4
                                                                                }
                                                                                value={
                                                                                    detail.value ??
                                                                                    ''
                                                                                }
                                                                                onChange={(
                                                                                    ev
                                                                                ) =>
                                                                                    this.onChargeChange(
                                                                                        detail.resources,
                                                                                        ev
                                                                                    )
                                                                                }
                                                                                min={0}
                                                                                max={detail.resources.length > 1 ? 1 : undefined}
                                                                                placeholder={`Enter value`}
                                                                                type="number"
                                                                                step={
                                                                                    detail.chargeTypeIncrement ??
                                                                                    1
                                                                                }
                                                                            />
                                                                        </td>
                                                                    ) : (
                                                                        <>
                                                                            <td className="start-time">
                                                                                <TimeEntry
                                                                                    disabled={
                                                                                        !!detail.notApplicable ||
                                                                                        disabled ||
                                                                                        detail.timesheetDetailStatusId ===
                                                                                            4
                                                                                    }
                                                                                    name="start"
                                                                                    value={
                                                                                        detail.start
                                                                                    }
                                                                                    increment={
                                                                                        detail.chargeTypeIncrement
                                                                                    }
                                                                                    onChange={(
                                                                                        ev
                                                                                    ) =>
                                                                                        this.onChargeChange(
                                                                                            detail.resources,
                                                                                            ev
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td className="end-time">
                                                                                <TimeEntry
                                                                                    disabled={
                                                                                        !!detail.notApplicable ||
                                                                                        disabled ||
                                                                                        detail.timesheetDetailStatusId ===
                                                                                            4
                                                                                    }
                                                                                    name="end"
                                                                                    value={
                                                                                        detail.end
                                                                                    }
                                                                                    increment={
                                                                                        detail.chargeTypeIncrement
                                                                                    }
                                                                                    onChange={(
                                                                                        ev
                                                                                    ) =>
                                                                                        this.onChargeChange(
                                                                                            detail.resources,
                                                                                            ev
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </>
                                                                    )}
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                            )}
                        <FlexStartRow>
                            <FormGroup className="w-100">
                                <FormLabel htmlFor="notes" text="Notes" />
                                <textarea
                                    disabled={disabled}
                                    placeholder={
                                        '(Optional, limit 2000 characters)'
                                    }
                                    maxLength="2000"
                                    id="notes"
                                    rows="3"
                                    name="timesheet.notes"
                                    className={cls(
                                        'form-control form-control-sm'
                                    )}
                                    value={timesheet.notes ?? ''}
                                    onChange={(ev) => {
                                        let value = ev.target.value;
                                        this.setState(
                                            (state) => (
                                                (state.timesheet.notes = value),
                                                state
                                            )
                                        );
                                    }}
                                />
                                <small
                                    className={cls('text-right', {
                                        'text-danger': notesLength < 0,
                                        'text-success': notesLength >= 0,
                                    })}
                                >{`${(
                                    2000 - notesLength
                                ).toString()} remaining`}</small>
                            </FormGroup>
                        </FlexStartRow>
                    </div>
                </SlideForm>
                <AddressFormNew
                    id="addressForm"
                    ref={this.addressFormRef}
                    contractUsesPrevailingWage={true}
                    onSaveCallback={(address) => this.onSaveAddress(address)}
                />
            </>
        );
    }
}
