import { faRecycle } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import moment from 'moment';
import { Button, ButtonGroup, ButtonToolbar, Col, Container, FormGroup, Row } from 'reactstrap';
import { ConfirmationStatus, JobConfirmationStatusOptions } from '../assignmentConfirmation/AssignmentConfirmation';
import { FlexBetweenRow, FlexColumnCenter, FlexColumnStart, FlexEndRow, FlexStartRow, FormBlocker, FormLabel, SubHeading, toasty } from '../common/forms/FormElements';
import SlideForm from '../common/forms/SlideForm';
import "./JobWorkflowEditor.scss";
import cls from 'classnames';
import { util } from '../Util';
import { ApiRoutes, LocalizationKeys as l } from '../Common';
import ChangeLogViewer, { ChangeLog } from '../common/ChangeLogViewer';
//import * as moment from 'moment';

export default class JobWorkflowEditor extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.changeLogRef = React.createRef();
        this.state = {
            loading: false,
            formValidated: false,
            validationMessage: '',
            showConfirmation: false,
            selectedAssignment: null,
            notes: '',
            changeLog: new ChangeLog()
        }        
    }

    onClose = () => {
        this.setState({
            formValidated: false,
            loading: false,
            validationMessage: '',
            showConfirmation: false,
            selectedAssignment: null,
            notes: ''
        })
        this.props.onClose();
    }

    showConfirmationMessage = (assignment) => {

        const _toastHeader = 'Unable to Confirm This Assignment';
        const _validationToastOptions = { autoClose: false, closeOnClick: true };
        const selectedAssignment = { ...assignment };
        const { jobDetails } = { ...this.props };
        const { assignments } = { ...jobDetails };
        const isEquipmentAssignment = !!selectedAssignment.requiredEquipment.length;
        const staffConfirmations = assignments.staff.filter(x => x.assignment?.confirmationStatusId === ConfirmationStatus.Confirmed);
        const equipmentConfirmations = assignments.equipment.filter(x => x.assignment?.confirmationStatusId === ConfirmationStatus.Confirmed);

        if (!!isEquipmentAssignment) {
            //Validation #1: Don't allow confirming equipment before the responsible personnel has been confirmed at the job site.
            //               The timesheet will not have been created yet.
            if (!!selectedAssignment.assignedEmployee?.id) {
                const rpIsAssigned = !!staffConfirmations.filter(x => x.assignment?.id === selectedAssignment.assignedEmployee.id).length;
                if (!rpIsAssigned) {
                    toasty.error(
                        _toastHeader,
                        <FlexColumnStart>
                            <div>
                                Please ensure <strong>{`[${selectedAssignment.assignedEmployee.name}]`}</strong> has been confirmed for this job before confirming their equipment.
                            </div>
                            <div className="pt-2">
                                <small>Click to dismiss.</small>
                            </div>
                        </FlexColumnStart>,
                        _validationToastOptions
                    );
                    return false;
                }
            }

            //Validation #2: Don't allow confirming equipment before the parent equipment has been confirmed at the job site.
            if (!!selectedAssignment.assignedEquipment?.id) {
                const parentEqIsAssigned = !!equipmentConfirmations.filter(x => x.assignment?.id === selectedAssignment.assignedEquipment.id).length;
                if (!parentEqIsAssigned) {
                    toasty.error(
                        _toastHeader,
                        <FlexColumnStart>
                            <div>
                                Please ensure <strong>{`[${selectedAssignment.assignedEquipment.equipmentType.description} #${selectedAssignment.assignedEquipment.description}]`}</strong> is confirmed for this job before confirming this equipment.
                            </div>
                            <div className="pt-2">
                                <small>Click to dismiss.</small>
                            </div>
                        </FlexColumnStart>,
                        _validationToastOptions
                    );
                    return false;
                }
            }
        }

        this.setState({ showConfirmation: true, selectedAssignment: selectedAssignment });
    }

    onCancelConfirmClicked = () => this.setState({ showConfirmation: false, selectedAssignment: null });

    onSubmit = async () => {

        await this.setState({ saving: true });

        const { selectedAssignment, notes } = { ...this.state };

        let data = { notes: notes };

        try {
            await util.fetch.post(ApiRoutes.jobAssignment.confirm(selectedAssignment.jobAssignmentId), data);
            toasty.success('Job assignment confirmed.');
            this.onClose();
        }
        catch {
            toasty.error('There was a problem confirming the assignment.  Please try your request again or contact support.');

        } finally {
            await this.setState({ saving: false });
        }
    }

    getFormTitle = () => {
        return (
            <FlexColumnStart>
                <span>Edit Job Workflow</span>
                <small className="text-muted">Set Confirmation Status for Assignments</small>
            </FlexColumnStart>
        );
    }

    coalesceJobDetails = () => {
        const { jobDetails } = { ...this.props };
        const { job } = { ...jobDetails };

        const override = this.selectedDayHasOverride();

        if (!override) {
            return job;
        }

        return {
            ...job,
            startTime: override.startTime || job.startTime,
            foremanName: override.foremenName || job.foremanName,
            workOrderNumber: override.workOrderNumber || job.workOrderNumber,
            meetingAddress: override.meetingAddress || job.meetingAddress,
            jobAddress: override.jobAddress || job.jobAddress,
        };
    };

    getJobDetails = () => {
        const job = this.coalesceJobDetails();

        const formTitle = `${job?.name} - ${job?.startTime}`;
        const foreman = job?.foremanName ?? '';

        return (
            <div className="table-responsive">
                <table className="table table-sm table-job-workflow-details">
                <thead>
                    <tr>
                        <th colSpan="2" scope="col">
                            <FlexStartRow>
                                <span className="badge badge-lg badge-secondary mr-2" style={{ fontSize: '100% !important' }}>{job.number}</span>
                                {formTitle}
                            </FlexStartRow>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">
                            Foreman
                        </th>
                        <td>{foreman}</td>
                        </tr>
                    {
                        !!job?.workOrderNumber &&
                        <tr>
                            <th scope="row">
                                Work Order Number
                            </th>
                            <td className="text-danger">{job?.workOrderNumber}</td>
                        </tr>
                    }
                    <tr>
                        <th scope="row">
                            Meeting Location
                        </th>
                        <td>{job?.meetingAddress}</td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Job Location
                        </th>
                        <td>{job?.jobAddress}</td>
                    </tr>
                </tbody>
                </table>
            </div>
        );
    }

    selectedDayHasOverride = () => {
        const { jobDetails } = this.props;
        const { job, day: dayIndex } = jobDetails;

        if (!job?.overrides || job?.overrides.length === 0) {
            return false;
        }

        return job.overrides.find(override => moment(override.overrideDate, moment.ISO_8601).day() === dayIndex);
    };


    onShowChangeLogClicked = async (data) => {
        const asn = { ...data };
        let { changeLog } = { ...this.state };
        const { jobDetails } = { ...this.props };
        const { job } = { ...jobDetails };
        const jobName = `${job?.name} - ${job?.startTime}`;

        changeLog.title = <>
            <FlexStartRow>
                <span style={{ fontSize: '.84em'}} className="badge badge-lg badge-secondary p-2 mr-2">{asn.name ?? asn.description}</span>
                {asn.assignment.name ?? asn.assignment.description}
            </FlexStartRow>
            <FlexStartRow className="mt-2 mb-2">
                <small className="font-weight-bold text-muted">{jobName}</small>
            </FlexStartRow>
        </>;

        changeLog.show = true;
        changeLog.loading = true;
        await this.setState({ changeLog: { ...changeLog } });
        changeLog.events = await util.fetch.post(ApiRoutes.jobAssignment.eventLog(), [asn.jobAssignmentId]);
        changeLog.loading = false;
        await this.setState({ changeLog: { ...changeLog } });
    }

    onCloseChangeLog = () => {
        let { changeLog } = { ...this.state };
        changeLog.show = false;
        changeLog.loading = false;
        changeLog.title = '';
        changeLog.events = [];
        this.setState({ changeLog: { ...changeLog } });
    }

    getConfirmationControls = (asn, asn_inx) => {

        const crewLead = this.props.strings[l.crewLead];

        return (
            <FormGroup key={asn_inx} className="job-assignment-card mt-2 border border-radius p-2">
                <FlexColumnCenter>
                    {
                        <FlexBetweenRow className="pb-2">
                            <FlexStartRow>
                                <span className="badge badge-lg badge-secondary p-2 mr-2">{asn.name ?? asn.description}</span>
                                {asn.assignment.name ?? asn.assignment.description}
                            </FlexStartRow>
                            {!!asn.isCrewLead && <span className="text-warning" title={`${crewLead}`}><i className="fa fa-hard-hat fa-lg mr-2"></i><small>{`${crewLead.toUpperCase()}`}</small></span>}
                            {!!asn?.assignedEmployee && <small className="text-success">{`Assigned To: ${asn.assignedEmployee.name}`}</small>}
                            {!!asn?.assignedEquipment && <small className="text-success">{`Assigned To: ${asn.assignedEquipment.description}`}</small>}
                        </FlexBetweenRow>
                    }
                    <FlexStartRow className="border-top p-2">
                        <ButtonToolbar className="edit-confirmation-status-toolbar justify-content-center">
                            <ButtonGroup>
                                {
                                    JobConfirmationStatusOptions.map(x =>
                                        <Button
                                            disabled={(x.value !== ConfirmationStatus.Confirmed) || ((asn.assignment?.confirmationStatusId ?? 0) >= ConfirmationStatus.Confirmed)}
                                            key={x.value}
                                            size="sm"
                                            color="default"
                                            title={(x.value !== ConfirmationStatus.Confirmed ? null : "Set status to Confirmed")}
                                            className={
                                                cls(`assignment-status-label font-weight-bold ${x.class.toLowerCase()}`, {
                                                    [`active`]: x.value === asn.assignment.confirmationStatusId
                                                })
                                            }
                                            onClick={() => this.showConfirmationMessage(asn)}
                                        >
                                            {x.label}
                                            {!!(x.value < ConfirmationStatus.Confirmed) && <i className="text-muted fa fa-angle-double-right ml-"></i>}
                                        </Button>
                                    )
                                }
                            </ButtonGroup>
                        </ButtonToolbar>
                    </FlexStartRow>
                    <FlexStartRow className="pl-2">
                        <small
                            className="site-link"
                            title="View Change Log"
                            onClick={() => this.onShowChangeLogClicked({ ...asn })}>
                            View Change Log
                        </small>
                    </FlexStartRow>
                </FlexColumnCenter>
            </FormGroup>
        )
    }

    render() {
        const { jobDetails } = { ...this.props };
        const { formValidated, notes, saving, showConfirmation, selectedAssignment } = { ...this.state };

        if (!jobDetails)
            return null;

        const { assignments } = { ...jobDetails };
        const selectedAssignmentName = selectedAssignment?.assignment?.name ?? selectedAssignment?.assignment?.description;

        return (
            <>
                <SlideForm
                    size="col-xl-5 col-lg-6 col-md-8 col-sm-12"
                    loading={this.state.loading}
                    show={this.props.show}
                    id={"jobWorkflowEditor"}
                    formIcon={faRecycle}
                    formTitle={this.getFormTitle()}
                    ref={this.formRef}
                    setIsValidated={(value) => this.setState({ formValidated: value })}
                    isValidated={formValidated}
                    className="job-workflow-editor"
                    onSubmit={this.onSubmit.bind(this)}
                    onClose={this.onClose.bind(this)}
                    onClearErrors={() => { }}
                    errors={[]}
                    validationMessage={''}
                    hideToolbar={ true }
                >
                <Container fluid className="p-2">
                    <Row>
                        <Col>
                            <SubHeading className="mb-2">Day Details</SubHeading>
                            {this.getJobDetails()}
                            <SubHeading>Assignments</SubHeading>
                            <FlexStartRow className="p-2">
                                <small className="text-warning">
                                    <i className="text-warning fa fa-lg fa-exclamation-circle mr-2"></i>
                                    You may manually confirm an unconfirmed job assignment here.  Please note that this override will not provide employees with notifications.</small>
                            </FlexStartRow>
                            <FlexStartRow className="p-2">
                                <small className="text-warning">
                                    <i className="text-warning fa fa-lg fa-exclamation-circle mr-2"></i>
                                    Please note that you cannot confirm equipment until at least one crew member has been confirmed for the job.</small>
                            </FlexStartRow>
                            <FlexColumnCenter className="day-assignments">
                                {
                                    assignments?.staff?.map((asn, asn_inx) => !!asn.assignment && this.getConfirmationControls(asn, asn_inx))
                                }
                                {
                                    assignments?.equipment?.map((asn, asn_inx) => !!asn.assignment && this.getConfirmationControls(asn, asn_inx))
                                }
                            </FlexColumnCenter>
                        </Col>
                    </Row>
                    <div className={cls("edit-status-confirmation border", { 'show': showConfirmation })}>
                        <div className="p-3">
                            {`Set assignment status for ${selectedAssignmentName} on this job to confirmed?`}
                            <FormGroup className="mt-3">
                                <FormLabel required={true} text='Notes' htmlFor="manualConfirmNotes"></FormLabel>
                                <textarea
                                    disabled={!!saving}
                                    id="manualConfirmNotes"
                                    name="manualConfirmNotes"
                                    className="form-control"
                                    defaultValue={notes ?? ''}
                                    onChange={(event) => {
                                        let notes = event.target.value;
                                        this.setState({ notes: notes });
                                    }}
                                    required
                                    placeholder="Detail the reason for the manual status change (500 characters max)"
                                    type="text"
                                    maxLength="500"
                                    rows="5"
                                />
                                <small className={`text-right text-field-count ${(notes.length < 500 ? 'text-success' : 'text-danger')}`}>{`${500 - notes.length} remaining`}</small>
                                <small className="invalid-feedback text-danger">Notes are required and must not exceed 500 characters in length.</small>
                            </FormGroup>
                            <FlexEndRow className="mt-3 p-3 border-top">
                            {
                                !!saving &&
                                <>
                                    <span className="fa fa-spin fa-circle-notch mr-2" size="lg" />Setting assignment status. Please wait...
                                </>
                            }
                            {
                                !saving &&
                                <>                                    
                                    <Button className="mr-2" type="submit" color="primary">Yes, confirm assignment</Button>
                                    <Button color="secondary" onClick={() => this.onCancelConfirmClicked()}>Cancel</Button>
                                </>
                            }
                            </FlexEndRow>
                        </div>
                    </div>
                    <FormBlocker show={showConfirmation} />
                </Container>
                </SlideForm>
                <ChangeLogViewer
                    title={this.state.changeLog.title}
                    onClose={this.onCloseChangeLog}
                    changeLog={this.state.changeLog }
                    formRef={ this.changeLogRef }
                />
            </>
        );
    }
}