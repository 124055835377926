import React from 'react';
import {
    DataGrid,
    TextFilterDefaults,
    LinkCellRenderer,
    ButtonCellRenderer,
    createDataSource,
    DateFilterDefaults,
    getMappedFilterModel,
    ServerSideScrollRowsRequest,
    indexCellRenderer,
    DateRangeReadOnlyFloatingFilterComponent,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, {
    ApiRoutes,
    ApplicationPermissions,
    AppNavPaths,
    LocalizationKeys as l,
} from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendarDay,
    faHistory,
    faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { LinearProgress } from '@material-ui/core';
import {
    FlexColumnStart,
    FlexStartRow,
    PageWrap,
    SmallButton,
} from '../common/forms/FormElements';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import authService from '../api-authorization/AuthorizeService';
import moment from 'moment';
import { renderToString } from 'react-dom/server';
import { util } from '../Util';
import { getTenantUserProfile } from '../common/TenantUserProfile';
import classnames from 'classnames';

const gsfFilterParams = {
    suppressFilterButton: true,
    options: [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
    ],
    optionsLabel: 'label',
    optionsValue: 'value',
    disableFilter: false,
    initialFilterValue: '',
};

class DailyJobView extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        //window.addEventListener('resize', this.onWindowResized);

        const viewportWidth = window.innerWidth;

        this.state = {
            loading: true,
            loadingData: false,
            rowData: [],
            historyRowData: [],
            selectedRow: null,
            isMobile: viewportWidth <= 767,
            activeTab: '1',
        };
    }

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    };

    componentWillUnmount = () =>
    (this.setState = (state, callback) => {
        return;
    });

    async populateState() {
        const isAuthenticated = await authService.isAuthenticated();

        if (!!isAuthenticated) {
            const [strings, currentUserProfile] = await Promise.all([
                util.l10n.getStrings([l.crewLead]),
                getTenantUserProfile(),
            ]);

            const isMobile = window.innerWidth <= 767;
            await this.setState(
                {
                    strings: strings,
                    currentUserProfile: currentUserProfile,
                },
                async () => {
                    await this.setupGrid(isMobile);
                    await this.setupHistoryGrid(isMobile);
                }
            );
        }
    }

    onViewJob = (id) => {
        this.props.history.push(`${AppNavPaths.Job}/${id}`);
    };

    getGridOptions = async (isMobile) => {
        const { currentUserProfile } = { ...this.state };

        const { strings } = this.state;
        const crewLead = strings ? strings[l.crewLead] : '';

        const { currentUser, userPermissions } = { ...currentUserProfile };
        const hasJobAccess =
            userPermissions.includes(ApplicationPermissions.job_create) ||
            userPermissions.includes(ApplicationPermissions.job_edit) ||
            userPermissions.includes(ApplicationPermissions.job_edit);
        //for now default to false
        isMobile = false;

        let [dispatchLocations] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
        ]);

        //Admins can select all dispatch, others default to their dispatch loc.
        let initialDispatchValue = null;
        if (!currentUser.isAdmin)
            initialDispatchValue = currentUser.dispatchCompanyId;

        const dispatchFilterParams = {
            suppressFilterButton: true,
            options: dispatchLocations,
            optionsLabel: 'label',
            optionsValue: 'value',
            disableFilter: false,
            initialFilterValue: initialDispatchValue,
        };

        let context = this;
        let opts = {
            domLayout: 'normal',
            frameworkComponents: {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                linkRenderer: LinkCellRenderer,
                buttonRenderer: ButtonCellRenderer,
                nameRenderer: LinkCellRenderer,
            },
            suppressMenuHide: true,
            headerHeight: !!isMobile ? 0 : 34,
            rowHeight: !!isMobile ? 76 : 40,
            suppressPropertyNamesCheck: true,
            rowModelType: 'infinite',
            cacheBlockSize: 18, //with including subcontractors, row height increases. less rows are visible
            defaultColDef: {
                sortable: !isMobile,
                resizable: !isMobile,
                suppressMenu: true,
                floatingFilter: !isMobile,
            },
            filter: !isMobile,
            unSortIcon: true,
            rowSelection: 'single',
            multiSortKey: 'ctrl',
            onSelectionChanged: (event) => {
                let selection = event.api.getSelectedRows();
                context.setState({
                    selectedRow: !!selection.length ? selection[0] : null,
                });
            },
            refresh: () => {
                context.state.gridApi.setDatasource(context.state.dataSource);
            },
            setStatus: (status) => {
                context.setState({ gridStatus: status });
            },
            columnDefs: [
                {
                    flex: 0,
                    width: 70,
                    minWidth: 70,
                    maxWidth: 70,
                    headerName: '',
                    valueGetter: 'node.id',
                    sortable: false,
                    cellRenderer: indexCellRenderer,
                },
                {
                    colId: 'Customer',
                    minWidth: 220,
                    flex: 1,
                    headerName: 'Customer',
                    field: 'customer',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: (params) => {
                        if (!params?.node?.data) return null;

                        let contents = `${params.node.data.customer}`;

                        if (!!params?.node?.data?.subcontractor)
                            return (
                                <span>
                                    {params.node.data.customer}
                                    <br />
                                    <small class="text-muted">
                                        {params.node.data.subcontractor}
                                    </small>
                                </span>
                            );

                        return params.node.data.customer;
                    },
                },
                {
                    colId: 'CrewLead',
                    flex: 1,
                    minWidth: 220,
                    headerName: crewLead,
                    headerClass: 'jobview-crewlead',
                    field: 'crewLead',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: (params) => {
                        if (!params?.node?.data) return null;

                        if (!!params?.node?.data?.crewLead)
                            return params.node.data.crewLead;
                        else return <span> - </span>;
                    },
                },
                {
                    colId: 'Foreman',
                    flex: 1,
                    minWidth: 220,
                    headerName: 'Customer Foreman',
                    field: 'foreman',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'DispatchCompanyId',
                    headerName: 'Dispatching',
                    sortable: true,
                    minWidth: 220,
                    flex: 1,
                    field: 'dispatchLocation',
                    tooltipField: 'dispatchLocation',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: { ...dispatchFilterParams },
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: { ...dispatchFilterParams },
                },
                {
                    colId: 'JobNumber',
                    minWidth: 180,
                    flex: 1,
                    headerName: 'Job Number',
                    field: 'jobNumber',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: !!hasJobAccess ? 'nameRenderer' : null,
                    cellRendererParams: !hasJobAccess
                        ? null
                        : {
                            clicked: this.onViewJob,
                            nameField: 'jobNumber',
                            idField: 'jobId',
                            title: 'Go to this Job',
                        },
                },
                {
                    colId: 'JobLocation',
                    minWidth: 220,
                    flex: 1,
                    headerName: 'Job Location',
                    field: 'jobLocation',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: 'linkRenderer',
                    cellRendererParams: {
                        clicked: (address) => {
                            window.open(
                                `https://maps.google.com?q=${address}`,
                                '_blank'
                            );
                        },
                        nameField: 'jobLocation',
                        idField: 'jobLocationAddress',
                        title: 'View this Job Location',
                    },
                },
                {
                    width: 220,
                    minWidth: 220,
                    colId: 'DateRange',
                    headerName: 'Date',
                    field: 'assignmentDate',
                    sortable: true,
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        ...DateFilterDefaults,
                        inRangeInclusive: true,
                        closeOnApply: true,
                        browserDatePicker: true,
                        suppressFilterButton: true,
                    },
                    floatingFilter: true,
                    floatingFilterComponent:
                        DateRangeReadOnlyFloatingFilterComponent,
                    floatingFilterComponentParams: {},
                },
                {
                    width: 100,
                    minWidth: 100,
                    colId: 'StartTime',
                    headerName: 'Start',
                    headerClass: 'text-center',
                    field: 'startTime',
                    sortable: true,
                },
                {
                    headerName: 'Required',
                    width: 120,
                    minWidth: 120,
                    field: 'requiredResources',
                    sortable: false,
                    cellStyle: { textAlign: 'center' },
                    headerClass: 'text-center',
                },
                {
                    headerName: 'Assignments',
                    width: 120,
                    minWidth: 120,
                    field: 'assignmentCount',
                    sortable: false,
                    cellStyle: { textAlign: 'center' },
                    headerClass: 'text-center',
                },
                {
                    headerName: 'Unfilled',
                    width: 120,
                    minWidth: 120,
                    field: 'unfilledCount',
                    sortable: false,
                    cellStyle: { textAlign: 'center' },
                    headerClass: 'text-center',
                },
                {
                    colId: 'HasGSF',
                    headerName: 'GSF',
                    width: 120,
                    minWidth: 120,
                    field: 'hasGSF',
                    sortable: true,
                    cellStyle: { textAlign: 'center' },
                    headerClass: 'text-center',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: { ...gsfFilterParams },
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: { ...gsfFilterParams },
                },
                {
                    width: 70,
                    minWidth: 70,
                    headerName: '',
                    headerClass: 'jobview-timesheets-completed text-center',
                    field: 'completedTimesheets',
                    sortable: false,
                    cellRenderer: (params) => {
                        if (!params.data) return '';
                        let completed = params.data?.completedTimesheets ?? 0,
                            count = params.data?.timesheetsCount ?? 0;
                        let done = completed > 0 && completed === count;

                        const display = count > 0 ? completed / count : 0;

                        return (
                            <div className="w-100 text-right">
                                <span
                                    style={{ fontSize: '1em' }}
                                    className={`badge ${!!done
                                            ? 'badge-success'
                                            : 'badge-secondary'
                                        }`}
                                >
                                    {display}
                                </span>
                            </div>
                        );
                    },
                    cellStyle: { textAlign: 'center' },
                },
            ],
        };

        opts.onGridReady = (params) => {
            //default date filter values
            let today = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');

            const dateRangeFilterComponent =
                params.api.getFilterInstance('DateRange');
            
            dateRangeFilterComponent.setModel({
                filterType: 'date',
                type: 'inRange',
                dateFrom: today,
                dateTo: today,
            });

            params.api.onFilterChanged();
            opts.api = params.api;
            params.api.setDatasource(context.state.dataSource, opts);
        };

        opts.setLoading = (b) => {
            context.setState({ loadingData: b });
        };

        opts.showLoadingOverlay = false;
        opts.overlayNoRowsTemplate =
            'No job information found for the selected filters';

        //debug only
        //opts.successCallback = (data) => context.setState({ rowData: data });

        if (!!isMobile) {
            opts.isFullWidthCell = () => true;
            opts.fullWidthCellRenderer = context.fullWidthCellRenderer;
        } else {
            opts.isFullWidthCell = () => {
                return false;
            };
            opts.fullWidthCellRenderer = null;
        }

        opts.defaultColDef = {
            ...opts.defaultColDef,
            cellStyle: {
                lineHeight: '18px',
                display: 'flex',
                alignItems: 'center',
            },
        };

        //Custom validation of filters.  We are primarily concerned with date.
        //Ensure it's valid.
        opts.validateParametersFunction = this.validateParameters;

        return opts;
    };

    getHistoryGridOptions = async (isMobile) => {
        const { currentUserProfile } = { ...this.state };

        const { strings } = this.state;
        const crewLead = strings ? strings[l.crewLead] : '';

        const { currentUser, userPermissions } = { ...currentUserProfile };
        const hasJobAccess =
            userPermissions.includes(ApplicationPermissions.job_create) ||
            userPermissions.includes(ApplicationPermissions.job_edit) ||
            userPermissions.includes(ApplicationPermissions.job_edit);
        //for now default to false
        isMobile = false;

        let [dispatchLocations] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
        ]);

        //Admins can select all dispatch, others default to their dispatch loc.
        let initialDispatchValue = null;
        if (!currentUser.isAdmin)
            initialDispatchValue = currentUser.dispatchCompanyId;

        const dispatchFilterParams = {
            suppressFilterButton: true,
            options: dispatchLocations,
            optionsLabel: 'label',
            optionsValue: 'value',
            disableFilter: false,
            initialFilterValue: initialDispatchValue,
        };

        let context = this;
        let opts = {
            domLayout: 'normal',
            frameworkComponents: {
                selectFilter: DataGridSelectFilter,
                selectFloatingFilter: DataGridSelectFloatingFilter,
                linkRenderer: LinkCellRenderer,
                buttonRenderer: ButtonCellRenderer,
                nameRenderer: LinkCellRenderer,
            },
            suppressMenuHide: true,
            headerHeight: !!isMobile ? 0 : 34,
            rowHeight: !!isMobile ? 76 : 40,
            suppressPropertyNamesCheck: true,
            rowModelType: 'infinite',
            cacheBlockSize: 18, //with including subcontractors, row height increases. less rows are visible
            defaultColDef: {
                sortable: !isMobile,
                resizable: !isMobile,
                suppressMenu: true,
                floatingFilter: !isMobile,
            },
            filter: !isMobile,
            unSortIcon: true,
            rowSelection: 'single',
            multiSortKey: 'ctrl',
            onSelectionChanged: (event) => {
                let selection = event.api.getSelectedRows();
                context.setState({
                    selectedRow: !!selection.length ? selection[0] : null,
                });
            },
            refresh: () => {
                context.state.gridApi.setDatasource(
                    context.state.historyDataSource
                );
            },
            setStatus: (status) => {
                context.setState({ gridStatus: status });
            },
            columnDefs: [
                {
                    flex: 0,
                    width: 70,
                    minWidth: 70,
                    maxWidth: 70,
                    headerName: '',
                    valueGetter: 'node.id',
                    sortable: false,
                    cellRenderer: indexCellRenderer,
                },
                {
                    colId: 'Customer',
                    minWidth: 220,
                    flex: 1,
                    headerName: 'Customer',
                    field: 'customer',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: (params) => {
                        if (!params?.node?.data) return null;

                        let contents = `${params.node.data.customer}`;

                        if (!!params?.node?.data?.subcontractor)
                            return (
                                <span>
                                    {params.node.data.customer}
                                    <br />
                                    <small class="text-muted">
                                        {params.node.data.subcontractor}
                                    </small>
                                </span>
                            );

                        return params.node.data.customer;
                    },
                },
                {
                    colId: 'CrewLead',
                    flex: 1,
                    minWidth: 220,
                    headerName: crewLead,
                    headerClass: 'jobview-crewlead',
                    field: 'crewLead',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: (params) => {
                        if (!params?.node?.data) return null;

                        if (!!params?.node?.data?.crewLead)
                            return params.node.data.crewLead;
                        else return <span> - </span>;
                    },
                },
                {
                    colId: 'Foreman',
                    flex: 1,
                    minWidth: 220,
                    headerName: 'Customer Foreman',
                    field: 'foreman',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    colId: 'DispatchCompanyId',
                    headerName: 'Dispatching',
                    sortable: true,
                    minWidth: 220,
                    flex: 1,
                    field: 'dispatchLocation',
                    tooltipField: 'dispatchLocation',
                    filter: 'selectFilter',
                    floatingFilter: true,
                    filterParams: { ...dispatchFilterParams },
                    floatingFilterComponent: 'selectFloatingFilter',
                    floatingFilterComponentParams: { ...dispatchFilterParams },
                },
                {
                    colId: 'JobNumber',
                    minWidth: 180,
                    flex: 1,
                    headerName: 'Job Number',
                    field: 'jobNumber',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: !!hasJobAccess ? 'nameRenderer' : null,
                    cellRendererParams: !hasJobAccess
                        ? null
                        : {
                              clicked: this.onViewJob,
                              nameField: 'jobNumber',
                              idField: 'jobId',
                              title: 'Go to this Job',
                          },
                },
                {
                    colId: 'JobLocation',
                    minWidth: 220,
                    flex: 1,
                    headerName: 'Job Location',
                    field: 'jobLocation',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                    cellRenderer: 'linkRenderer',
                    cellRendererParams: {
                        clicked: (address) => {
                            window.open(
                                `https://maps.google.com?q=${address}`,
                                '_blank'
                            );
                        },
                        nameField: 'jobLocation',
                        idField: 'jobLocationAddress',
                        title: 'View this Job Location',
                    },
                },
                {
                    width: 220,
                    minWidth: 220,
                    colId: 'DateRange',
                    headerName: 'Date',
                    field: 'assignmentDate',
                    sortable: true,
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        ...DateFilterDefaults,
                        inRangeInclusive: true,
                        closeOnApply: true,
                        browserDatePicker: true,
                        suppressFilterButton: true,
                    },
                    floatingFilter: true,
                    floatingFilterComponent:
                        DateRangeReadOnlyFloatingFilterComponent,
                    floatingFilterComponentParams: {},
                },
                {
                    width: 100,
                    minWidth: 100,
                    colId: 'StartTime',
                    headerName: 'Start',
                    headerClass: 'text-center',
                    field: 'startTime',
                    sortable: true,
                },
                {
                    headerName: 'Required',
                    width: 120,
                    minWidth: 120,
                    field: 'requiredResources',
                    sortable: false,
                    cellStyle: { textAlign: 'center' },
                    headerClass: 'text-center',
                },
                {
                    headerName: 'Assignments',
                    width: 120,
                    minWidth: 120,
                    field: 'assignmentCount',
                    sortable: false,
                    cellStyle: { textAlign: 'center' },
                    headerClass: 'text-center',
                },
                {
                    headerName: 'Unfilled',
                    width: 120,
                    minWidth: 120,
                    field: 'unfilledCount',
                    sortable: false,
                    cellStyle: { textAlign: 'center' },
                    headerClass: 'text-center',
                },
                {
                    colId: 'HasGSF',
                    headerName: 'GSF',
                    width: 120,
                    minWidth: 120,
                    field: 'hasGSF',
                    sortable: true,
                    cellStyle: { textAlign: 'center' },
                    headerClass: 'text-center',
                    filter: 'agTextColumnFilter',
                    filterParams: TextFilterDefaults,
                    floatingFilterComponentParams: {
                        suppressFilterButton: true,
                    },
                },
                {
                    width: 70,
                    minWidth: 70,
                    headerName: '',
                    headerClass: 'jobview-timesheets-completed text-center',
                    field: 'completedTimesheets',
                    sortable: false,
                    cellRenderer: (params) => {
                        if (!params.data) return '';
                        let completed = params.data?.completedTimesheets ?? 0,
                            count = params.data?.timesheetsCount ?? 0;
                        let done = completed > 0 && completed === count;

                        const display = count > 0 ? completed / count : 0;

                        return (
                            <div className="w-100 text-right">
                                <span
                                    style={{ fontSize: '1em' }}
                                    className={`badge ${
                                        !!done
                                            ? 'badge-success'
                                            : 'badge-secondary'
                                    }`}
                                >
                                    {display}
                                </span>
                            </div>
                        );
                    },
                    cellStyle: { textAlign: 'center' },
                },
            ],
        };

        opts.onGridReady = (params) => {
            //default date filter values
            let today = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
            const dateRangeFilterComponent =
                params.api.getFilterInstance('DateRange');
            dateRangeFilterComponent.setModel({
                filterType: 'date',
                type: 'inRange',
                dateFrom: today,
                dateTo: today,
            });

            params.api.onFilterChanged();
            opts.api = params.api;
            params.api.setDatasource(context.state.historyDataSource, opts);
        };

        opts.setLoading = (b) => {
            context.setState({ loadingData: b });
        };

        opts.showLoadingOverlay = false;
        opts.overlayNoRowsTemplate =
            'No job information found for the selected filters';

        //debug only
        //opts.successCallback = (data) => context.setState({ rowData: data });

        if (!!isMobile) {
            opts.isFullWidthCell = () => true;
            opts.fullWidthCellRenderer = context.fullWidthCellRenderer;
        } else {
            opts.isFullWidthCell = () => {
                return false;
            };
            opts.fullWidthCellRenderer = null;
        }

        opts.defaultColDef = {
            ...opts.defaultColDef,
            cellStyle: {
                lineHeight: '18px',
                display: 'flex',
                alignItems: 'center',
            },
        };

        //Custom validation of filters.  We are primarily concerned with date.
        //Ensure it's valid.
        opts.validateParametersFunction = this.validateHistoryParameters;

        return opts;
    };

    validateParameters = (filter_model) => {
        let validationMessages = [];

        //Date Range
        if (
            !filter_model?.DateRange?.dateFrom ||
            !filter_model?.DateRange?.dateTo
        ) {
            validationMessages.push('[Date From] and [Date To] are required.');
        } else {
            let mFrom = moment(filter_model.DateRange.dateFrom);
            let mTo = moment(filter_model.DateRange.dateTo);

            if (!mTo.isSameOrAfter(mFrom))
                validationMessages.push(
                    `Invalid date range: ${mFrom.format(
                        'M/D/YY'
                    )} - ${mTo.format('M/D/YY')}`
                );

            if (mTo.diff(mFrom, 'days') > 14)
                validationMessages.push(
                    `Invalid date range: Date range cannot exceed 2 weeks.`
                );
        }
        return !!validationMessages.length
            ? validationMessages.join('\r\n')
            : '';
    };

    validateHistoryParameters = (filter_model) => {
        let validationMessages = [];

        //Date Range
        if (
            !filter_model?.DateRange?.dateFrom ||
            !filter_model?.DateRange?.dateTo
        ) {
            validationMessages.push('[Date From] and [Date To] are required.');
        } else {
            let mFrom = moment(filter_model.DateRange.dateFrom);
            let mTo = moment(filter_model.DateRange.dateTo);

            if (!mTo.isSameOrAfter(mFrom))
                validationMessages.push(
                    `Invalid date range: ${mFrom.format(
                        'M/D/YY'
                    )} - ${mTo.format('M/D/YY')}`
                );
        }
        return !!validationMessages.length
            ? validationMessages.join('\r\n')
            : '';
    };

    setupGrid = async (isMobile) => {
        let gridOptions = await this.getGridOptions(isMobile);

        let dataSource = createDataSource(
            ApiRoutes.job.dailyView(),
            gridOptions
        );

        await this.setState({
            loading: false,
            gridOptions: { ...gridOptions },
            dataSource: { ...dataSource },
            isMobile: isMobile,
            currentWidth: window.innerWidth,
        });
    };

    setupHistoryGrid = async (isMobile) => {
        let historyGridOptions = await this.getHistoryGridOptions(isMobile);

        let historyDataSource = createDataSource(
            ApiRoutes.job.dailyViewHistory(),
            historyGridOptions
        );

        await this.setState({
            loading: false,
            historyGridOptions: historyGridOptions,
            historyDataSource: historyDataSource,
            isMobile: isMobile,
            currentWidth: window.innerWidth,
        });
    };

    fullWidthCellRenderer = (params) => {
        const view = params?.node?.data;

        const { strings } = this.state;
        const crewLead = strings ? strings[l.crewLead] : '';

        return renderToString(
            <FlexStartRow className="p-2">
                <FlexColumnStart>
                    <strong>{view?.customer}</strong>
                    <span>
                        <strong className="text-muted mr-2">Foreman</strong>
                        {view?.foreman}
                    </span>
                    <span>
                        <strong className="text-muted mr-2">{crewLead}</strong>
                        {view?.crewLead}
                    </span>
                </FlexColumnStart>
                <FlexColumnStart></FlexColumnStart>
            </FlexStartRow>
        );
    };

    onExportToExcelClicked = async () => {
        const { gridOptions } = { ...this.state };
        const filterModel = gridOptions.api.getFilterModel();
        const sortModel = gridOptions.api.columnModel.getColumnState();
        const filterModelMapped = getMappedFilterModel(
            { ...filterModel },
            { ...gridOptions }
        );
        const req = new ServerSideScrollRowsRequest();
        req.startRow = 0;
        req.endRow = 9999999;
        req.filterModel = { ...filterModelMapped };
        req.sortModel = !!Object.keys(sortModel).length ? sortModel : [];
        req.sortModel.shift();
        const fileName = `JobDailyView_${moment().format(
            'yyyyMMdd_hhmmss'
        )}.xlsx`;
        await util.fetch.downloadFile(
            ApiRoutes.job.dailyViewExcelExport(),
            req,
            fileName
        );
    };

    onExportHistoryToExcelClicked = async () => {
        const { historyGridOptions } = { ...this.state };
        const filterModel = historyGridOptions.api.getFilterModel();
        const sortModel = historyGridOptions.api.columnModel.getColumnState();
        const filterModelMapped = getMappedFilterModel(
            { ...filterModel },
            { ...historyGridOptions }
        );
        const req = new ServerSideScrollRowsRequest();
        req.startRow = 0;
        req.endRow = 9999999;
        req.filterModel = { ...filterModelMapped };
        req.sortModel = !!Object.keys(sortModel).length ? sortModel : [];
        req.sortModel.shift();
        const fileName = `JobDailyViewHistory_${moment().format(
            'yyyyMMdd_hhmmss'
        )}.xlsx`;

        this.setState({
            isHistoryExporting: true,
        });

        await util.fetch.downloadFile(
            ApiRoutes.job.dailyViewHistoryExcelExport(),
            req,
            fileName
        );

        this.setState({
            isHistoryExporting: false,
        });
    };

    toggleTab = async (tab) => {
        const { activeTab } = this.state;

        if (activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    render() {
        let {
            dataSource,
            rowData,
            isHistoryExporting,
            gridOptions,
            loading,
            loadingData,
            historyDataSource,
            historyRowData,
            historyGridOptions,
            activeTab,
            saving,
        } = { ...this.state };

        if (Boolean(loading) || !gridOptions) {
            return <LinearProgress variant="indeterminate" color="secondary" />;
        }

        return (
            <PageWrap>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: activeTab === '1',
                            })}
                            onClick={() => {
                                this.toggleTab('1');
                            }}
                        >
                            <>
                                <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    className="mr-2 text-muted"
                                />
                                <span className="mr-2">{`Daily Jobs View`}</span>
                                {!!loadingData && (
                                    <span className="fa fa-spin fa-circle-notch text-danger"></span>
                                )}
                            </>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({
                                active: activeTab === '2',
                            })}
                            onClick={() => {
                                this.toggleTab('2');
                            }}
                        >
                            <>
                                <FontAwesomeIcon
                                    icon={faHistory}
                                    className="mr-2 text-muted"
                                />
                                <span className="mr-2">{`Job History`}</span>
                                {!!loadingData && (
                                    <span className="fa fa-spin fa-circle-notch text-danger"></span>
                                )}
                            </>
                        </NavLink>
                    </NavItem>
                </Nav>
                <p></p>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1"></TabPane>
                </TabContent>

                <TabContent activeTab={activeTab}>
                    <TabPane tabId="2"></TabPane>
                </TabContent>

                {activeTab === '1' && (
                    <>
                        <DataGridToolbar
                            mobileTitle="Daily Jobs View"
                            hideAdd={true}
                            hideExcelButton={true}
                            gridApi={gridOptions.api}
                            dataSource={dataSource}
                        >
                            <SmallButton
                                disabled={Boolean(saving)}
                                onClick={this.onExportToExcelClicked}
                            >
                                <i className="fa fa-file-excel fa-lg" />
                                <span className="ml-2 small-viewport-hide">
                                    Export to Excel
                                </span>
                            </SmallButton>
                        </DataGridToolbar>
                        <DataGrid
                            domLayout={'normal'}
                            rowData={rowData}
                            gridOptions={gridOptions}
                        />
                    </>
                )}

                {activeTab === '2' && (
                    <>
                        <DataGridToolbar
                            mobileTitle="Jobs History"
                            hideAdd={true}
                            hideExcelButton={true}
                            gridApi={historyGridOptions.api}
                            dataSource={historyDataSource}
                        >
                            <SmallButton
                                disabled={Boolean(saving)}
                                onClick={this.onExportHistoryToExcelClicked}
                            >
                                <i className="fa fa-file-excel fa-lg" />
                                <span className="ml-2 small-viewport-hide">
                                    Export to Excel
                                </span>
                            </SmallButton>
                        </DataGridToolbar>
                        <DataGrid
                            domLayout={'normal'}
                            rowData={historyRowData}
                            gridOptions={historyGridOptions}
                        />
                    </>
                )}

                <Modal
                    backdrop="static"
                    keyboard={false}
                    isOpen={isHistoryExporting}
                >
                    <ModalHeader>Job History Export</ModalHeader>
                    <ModalBody>
                        <FontAwesomeIcon
                            icon={faCircleNotch}
                            className="fa-spin mr-2"
                            size="sm"
                        />
                        &nbsp;Exporting...
                    </ModalBody>
                </Modal>
            </PageWrap>
        );
    }
}
export default withRouter(DailyJobView);
