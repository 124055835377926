import { faTasks, faComments } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import SlideForm from '../common/forms/SlideForm';
import InvoiceIssuesWidget from '../widgets/InvoiceIssuesWidget';

import CommonContext, { ApiRoutes } from '../Common';
import {
    getFilterModel,
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    LinkCellRenderer,
    IconCellRenderer,
    VariableLinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
} from '../common/dataGrid/DataGrid';
import {
    Button,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormText,
} from 'reactstrap';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import {
    FlexRow,
    SmallButton,
    PageHeading,
    PageWrap,
    toasty,
    FormLabel,
    onFieldChange,
    FormCheckbox,
    FlexCenterRow
} from '../common/forms/FormElements';
import { faCircleNotch } from '@fortawesome/fontawesome-free-solid';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { util } from '../Util';
import CustomCircularProgress from '../common/CustomCircularProgress';
import { NotAuthorizedPage } from '../status/StatusCodes';
import InvoiceSlideout from '../invoice/InvoiceSlideout';
import FormErrorResponseDisplay from '../common/forms/FormErrorResponseDisplay';
import * as moment from 'moment';

class InvoiceIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.invoiceSlideoutRef = React.createRef();
        this.invoiceIssuesSlideoutRef = React.createRef();

        const yesNo = [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
        ];

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            invoiceSlideoutOpen: false,
            selectedDetails: [],
            loadingReport: false,
            showExportModal: false,
            isExporting: false,
            yesNo: yesNo,
            isApproving: false,
            isDownloading: false,
            isDownloadingByContract: false,
            isEmailingByContract: false,
            errorResponse: {},
            invoiceExportsClearing: [],
            willClearInvoiceExports: false,
            isClearingInvoiceExports: false,
            disableExport: true,
            disableClearExport: true,
            disableDownloadByContract: true,
            clearExportItems: new Set(),
            showInvoiceIssuesSlideout: false,
            invoiceIssueMessages: [],
            timesheetNumbers: ''
        };

        this.onRowClicked = this.onRowClicked.bind(this);
        this.onCellClicked = this.onCellClicked.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount = () => this.populateState();
    componentWillUnmount = () =>
        (this.setState = (state, callback) => {
            return;
        });

    populateState = async () => {
        let [invoiceStatuses, customers] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.invoiceStatuses()),
            util.fetch.js(ApiRoutes.typeAheads.customers()),
        ]);

        let context = this;
        const gridOptions = createGridOptions(this);

        //https://www.ag-grid.com/documentation/javascript/row-selection/#checkbox-selection
        //One column must have "checkboxSelection" set to true.
        //Note: headerCheckboxSelection not available with infinite scroll.
        gridOptions.rowSelection = 'multiple';

        gridOptions.postProcessData = this.transformRowData;
        gridOptions.onRowSelected = this.onRowSelected;

        gridOptions.rowClassRules = {
            'ag-row-warning': (params) => {
                return (params.data ?? {}).hasOverride === 'Yes';
            },
            'ag-row-danger': (params) => {
                return (params.data ?? {}).isEmergency === true;
            },
        };

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
            iconRenderer: IconCellRenderer,
            variableLinkRenderer: VariableLinkCellRenderer,
        };
        gridOptions.onRowClicked = this.onRowClicked;

        let invoiceStatusFilterParams = {
            suppressFilterButton: true,
            options: invoiceStatuses,
            optionsLabel: 'label',
            optionsValue: 'value',
        };

        gridOptions.columnDefs = [
            {
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'InvoiceDate',
                headerName: 'Date',
                field: 'invoiceDate',
                sortable: true,
                flex: 1.5,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
            },
            {
                colId: 'invoiceEmailDate',
                headerName: 'Emailed On',
                field: 'invoiceEmailDate',
                sortable: true,
                flex: 1.5,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
            },
            {
                colId: 'invoiceNumber',
                headerName: 'Invoice #',
                field: 'invoiceNumber',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellRenderer: (params) => {
                    if (params.data) {
                        if (
                            params.data.invoiceStatus === 'Approved' ||
                            params.data.invoiceStatus === 'Exported'
                        ) {
                            return (
                                <span
                                    className="site-link"
                                    disabled={!!this.context.formIsOpen}
                                    title="Print Invoice"
                                    onClick={() => {
                                        this.onPrintInvoice(params.data.id);
                                    }}
                                >
                                    {params.data.invoiceNumber}
                                    <i className={`fa fa-print fa-xs ml-2`} />
                                </span>
                            );
                        }

                        return params.data.invoiceNumber;
                    }

                    return null;
                },
            },
            {
                colId: 'timesheetNumber',
                headerName: 'Timesheet Number',
                field: 'timesheetNumber',
                sortable: false,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'customerName',
                headerName: 'Customer Name',
                field: 'customerName',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'DispatchOfficeLocation.CompanyName', //leave this, it doesnt map to an ef prop but is triggered for the sort on the dynamic field.
                headerName: 'Class',
                field: 'dispatchOfficeLocationName',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'ContractNumber', //leave this, it doesnt map to an ef prop but is triggered for the sort on the dynamic field.
                headerName: 'Contract #',
                field: 'contractNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'WorkOrderNumber',
                headerName: 'Work Order #',
                field: 'workOrderNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'InvoiceStatusId',
                headerName: 'Invoice Status',
                field: 'invoiceStatus',
                sortable: true,
                flex: 1,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: invoiceStatusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: invoiceStatusFilterParams,
            },
            {
                colId: 'invoiceAmount',
                headerName: 'Invoice Amount',
                field: 'invoiceAmount',
                sortable: true,
                flex: 1,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'memo',
                headerName: 'Memo',
                field: 'memo',
                sortable: true,
                flex: 1,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 75,
                headerName: 'Billing Issue',
                sortable: false,
                cellRenderer: (params) => {
                    if (params.data) {
                        return (
                            <SmallButton
                                title="Reject"
                                type="button"
                                onClick={() => {
                                    this.onInvoiceIssuesSlideout(params.data);
                                }}
                            >
                                <i className={`fa fa-comments fa-md mr-2`} />
                            </SmallButton>
                        );
                    }

                    return null;
                },
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 100,
                headerName: 'Invoice',
                sortable: false,
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: this.openInvoiceSlideout,
                    idField: 'id',
                    iconClass: 'fa-eye',
                },
            },
            {
                colId: 'HasOvertime',
                flex: 0,
                maxWidth: 120,
                headerName: 'Over 40',
                sortable: true,
                cellRenderer: (params) => {
                    if (params.data) {
                        if (
                            params.data.useCTRReport &&
                            params.data.hasOvertime
                        ) {
                            return (
                                <button
                                    className="site-button-small btn btn-outline-primary btn-sm"
                                    style={{ border: 'none' }}
                                    onClick={() => {
                                        this.onOver40Download(params.data);
                                    }}
                                >
                                    <i
                                        className={`fa fa-file-excel fa-md mr-2`}
                                    />
                                </button>
                            );
                        }
                    }
                    return null;
                },
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 115,
                headerName: 'Timesheets',
                sortable: false,
                cellRenderer: (params) => {
                    if (params.data) {
                        return (
                            <button
                                className="site-button-small btn btn-outline-primary btn-sm"
                                style={{ border: 'none' }}
                                onClick={() => {
                                    this.onDownloadTimesheets(params.data);
                                }}
                            >
                                <i className={`fa fa-file-pdf fa-md mr-2`} />
                            </button>
                        );
                    }
                    return null;
                },
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 100,
                headerName: 'Email',
                sortable: false,
                cellRenderer: (params) => {
                    if (
                        params.data &&
                        (params.data.invoiceStatus === 'Approved' ||
                            params.data.invoiceStatus === 'Exported')
                    ) {
                        return (
                            <button
                                className="site-button-small btn btn-outline-primary btn-sm"
                                style={{ border: 'none' }}
                                onClick={() => {
                                    this.onEmailRowClick(params.data);
                                }}
                            >
                                <i className={`fa fa-envelope fa-md mr-2`} />
                            </button>
                        );
                    }
                    return null;
                },
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 100,
                headerName: 'CTR File',
                sortable: false,
                cellRenderer: (params) => {
                    if (params.data) {
                        if (
                            params.data.useCTRReport &&
                            (params.data.invoiceStatus === 'Approved' ||
                                params.data.invoiceStatus === 'Exported')
                        ) {
                            return (
                                <button
                                    className="site-button-small btn btn-outline-primary btn-sm"
                                    style={{ border: 'none' }}
                                    onClick={() => {
                                        this.onDownloadCTR(params.data);
                                    }}
                                >
                                    <i
                                        className={`fa fa-file-excel fa-md mr-2`}
                                    />
                                </button>
                            );
                        }
                    }
                    return null;
                },
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 100,
                headerName: 'Download',
                sortable: false,
                cellRenderer: (params) => {
                    if (params.data) {
                        if (
                            params.data.invoiceStatus === 'Approved' ||
                            params.data.invoiceStatus === 'Exported'
                        ) {
                            return (
                                <button
                                    className="site-button-small btn btn-outline-primary btn-sm"
                                    style={{ border: 'none' }}
                                    onClick={() => {
                                        this.onDownloadInvoice(params.data);
                                    }}
                                >
                                    <i
                                        className={`fa fa-download fa-md mr-2`}
                                    />
                                </button>
                            );
                        }
                    }
                    return null;
                },
            },
            {
                colId: 'InvoiceDownloadDate',
                headerName: 'Download Date',
                field: 'invoiceDownloadDate',
                sortable: true,
                flex: 1.5,
                tooltipField: 'invoiceDownloadBy',
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
            },
            {
                colId: 'quickbooksExportNumber',
                field: 'quickbooksExportNumber',
                flex: 0,
                headerName: 'Quickbooks',
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                sortable: true,
                cellRenderer: (params) => {
                    if (params.data) {
                        if (!!params.data.exportedToQuickbooks) {
                            return (
                                <button
                                    className="site-button-small btn btn-outline-primary btn-sm"
                                    style={{ border: 'none' }}
                                    onClick={() => {
                                        this.onGetExportFile(
                                            params.data.id, 'Quickbooks'
                                        );
                                    }}
                                >
                                    <i
                                        className={`fa fa-file-archive fa-md mr-2`}
                                    />
                                    <span>
                                        {params.data.quickbooksExportNumber}
                                    </span>
                                </button>
                            );
                        }
                    }
                    return null;
                },
            },
            {
                colId: 'NetSuiteExportNumber',
                field: 'netSuiteExportNumber',
                flex: 0,
                headerName: 'NetSuite',
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                sortable: true,
                cellRenderer: (params) => {
                    if (params.data) {
                        if (!!params.data.exportedToNetSuite) {
                            return (
                                <button
                                    className="site-button-small btn btn-outline-primary btn-sm"
                                    style={{ border: 'none' }}
                                    onClick={() => {
                                        this.onGetExportFile(
                                            params.data.id, 'NetSuite'
                                        );
                                    }}
                                >
                                    <i
                                        className={`fa fa-file-archive fa-md mr-2`}
                                    />
                                    <span>
                                        {params.data.netSuiteExportNumber}
                                    </span>
                                </button>
                            );
                        }
                    }
                    return null;
                },
            },
            {
                colId: 'SelectionPlaceholder',
                headerName: '',
                field: 'selectionPlaceholder',
                sortable: false,
                minWidth: 50,
                checkboxSelection: true,
            },
            {
                colId: 'ExportRevised',
                flex: 0,
                maxWidth: 150,
                headerName: 'Revised',
                sortable: true,
                cellRenderer: (params) => {
                    if (params.data) {
                        if (params.data.exportRevised == true) {
                            return (
                                <button
                                    className="site-button-small btn btn-outline-primary btn-sm"
                                    style={{ border: 'none' }}
                                >
                                    <i className={`fa fa-check fa-md mr-2`} />
                                </button>
                            );
                        }
                    }
                    return null;
                },
            },
            {
                colId: 'ModifiedByUser',
                headerName: 'Last Modified By',
                field: 'lastModifiedBy',
                sortable: true,
                flex: 5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: '!ModifiedOn.HasValue ? CreatedOn : ModifiedOn',
                headerName: 'Last Modified On',
                field: 'lastModifiedOn',
                sortable: true,
                flex: 7,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
            },
        ];

        gridOptions.postProcessData = (data) => {
            context.setState({
                rowData: data.rows,
                scoreboardData: { ...data.counts } ?? null,
            });

            return { ...data };
        };

        const dataSource = createDataSource(
            ApiRoutes.invoice.search(),
            gridOptions
        );

        this.setState({
            loading: false,
            gridOptions: gridOptions,
            dataSource: dataSource,
            isRejectingTimesheet: false,
        });
    };

    //https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    //Possibly use lodash dequeue?
    onRowSelected(e) {
        const rs = e.api.getSelectedRows();

        let canExport = rs.every((i) => {
            return i.invoiceStatus === 'Approved';
        });

        let exportNumber = rs[0]?.quickbooksExportNumber ?? '';

        let canClearExport = rs.every((i) => {
            return (
                i.invoiceStatus === 'Exported' &&
                i.quickbooksExportNumber === exportNumber
            );
        });

        let contractNumber = rs[0]?.contractNumber ?? '';
        let canExportContract = rs.every((i) => {
            return (
                i.contractNumber === contractNumber &&
                (i.invoiceStatus === 'Approved' ||
                    i.invoiceStatus === 'Exported')
            );
        });

        if (rs.length == 0) {
            canExport = false;
            canClearExport = false;
        }

        this.setState({
            rowsSelected: rs,
            disableExport: !canExport,
            disableClearExport: !canClearExport,
            disableDownloadByContract: !canExportContract,
        });
    }

    onCellClicked = (data) => {
        //test
        alert(data);
    };

    onRowClicked = (event) => {
        let selection = event.api.getSelectedRows();
        let row = !!selection.length ? selection[0] : null;

        if (row) {
            this.setState({ selectedRow: row });
        }
    };

    onChange = onFieldChange;
    handleSaveError = (err) => handleFormSaveError(this, err);

    displaySaveErrors = (response) =>
        this.setState((state) => {
            return { errorResponse: response };
        });
    clearSaveErrors = () =>
        this.setState((state) => {
            return { errorResponse: {} };
        });

    async toggleExportModal() {
        const { showExportModal } = this.state;
        this.setState({ showExportModal: !showExportModal });
    }

    onPrintInvoice = async (id) => {
        if (!!id) {
            await this.setState({ printing: true });

            let route = ApiRoutes.report.invoice(id);

            await this.setState({ printing: false });
            window.open(route, '_self');
        }
    };

    printTimesheet = async (id) => {
        if (!!id) {
            window.open(ApiRoutes.report.timesheetBilling(id), '_self');
        }
    };

    async onExportClick() {
        //this.setState({ loadingReport: true });
        this.setState({ isExporting: true });
        let { gridOptions } = this.state;
        let model = {};
        if (gridOptions.api.isAnyFilterPresent())
            model = getFilterModel(gridOptions.api.getFilterModel());
        try {
            let response = await util.fetch.post(
                ApiRoutes.billing.export(),
                model
            );
            if (response) {
                toasty.error(response);
            } else {
                toasty.success('Billing Report complete');
            }
        } catch {
            toasty.error('Error exporting billing');
        }
        //this.setState({ loadingReport: false });
        this.state.gridOptions.refresh();
        this.setState({ isExporting: false, showExportModal: false });
    }

    openInvoiceSlideout = async (id) => {
        if (!!id) {
            let model = {
                billingIds: null,
                invoiceId: id,
            };

            await this.context.setFormOpened(true);
            this.invoiceSlideoutRef.current.open(model);
        }
    };

    onInvoiceSlideoutClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ rowsSelected: [], isInvoicing: false });
        this.state.gridOptions.refresh();
    };

    onDownloadInvoice = async (data) => {
        if (!!data.id) {
            this.setState({ isDownloading: true });

            await util.fetch
                .downloadFile(
                    ApiRoutes.invoice.downloadInvoice(data.id),
                    null,
                    'Invoice ' + data.invoiceNumber + '.zip'
                )
                .catch(this.handleSaveError);

            this.setState({ isDownloading: false });

            this.state.gridOptions.refresh();
        }
    };

    onOver40Download = async (data) => {
        this.setState({ isDownloadingOver40: true });
        if (!!data.id) {
            await util.fetch
                .downloadFile(
                    ApiRoutes.invoice.proofof40(data.id),
                    null,
                    'Proof_Of_40_' + data.invoiceNumber + '.xlsx'
                )
                .catch(this.handleSaveError);
        }
        this.setState({ isDownloadingOver40: false });
    };

    onDownloadCTR = async (data) => {
        this.setState({ isDownloadingCTR: true });
        if (!!data.id) {
            await util.fetch
                .downloadFile(
                    ApiRoutes.invoice.ctrexport(data.id),
                    null,
                    'CTR Spreadsheet ' + data.invoiceNumber + '.xlsx'
                )
                .catch(this.handleSaveError);
        }
        this.setState({ isDownloadingCTR: false });
    };

    onDownloadTimesheets = async (data) => {
        this.setState({ isDownloadingTimesheets: true });
        if (!!data.id) {
            let timesheetsFileName = 'Timesheets ' + data.invoiceNumber;
            if (
                data.invoiceTimesheetUsesWO &&
                data.tcNumber != null &&
                data.tcNumber.length > 0
            ) {
                timesheetsFileName = data.tcNumber;
            }

            await util.fetch
                .downloadFile(
                    ApiRoutes.invoice.downloadInvoiceTimesheets(data.id),
                    null,
                    timesheetsFileName + '.pdf'
                )
                .catch(this.handleSaveError);
        }
        this.setState({ isDownloadingTimesheets: false });
    };

    onDownloadInvoices = async () => {
        this.setState({ isDownloadingByContract: true });

        let rowsSelected = this.state.rowsSelected;
        if (!!rowsSelected) {
            let model = rowsSelected.map((x) => x.id); //Invoice Id
            try {
                let response = await util.fetch.post(
                    ApiRoutes.invoice.downloadInvoices(),
                    model,
                    //This is required for the error to be brought back clean.
                    //If you don't specify this, the error handling does not work
                    util.fetch.format.none
                );

                if (!!response.ok) {
                    let filename = util.fileNameFromContentDisposition(
                        response.headers.get('content-disposition')
                    );
                    filename = filename.replaceAll('_', ' ');

                    let blob = await response.blob();
                    const a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.target = '_blank';
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                } else {
                    if (response.status === 400) {
                        let serviceResponse = await response.json();
                        this.displaySaveErrors(serviceResponse);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [await response.text()],
                            },
                        };
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            } catch (ex) {
                toasty.error(ex);
            } finally {
                this.state.gridOptions.refresh();
                this.setState({ isDownloadingByContract: false });
            }
        }

        this.state.gridOptions.refresh();
        this.setState({ isDownloading: false });
    };

    onEmailButtonClick = async () => {
        let { rowsSelected } = this.state;
        if (rowsSelected.length == 0) return;

        let customerName = rowsSelected[0].customerName;
        let invoiceCollationId = rowsSelected[0].invoiceCollationId;
        let invoiceDate = rowsSelected[0].invoiceDate;
        let useCTRReport = rowsSelected[0].useCTRReport;
        let emailTo =
            rowsSelected[0].billingContactEmail == null
                ? ''
                : rowsSelected[0].billingContactEmail;
        let data = rowsSelected.map((x) => x.id);
        let invoices = Array.from(data).join(',');

        let emlContent = '';
        let CTRFile = null;

        if (invoiceCollationId == 0) {
            // No collation - separate files
            let emailSubject = customerName + ' invoices for ' + invoiceDate;
            let htmlDocument =
                '<html>Attached are ' +
                customerName +
                ' invoices and timesheets for ' +
                invoiceDate +
                '</html>';
            let contentType = 'application/pdf';

            emlContent = 'data:message/rfc822 eml;charset=utf-8,';
            emlContent += 'To: ' + emailTo + '\n';
            emlContent += 'Subject: ' + emailSubject + '\n';
            emlContent += 'X-Unsent: 1' + '\n';
            emlContent +=
                'Content-Type: multipart/mixed; boundary=--boundary_text_string' +
                '\n\n';

            emlContent += '----boundary_text_string' + '\n';
            emlContent += 'Content-Type: text/html' + '\n';
            emlContent += '' + '\n';
            emlContent += htmlDocument + '\n\n';

            this.setState({ isEmailingByContract: true });

            for (let i = 0; i < rowsSelected.length; i++) {
                let timesheetsFile = await util.fetch
                    .js(
                        ApiRoutes.invoice.streamInvoiceTimesheets(
                            rowsSelected[i].id
                        )
                    )
                    .catch(this.handleSaveError);
                let invoiceFile = await util.fetch
                    .js(ApiRoutes.invoice.streamInvoice(rowsSelected[i].id))
                    .catch(this.handleSaveError);

                if (useCTRReport) {
                    CTRFile = await util.fetch
                        .js(ApiRoutes.invoice.streamCTRFile(rowsSelected[i].id))
                        .catch(this.handleSaveError);
                }

                let timesheetsFileName =
                    'Timesheets ' + rowsSelected[i].invoiceNumber;
                if (
                    rowsSelected[i].invoiceTimesheetUsesWO &&
                    rowsSelected[i].tcNumber != null &&
                    rowsSelected[i].tcNumber.length > 0
                ) {
                    timesheetsFileName = rowsSelected[i].tcNumber;
                }

                emlContent += '----boundary_text_string\n';
                emlContent +=
                    'Content-Type: application/pdf; name=Invoice ' +
                    rowsSelected[i].invoiceNumber +
                    '.pdf\n';
                emlContent += 'Content-Transfer-Encoding: base64\n';
                emlContent += 'Content-Disposition: attachment\n\n';
                emlContent += invoiceFile.data + '\n\n';

                emlContent += '----boundary_text_string\n';
                emlContent +=
                    'Content-Type: application/pdf; name=' +
                    timesheetsFileName +
                    '.pdf\n';
                emlContent += 'Content-Transfer-Encoding: base64\n';
                emlContent += 'Content-Disposition: attachment\n\n';
                emlContent += timesheetsFile.data + '\n\n';

                if (useCTRReport && CTRFile != null) {
                    emlContent += '----boundary_text_string\n';
                    emlContent +=
                        'Content-Type: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; name=CTR ' +
                        rowsSelected[i].invoiceNumber +
                        '.xlsx\n';
                    emlContent += 'Content-Transfer-Encoding: base64\n';
                    emlContent += 'Content-Disposition: attachment\n\n';
                    emlContent += CTRFile.data + '\n\n';
                }
            }

            this.setState({ isEmailingByContract: false });
        }

        if (invoiceCollationId == 1) {
            // Collated - as separate files
            let emailSubject = customerName + ' invoices for ' + invoiceDate;
            let htmlDocument =
                '<html>Attached are ' +
                customerName +
                ' invoices and timesheets for ' +
                invoiceDate +
                '</html>';
            let contentType = 'application/pdf';

            emlContent = 'data:message/rfc822 eml;charset=utf-8,';
            emlContent += 'To: ' + emailTo + '\n';
            emlContent += 'Subject: ' + emailSubject + '\n';
            emlContent += 'X-Unsent: 1' + '\n';
            emlContent +=
                'Content-Type: multipart/mixed; boundary=--boundary_text_string' +
                '\n\n';

            emlContent += '----boundary_text_string' + '\n';
            emlContent += 'Content-Type: text/html' + '\n';
            emlContent += '' + '\n';
            emlContent += htmlDocument + '\n\n';

            this.setState({ isEmailingByContract: true });

            for (let i = 0; i < rowsSelected.length; i++) {
                let invoiceFile = await util.fetch
                    .js(ApiRoutes.invoice.streamInvoices(rowsSelected[i].id))
                    .catch(this.handleSaveError);

                if (useCTRReport) {
                    CTRFile = await util.fetch
                        .js(ApiRoutes.invoice.streamCTRFile(rowsSelected[i].id))
                        .catch(this.handleSaveError);
                }

                emlContent += '----boundary_text_string\n';
                emlContent +=
                    'Content-Type: application/pdf; name=Invoice ' +
                    rowsSelected[i].invoiceNumber +
                    '.pdf\n';
                emlContent += 'Content-Transfer-Encoding: base64\n';
                emlContent += 'Content-Disposition: attachment\n\n';
                emlContent += invoiceFile.data + '\n\n';

                if (useCTRReport && CTRFile != null) {
                    emlContent += '----boundary_text_string\n';
                    emlContent +=
                        'Content-Type: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; name=CTR ' +
                        rowsSelected[i].invoiceNumber +
                        '.xlsx\n';
                    emlContent += 'Content-Transfer-Encoding: base64\n';
                    emlContent += 'Content-Disposition: attachment\n\n';
                    emlContent += CTRFile.data + '\n\n';
                }
            }

            this.setState({ isEmailingByContract: false });
        } else {
            this.setState({ isEmailingByContract: true });

            let downloadFile = await util.fetch
                .js(ApiRoutes.invoice.streamInvoices(invoices))
                .catch(this.handleSaveError);

            let emailSubject = customerName + ' invoices for ' + invoiceDate;
            let htmlDocument =
                '<html>Attached are ' +
                customerName +
                ' invoices and timesheets for ' +
                invoiceDate +
                '</html>';

            let contentType = 'application/zip';
            if (downloadFile.fileName.indexOf('.pdf') !== -1)
                contentType = 'application/pdf';

            emlContent = 'data:message/rfc822 eml;charset=utf-8,';
            emlContent += 'To: ' + emailTo + '\n';
            emlContent += 'Subject: ' + emailSubject + '\n';
            emlContent += 'X-Unsent: 1' + '\n';
            emlContent +=
                'Content-Type: multipart/mixed; boundary=--boundary_text_string' +
                '\n\n';

            emlContent += '----boundary_text_string' + '\n';
            emlContent += 'Content-Type: text/html' + '\n';
            emlContent += '' + '\n';
            emlContent += htmlDocument + '\n\n';

            emlContent += '----boundary_text_string' + '\n';
            emlContent +=
                'Content-Type: ' +
                contentType +
                '; name=' +
                downloadFile.fileName +
                '\n';
            emlContent += 'Content-Transfer-Encoding: base64' + '\n';
            emlContent += 'Content-Disposition: attachment' + '\n\n';
            emlContent += downloadFile.data + '\n';
            emlContent += '\n';

            this.setState({ isEmailingByContract: false });
        }

        let encodedUri = encodeURI(emlContent);
        let obj = document.createElement('a');
        let linkText = document.createTextNode('fileLink');
        obj.appendChild(linkText);
        obj.href = encodedUri;
        obj.id = 'fileLink';
        obj.download = 'invoices.eml';
        obj.style = 'display:none;';
        document.body.appendChild(obj);
        document.getElementById('fileLink').click();
        document.body.removeChild(obj);

        this.state.gridOptions.refresh();
    };

    onEmailRowClick = async (data) => {
        let invoiceFile = null;
        let timesheetsFile = null;
        let CTRFile = null;
        let ProofOf40File = null;

        this.setState({ isEmailing: true });
        if (!!data.id) {
            timesheetsFile = await util.fetch
                .js(ApiRoutes.invoice.streamInvoiceTimesheets(data.id))
                .catch(this.handleSaveError);
            invoiceFile = await util.fetch
                .js(ApiRoutes.invoice.streamInvoice(data.id))
                .catch(this.handleSaveError);

            if (data.useCTRReport) {
                CTRFile = await util.fetch
                    .js(ApiRoutes.invoice.streamCTRFile(data.id))
                    .catch(this.handleSaveError);  
            }

            if (data.useProofOf40 && data.hasOvertime) {
                ProofOf40File = await util.fetch
                    .js(ApiRoutes.invoice.streamProofOf40File(data.id))
                    .catch(this.handleSaveError);
            }
        }

        let timesheetsFileName = 'Timesheets ' + data.invoiceNumber;
        if (
            data.invoiceTimesheetUsesWO &&
            data.tcNumber != null &&
            data.tcNumber.length > 0
        ) {
            timesheetsFileName = data.tcNumber;
        }

        this.setState({ isEmailing: false });

        let emailSubject = 'Invoice ' + data.invoiceNumber;
        if (
            data.useCTRReport &&
            data.operationCenterCode != null &&
            data.operationCenterCode.length > 0
        ) {
            let dt = moment(data.invoiceDate).format('MMDDYYYY');
            let p = data.operationsCenter.indexOf(' - ');
            let opsCenter = data.operationsCenter.substring(p + 3);

            emailSubject =
                data.operationCenterCode +
                ' ' +
                'WD Wright Contracting ' +
                dt +
                ' ' +
                data.invoiceNumber +
                ' ' +
                opsCenter +
                ' - Flagging';
        }

        let emailTo =
            data.billingContactEmail == null ? '' : data.billingContactEmail;

        let htmlDocument =
            '<html>Attached are the invoice and timesheets for ' +
            data.invoiceNumber +
            '</html>';

        let emlContent = 'data:message/rfc822 eml;charset=utf-8,';
        emlContent += 'To: ' + emailTo + '\n';
        emlContent += 'Subject: ' + emailSubject + '\n';
        emlContent += 'X-Unsent: 1\n';
        emlContent +=
            'Content-Type: multipart/mixed; boundary=--boundary_text_string\n\n';

        emlContent += '----boundary_text_string\n';
        emlContent += 'Content-Type: text/html\n\n';
        emlContent += htmlDocument + '\n\n';

        emlContent += '----boundary_text_string\n';
        emlContent +=
            'Content-Type: application/pdf; name=Invoice ' +
            data.invoiceNumber +
            '.pdf\n';
        emlContent += 'Content-Transfer-Encoding: base64\n';
        emlContent += 'Content-Disposition: attachment\n\n';
        emlContent += invoiceFile.data + '\n\n';

        emlContent += '----boundary_text_string\n';
        emlContent +=
            'Content-Type: application/pdf; name=' +
            timesheetsFileName +
            '.pdf\n';
        emlContent += 'Content-Transfer-Encoding: base64\n';
        emlContent += 'Content-Disposition: attachment\n\n';
        emlContent += timesheetsFile.data + '\n\n';

        if (data.useCTRReport && CTRFile != null) {
            emlContent += '----boundary_text_string\n';
            emlContent +=
                'Content-Type: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; name=CTR Spreadsheet ' +
                data.invoiceNumber +
                '.xlsx\n';
            emlContent += 'Content-Transfer-Encoding: base64\n';
            emlContent += 'Content-Disposition: attachment\n\n';
            emlContent += CTRFile.data + '\n\n';
        }

        if (data.useProofOf40 && ProofOf40File != null) {
            emlContent += '----boundary_text_string\n';
            emlContent +=
                'Content-Type: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; name=ProofOf40.xlsx\n';
            emlContent += 'Content-Transfer-Encoding: base64\n';
            emlContent += 'Content-Disposition: attachment\n\n';
            emlContent += ProofOf40File.data + '\n\n';
        }

        let encodedUri = encodeURI(emlContent);
        let obj = document.createElement('a');
        let linkText = document.createTextNode('fileLink');
        obj.appendChild(linkText);
        obj.href = encodedUri;
        obj.id = 'fileLink';
        obj.download = 'invoice' + data.id + '.eml';
        obj.style = 'display:none;';
        document.body.appendChild(obj);
        document.getElementById('fileLink').click();
        document.body.removeChild(obj);

        this.state.gridOptions.refresh();
    };

    handleCheckboxChange = async (itemKey) => {
        const newClearExportItems = new Set(this.state.clearExportItems);

        if (!newClearExportItems.has(itemKey)) {
            newClearExportItems.add(itemKey);
        } else {
            newClearExportItems.delete(itemKey);
        }

        this.setState({ clearExportItems: newClearExportItems });
    };

    selectAllCheckboxes = async () => {
        const newClearExportItems = new Set(this.state.invoiceExportsClearing);
        this.setState({ clearExportItems: newClearExportItems });
    };

    onClearQuickBooksExports = async () => {
        let invoices = Array.from(this.state.clearExportItems).join(',');
        if (invoices.length == 0) return;

        this.setState({ isClearingInvoiceExports: true });

        let rowsSelected = this.state.rowsSelected;
        if (!!rowsSelected) {
            let model = invoices; //Invoices
            try {
                let response = await util.fetch.post(
                    ApiRoutes.invoice.clearExport(),
                    model,
                    //This is required for the error to be brought back clean.
                    //If you don't specify this, the error handling does not work
                    util.fetch.format.none
                );

                if (!!response.ok) {
                    toasty.success('Cleared Exports');
                } else {
                    if (response.status === 400) {
                        let serviceResponse = await response.json();
                        this.displaySaveErrors(serviceResponse);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [await response.text()],
                            },
                        };
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            } catch (ex) {
                toasty.error(ex);
                this.setState({ willClearInvoiceExports: false });
            } finally {
                this.setState({
                    willClearInvoiceExports: false,
                    isClearingInvoiceExports: false,
                    invoiceExportsClearing: [],
                });
                this.state.gridOptions.refresh();
            }
        }

        this.setState({ clearExportItems: new Set() });
    };

    onGetInvoicesToClear = async () => {
        this.setState({
            willClearInvoiceExports: true,
            clearExportItems: new Set(),
        });

        let rowsSelected = this.state.rowsSelected;
        if (!!rowsSelected) {
            let model = rowsSelected.map((x) => x.id)[0]; //Invoice Id
            try {
                let response = await util.fetch.get(
                    ApiRoutes.invoice.invoiceExportsToClear(model),
                    //This is required for the error to be brought back clean.
                    //If you don't specify this, the error handling does not work
                    util.fetch.format.none
                );

                if (!!response.ok) {
                    let list = await response.json();
                    this.setState({ invoiceExportsClearing: list });
                } else {
                    if (response.status === 400) {
                        let serviceResponse = await response.json();
                        this.displaySaveErrors(serviceResponse);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [await response.text()],
                            },
                        };
                        this.displaySaveErrors(serviceResponse);
                    }
                    this.setState({ willClearInvoiceExports: false });
                }
            } catch (ex) {
                toasty.error(ex);
                this.setState({ willClearInvoiceExports: false });
            }
        }
    };

    onExportToQuickbooks = async () => {
        this.setState({ isDownloading: true });

        let rowsSelected = this.state.rowsSelected;
        if (!!rowsSelected) {
            let model = rowsSelected.map((x) => x.id); //Invoice Id
            try {
                let response = await util.fetch.post(
                    ApiRoutes.invoice.exportToQuickbooks(),
                    model,
                    //This is required for the error to be brought back clean.
                    //If you don't specify this, the error handling does not work
                    util.fetch.format.none
                );

                if (!!response.ok) {
                    let filename = util.fileNameFromContentDisposition(
                        response.headers.get('content-disposition')
                    );
                    let blob = await response.blob();
                    const a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.target = '_blank';
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                } else {
                    if (response.status === 400) {
                        let serviceResponse = await response.json();
                        this.displaySaveErrors(serviceResponse);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [await response.text()],
                            },
                        };
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            } catch (ex) {
                toasty.error(ex);
            } finally {
                this.state.gridOptions.refresh();
                this.setState({ isDownloading: false });
            }
        }

        this.state.gridOptions.refresh();
        this.setState({ isDownloading: false });
    };

    onExportToNetSuite = async () => {
        this.setState({ isDownloading: true });
        this.onExportToQuickbooks();
        let rowsSelected = this.state.rowsSelected;
        if (!!rowsSelected) {
            let model = rowsSelected.map((x) => x.id); //Invoice Id
            try {
                let response = await util.fetch.post(
                    ApiRoutes.invoice.exportToNetSuite(),
                    model,
                    //This is required for the error to be brought back clean.
                    //If you don't specify this, the error handling does not work
                    util.fetch.format.none
                );

                if (!!response.ok) {
                    let filename = util.fileNameFromContentDisposition(
                        response.headers.get('content-disposition')
                    );
                    let blob = await response.blob();
                    const a = document.createElement('a');
                    a.href = URL.createObjectURL(blob);
                    a.target = '_blank';
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                } else {
                    if (response.status === 400) {
                        let serviceResponse = await response.json();
                        this.displaySaveErrors(serviceResponse);
                    } else {
                        let serviceResponse = {
                            title: 'Server Error',
                            errors: {
                                Exception: [await response.text()],
                            },
                        };
                        this.displaySaveErrors(serviceResponse);
                    }
                }
            } catch (ex) {
                toasty.error(ex);
            } finally {
                this.state.gridOptions.refresh();
                this.setState({ isDownloading: false });
            }
        }

        this.state.gridOptions.refresh();
        this.setState({ isDownloading: false });
    };

    onGetExportFile = async (id, type) => {
        this.setState({ isDownloading: true });

        try {
            let response = await util.fetch.get(
                ApiRoutes.invoice.getExportFile(id, type),
                util.fetch.format.none
            );

            if (!!response.ok) {
                let filename = util.fileNameFromContentDisposition(
                    response.headers.get('content-disposition')
                );
                let blob = await response.blob();
                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.target = '_blank';
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            } else {
                if (response.status === 400) {
                    let serviceResponse = await response.json();
                    this.displaySaveErrors(serviceResponse);
                } else {
                    let serviceResponse = {
                        title: 'Server Error',
                        errors: {
                            Exception: [await response.text()],
                        },
                    };
                    this.displaySaveErrors(serviceResponse);
                }
            }
        } catch (error) {
            toasty.error(error);
        }

        this.setState({ isDownloading: false });
    };

    onInvoiceIssuesSlideout = async (row) => {

        let invoice = await util.fetch.js(ApiRoutes.invoice.byId(row.id));

        var messages = await util.fetch.post(
            ApiRoutes.billingIssues.invoiceMessages(),
            row.id
        );

        this.setState({ invoiceIssueMessages: messages, timesheetNumbers: row.timesheetNumber, selectedInvoice: invoice, showInvoiceIssuesSlideout: true });
        this.invoiceIssuesSlideoutRef.current.open();
    };

    onRejectToInvoiceIssues = async (invoiceId, timesheetId, message, updateStatus) => {
        if (message == null || message.length == 0) {
            toasty.error('Please supply rejection notes');
            return;
        }

        var model = {
            invoiceId: invoiceId,
            timesheetId: timesheetId,
            notes: message,
            updateStatus: updateStatus
        };

        await util.fetch.post(
            ApiRoutes.billingIssues.invoiceReject(),
            model
        );

        toasty.success('Invoice rejected to Billing Issues');

        this.setState({
            showInvoiceIssuesSlideout: false,
        });

        this.state.gridOptions.refresh();
    }

    onAcceptInvoiceIssueReply = async (invoiceId, timesheetId, message) => {

        var model = {
            invoiceId: invoiceId,
            timesheetId: timesheetId,
            notes: message
        };

        await util.fetch.post(
            ApiRoutes.billingIssues.invoiceAccept(),
            model
        );

        toasty.success('Invoice reply accepted');

        this.setState({
            showInvoiceIssuesSlideout: false,
        });

        this.state.gridOptions.refresh();
    }

    render() {
        const {
            rowData,
            selectedRow,
            gridOptions,
            showRejectTimesheetModal,
            isRejectingTimesheet,
            selectedRowRejectionNotes,
            isExporting,
            showExportModal,
            isDownloading,
            isDownloadingByContract,
            isEmailingByContract,
            invoiceExportsClearing,
            willClearInvoiceExports,
            isClearingInvoiceExports,
            disableExport,
            disableClearExport,
            disableDownloadByContract,
            invoiceIssueMessages,
            scoreboardData,
            selectedInvoice,
            timesheetNumbers
        } = this.state;

        var counts = {
            new: 0,
            draft: 0,
            approved: 0,
            pendingReview: 0,
            invoiceIssue: 0
        };

        if (!!scoreboardData) {
            counts = scoreboardData;
        }

        let countNew =
            'mr-3 badge badge badge-lg ' +
            (counts.new === 0
                ? 'badge-success p-2'
                : 'badge-warning p-2');
        let countDraft =
            'mr-3 badge badge badge-lg ' +
            (counts.draft === 0
                ? 'badge-success p-2'
                : 'badge-warning p-2');
        let countApproved =
            'mr-3 badge badge badge-lg ' +
            (counts.approved === 0
                ? 'badge-success p-2'
                : 'badge-warning p-2');
        let countPendingReview =
            'mr-3 badge badge badge-lg ' +
            (counts.pendingReview === 0
                ? 'badge-success p-2'
                : 'badge-warning p-2');
        let countInvoiceIssue =
            'mr-3 badge badge badge-lg ' +
            (counts.invoiceIssue === 0
                ? 'badge-success p-2'
                : 'badge-warning p-2');

        let { errorResponse } = { ...this.state };

        //TODO: Eliminate inline styles.

        return (
            <CommonContext.Consumer>
                {(value) => {
                    const { tenantSettings } = (value ?? {}).tenant ?? {};

                    if (this.state.loading || !tenantSettings)
                        return (
                            <LinearProgress
                                variant="indeterminate"
                                color="secondary"
                            />
                        );

                    if (
                        !tenantSettings.billingEnabled ||
                        !tenantSettings.invoicingEnabled
                    )
                        return <NotAuthorizedPage />;

                    return (
                        <PageWrap>
                            <PageHeading>
                                <FontAwesomeIcon
                                    icon={faTasks}
                                    className="mr-2 text-muted"
                                />
                                <span>Invoice</span>
                                <span
                                    style={{
                                        float: 'right',
                                        position: 'relative',
                                        top: '-5px',
                                    }}
                                >
                                    <Button
                                        size="sm"
                                        color="success"
                                        style={{ marginRight: '10px' }}
                                        onClick={() =>
                                            this.onDownloadInvoices()
                                        }
                                        disabled={
                                            disableDownloadByContract ||
                                            isExporting
                                        }
                                    >
                                        <i
                                            className={`fa fa-download fa-md mr-2`}
                                        />
                                        Download Invoices&nbsp;
                                        {isDownloadingByContract && (
                                            <FontAwesomeIcon
                                                icon={faCircleNotch}
                                                className="fa-spin mr-2"
                                                size="sm"
                                            />
                                        )}
                                    </Button>
                                    <Button
                                        size="sm"
                                        color="success"
                                        style={{ marginRight: '10px' }}
                                        onClick={() =>
                                            this.onEmailButtonClick()
                                        }
                                        disabled={
                                            disableDownloadByContract ||
                                            isExporting
                                        }
                                    >
                                        <i
                                            className={`fa fa-envelope fa-md mr-2`}
                                        />
                                        Email Invoices&nbsp;
                                        {isEmailingByContract && (
                                            <FontAwesomeIcon
                                                icon={faCircleNotch}
                                                className="fa-spin mr-2"
                                                size="sm"
                                            />
                                        )}
                                    </Button>
                                    <Button
                                        size="sm"
                                        color="success"
                                        style={{ marginRight: '10px' }}
                                        onClick={() =>
                                            this.onExportToNetSuite()
                                        }
                                        disabled={
                                            disableExport ||
                                            isExporting
                                        }
                                    >
                                        Export Invoices
                                        {isDownloading && (
                                            <FontAwesomeIcon
                                                icon={faCircleNotch}
                                                className="fa-spin mr-2"
                                                size="sm"
                                            />
                                        )}
                                    </Button>
                                    <Button
                                        size="sm"
                                        color="danger"
                                        style={{ marginRight: '10px' }}
                                        onClick={() =>
                                            this.onGetInvoicesToClear()
                                        }
                                        disabled={
                                            disableClearExport ||
                                            isExporting
                                        }
                                    >
                                        Clear Invoice Export
                                        {isDownloading && (
                                            <FontAwesomeIcon
                                                icon={faCircleNotch}
                                                className="fa-spin mr-2"
                                                size="sm"
                                            />
                                        )}
                                    </Button>
                                </span>
                            </PageHeading>

                            <FlexCenterRow
                                className="pb-3 pt-2"
                                id="invoiceGenerationWorkflowStatus"
                            >
                                <span
                                    style={{ minWidth: '100px' }}
                                    className={countNew}
                                >
                                    New
                                    <span className="ml-2">{counts.new}</span>
                                </span>
                                <span
                                    style={{ minWidth: '100px' }}
                                    className={countDraft}
                                >
                                    Draft
                                    <span className="ml-2">{counts.draft}</span>
                                </span>
                                <span
                                    style={{ minWidth: '100px' }}
                                    className={countApproved}
                                >
                                    Approved
                                    <span className="ml-2">{counts.approved}</span>
                                </span>
                                <span
                                    style={{ minWidth: '100px' }}
                                    className={countInvoiceIssue}
                                >
                                    Rejected
                                    <span className="ml-2">{counts.invoiceIssue}</span>
                                </span>
                                <span
                                    style={{ minWidth: '100px' }}
                                    className={countPendingReview}
                                >
                                    Pending Review
                                    <span className="ml-2">{counts.pendingReview}</span>
                                </span>

                            </FlexCenterRow>

                            <DataGridToolbar
                                entity="Billing"
                                gridApi={this.state.gridApi}
                                dataSource={this.state.dataSource}
                                hideAdd={true}
                                hideExcelButton={true}
                                gridOptions={this.state.gridOptions}
                                serverExport={{
                                    apiPath: ApiRoutes.invoice.excelExport(),
                                    filePrefix: 'InvoiceSearch',
                                }}
                                serverExportDisabled={
                                    !!this.state.saving ||
                                    !!this.state.loading ||
                                    !!this.state.loadingData
                                }
                            />
                            {!!this.state.loadingReport && (
                                <CustomCircularProgress />
                            )}

                            {!this.state.loadingReport && (
                                <>
                                    <DataGrid
                                        domLayout={'normal'}
                                        rowData={rowData}
                                        gridOptions={gridOptions}
                                        gridStatus={this.state.gridStatus}
                                    />
                                </>
                            )}
                            <FlexRow>
                                <FormErrorResponseDisplay
                                    onClear={this.clearSaveErrors}
                                    response={errorResponse}
                                />
                            </FlexRow>
                            <InvoiceSlideout
                                ref={this.invoiceSlideoutRef}
                                show={this.state.invoiceSlideoutOpen}
                                toggleShow={(open) =>
                                    this.setState({ invoiceSlideoutOpen: open })
                                }
                                onClose={this.onInvoiceSlideoutClosed}
                            />
                            <Modal
                                isOpen={showRejectTimesheetModal}
                                toggle={this.toggleRejectTimesheetModal}
                            >
                                <ModalHeader
                                    toggle={this.toggleRejectTimesheetModal}
                                >
                                    Billing: Reject Timesheet
                                </ModalHeader>
                                <ModalBody>
                                    <FormText style={{ marginBottom: '20px' }}>
                                        This action will reject the timesheet
                                        from billing review and remove all
                                        charges from payroll review.
                                    </FormText>
                                    <FormGroup>
                                        <FormLabel
                                            required={true}
                                            text="Notes"
                                        ></FormLabel>
                                        <textarea
                                            id="selectedRowRejectionNotes"
                                            name="selectedRowRejectionNotes"
                                            className="form-control"
                                            defaultValue={
                                                selectedRowRejectionNotes ?? ''
                                            }
                                            onChange={this.onChange}
                                            required
                                            placeholder="Enter notes regarding the rejection."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                        <small className="text-danger">
                                            Notes are required.
                                        </small>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isRejectingTimesheet && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isRejectingTimesheet}
                                        onClick={this.rejectTimesheet}
                                    >
                                        Ok
                                    </Button>{' '}
                                </ModalFooter>
                            </Modal>
                            <Modal
                                isOpen={showExportModal}
                                toggle={() => this.toggleExportModal()}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleExportModal()}
                                >
                                    Export Billing
                                </ModalHeader>
                                <ModalBody>
                                    <p>
                                        Export will be generated based upon
                                        search criteria entered on the billing
                                        screen. If the result set is too large
                                        the export may timeout.
                                    </p>
                                    {/*<FormGroup>*/}
                                    {/*    <label>Dispatching</label>*/}
                                    {/*    <Select*/}
                                    {/*        placeholder={'Select Dispatch Location'}*/}
                                    {/*        id="selectedDispatchLocation"*/}
                                    {/*        name="selectedDispatchLocation"*/}
                                    {/*        styles={CompactSelectStyles}*/}
                                    {/*        isClearable={false}*/}
                                    {/*        className="react-select"*/}
                                    {/*        options={dispatchLocations}*/}
                                    {/*        value={(dispatchLocations ?? []).find(x => x.value === selectedDispatchLocation) ?? ''}*/}
                                    {/*        onChange={this.onSelectedDispatchLocationChanged}*/}
                                    {/*    />*/}
                                    {/*</FormGroup>*/}
                                    {/*<FormGroup>*/}
                                    {/*    <label>Week Of</label>*/}
                                    {/*    <input*/}
                                    {/*        id="weekOf"*/}
                                    {/*        name="selectedWeekOf"*/}
                                    {/*        className="form-control"*/}
                                    {/*        defaultValue={selectedWeekOf ?? ''}*/}
                                    {/*        onChange={this.onChange}*/}
                                    {/*        type="date"*/}
                                    {/*    />*/}
                                    {/*</FormGroup>*/}
                                </ModalBody>
                                <ModalFooter>
                                    {isExporting && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isExporting}
                                        onClick={() => this.onExportClick()}
                                    >
                                        Ok
                                    </Button>{' '}
                                    <Button
                                        color="secondary"
                                        onClick={() =>
                                            this.setState({
                                                showExportModal: false,
                                            })
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>

                            <Modal
                                isOpen={showRejectTimesheetModal}
                                toggle={this.toggleRejectTimesheetModal}
                            >
                                <ModalHeader
                                    toggle={this.toggleRejectTimesheetModal}
                                >
                                    Billing: Reject Timesheet
                                </ModalHeader>
                                <ModalBody>
                                    <FormText style={{ marginBottom: '20px' }}>
                                        This action will reject the timesheet
                                        from billing review and remove all
                                        charges from payroll review.
                                    </FormText>
                                    <FormGroup>
                                        <FormLabel
                                            required={true}
                                            text="Notes"
                                        ></FormLabel>
                                        <textarea
                                            id="selectedRowRejectionNotes"
                                            name="selectedRowRejectionNotes"
                                            className="form-control"
                                            defaultValue={
                                                selectedRowRejectionNotes ?? ''
                                            }
                                            onChange={this.onChange}
                                            required
                                            placeholder="Enter notes regarding the rejection."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                        <small className="text-danger">
                                            Notes are required.
                                        </small>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isRejectingTimesheet && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isRejectingTimesheet}
                                        onClick={this.rejectTimesheet}
                                    >
                                        Ok
                                    </Button>{' '}
                                </ModalFooter>
                            </Modal>
                            <Modal isOpen={this.state.isDownloading}>
                                <ModalHeader>Download Invoice</ModalHeader>
                                <ModalBody>
                                    <FontAwesomeIcon
                                        icon={faCircleNotch}
                                        className="fa-spin mr-2"
                                        size="sm"
                                    />
                                    &nbsp;Processing...
                                </ModalBody>
                            </Modal>
                            <Modal isOpen={this.state.isDownloadingTimesheets}>
                                <ModalHeader>Download Timesheets</ModalHeader>
                                <ModalBody>
                                    <FontAwesomeIcon
                                        icon={faCircleNotch}
                                        className="fa-spin mr-2"
                                        size="sm"
                                    />
                                    &nbsp;Processing...
                                </ModalBody>
                            </Modal>
                            <Modal isOpen={this.state.isDownloadingOver40}>
                                <ModalHeader>Download Proof of 40</ModalHeader>
                                <ModalBody>
                                    <FontAwesomeIcon
                                        icon={faCircleNotch}
                                        className="fa-spin mr-2"
                                        size="sm"
                                    />
                                    &nbsp;Processing...
                                </ModalBody>
                            </Modal>
                            <Modal isOpen={this.state.isDownloadingCTR}>
                                <ModalHeader>Download CTR File</ModalHeader>
                                <ModalBody>
                                    <FontAwesomeIcon
                                        icon={faCircleNotch}
                                        className="fa-spin mr-2"
                                        size="sm"
                                    />
                                    &nbsp;Processing...
                                </ModalBody>
                            </Modal>
                            <Modal isOpen={this.state.isEmailing}>
                                <ModalHeader>
                                    Generate Attachments for Email
                                </ModalHeader>
                                <ModalBody>
                                    <FontAwesomeIcon
                                        icon={faCircleNotch}
                                        className="fa-spin mr-2"
                                        size="sm"
                                    />
                                    &nbsp;Processing...
                                </ModalBody>
                            </Modal>
                            <Modal
                                isOpen={willClearInvoiceExports}
                                scrollable={false}
                            >
                                <ModalHeader>
                                    Clear NetSuite Export
                                </ModalHeader>
                                <ModalBody>
                                    {invoiceExportsClearing.length === 0 && (
                                        <p>
                                            {' '}
                                            This invoice is not exported or
                                            there are no exports that will be
                                            cleared
                                        </p>
                                    )}
                                    {invoiceExportsClearing.length > 0 && (
                                        <>
                                            <p>
                                                This will clear the NetSuite Export 
                                                for selected invoices:
                                            </p>
                                            <p>
                                                <button
                                                    onClick={() =>
                                                        this.selectAllCheckboxes()
                                                    }
                                                    className="site-button-small btn btn-primary btn-sm"
                                                >
                                                    Select All
                                                </button>
                                            </p>
                                        </>
                                    )}
                                    {invoiceExportsClearing.length > 0 && (
                                        <>
                                            <div
                                                style={{
                                                    maxHeight: '500px',
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                {invoiceExportsClearing
                                                    .sort()
                                                    .map((invn) => (
                                                        <FormGroup>
                                                            <FormCheckbox
                                                                id={
                                                                    'inv' + invn
                                                                }
                                                                name={
                                                                    'inv' + invn
                                                                }
                                                                labelText={invn}
                                                                checked={this.state.clearExportItems.has(
                                                                    invn
                                                                )}
                                                                onChange={() =>
                                                                    this.handleCheckboxChange(
                                                                        invn
                                                                    )
                                                                }
                                                            />
                                                        </FormGroup>
                                                    ))}
                                            </div>
                                        </>
                                    )}
                                </ModalBody>
                                <ModalFooter>
                                    <Button
                                        color="primary"
                                        disabled={isClearingInvoiceExports}
                                        onClick={this.onClearQuickBooksExports}
                                    >
                                        Ok
                                    </Button>{' '}
                                    <Button
                                        color="secondary"
                                        onClick={() =>
                                            this.setState({
                                                willClearInvoiceExports: false,
                                            })
                                        }
                                    >
                                        Cancel
                                    </Button>
                                    {isClearingInvoiceExports && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    {isClearingInvoiceExports && (
                                        <span>&nbsp;Processing...</span>
                                    )}
                                </ModalFooter>
                            </Modal>

                            <SlideForm
                                loading={false}
                                show={this.state.showInvoiceIssuesSlideout}
                                id="invoiceIssuesSlideout"
                                formIcon={faComments}
                                formTitle="Invoice Issues"
                                onClose={() => {
                                    this.setState({
                                        showInvoiceIssuesSlideout: false,
                                    });
                                }}
                            >
                                <InvoiceIssuesWidget
                                    ref={this.invoiceIssuesSlideoutRef}
                                    invoice={selectedInvoice}
                                    messages={invoiceIssueMessages}
                                    timesheetNumbers={timesheetNumbers}
                                    context={'rejecting'}
                                    onRejectCallback={(invoiceId, timesheetId, message, updateStatus) => {
                                        this.onRejectToInvoiceIssues(invoiceId, timesheetId, message, updateStatus);
                                    }}
                                    onAcceptCallback={(invoiceId, timesheetId, message) => {
                                        this.onAcceptInvoiceIssueReply(invoiceId, timesheetId, message);
                                    }}
                                    onCloseCallback={(id, message) => {
                                        this.setState({
                                            showInvoiceIssuesSlideout: false,
                                        });
                                    }}
                                >
                                </InvoiceIssuesWidget>
                            </SlideForm>
                        </PageWrap>
                    );
                }}
            </CommonContext.Consumer>
        );
    }
}

export default withRouter(InvoiceIndex);
