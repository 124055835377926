import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUserClock,
    faCircleNotch,
    faTrash,
    faEraser,
    faPrint,
} from '@fortawesome/free-solid-svg-icons';
import {
    FormGroup,
    Button,
    Badge,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
} from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    CollapseUnderlineHeader,
    FormLabel,
    onFieldChange,
    onReactSelectChanged,
    FlexStartRow,
    FlexCenterRow,
    toasty,
    FormBlocker,
    FormCheckbox,
} from '../common/forms/FormElements'; //, FlexColumnStart } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, ApplicationPermissions } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { TimesheetDetailsAdjustment } from '../timesheetManagement/TimesheetDetailsAdjustment';
import { Can } from '../Can';
import ValidatedSelect, {
    CompactSelectStyles,
} from '../common/forms/ValidatedSelect';
import NumericInput from '../common/forms/NumericInput';
import { TimesheetStatus } from './Timesheet';
import TimeEntry from '../common/forms/TimeEntry';
import { ChargeTypeUnits } from '../chargeType/ChargeType';
import * as moment from 'moment';
import { isEqual } from 'lodash';
import { CircularProgress } from '@material-ui/core';
import cls from 'classnames';
import { JobDetails } from '../job/Job';
import authService from '../api-authorization/AuthorizeService';
import { getTenantUserProfile } from '../common/TenantUserProfile';
import OrganizationContactForm from '../organization/OrganizationContactForm';
import { faSave } from '@fortawesome/fontawesome-free-regular';
import EmailRecipients from '../common/forms/EmailRecipients';
//import _ from 'lodash-es';
import { CountyCaptureType } from '../tenant/Tenant';

export default class TimesheetDetails extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.contactFormRef = React.createRef();

        let stateBase = Object.assign(
            {
                originalDetails: '',
                originalAdjustments: JSON.stringify([]),
                details: [],
                adjustments: [],
                jobDetailsOpen: true,
                timesheetDetailsOpen: true,
                reviewDetailsOpen: true,
                isPrinting: false,
                notesOpen: true,
                showRejectTimesheetModal: false,
                isRejectingTimesheet: false,
                rejectionNotes: '',
                jobAssignmentNotesOpen: true,
                cancellationNotesOpen: true,
                rejectionNotesInvalid: false,
                workOrderEditorOpen: false,
                displayNotesOpen: true,
                internalNotesOpen: true,
                isTimesheetApprover: false,
                currentUser: null,
                selectedContactIdType: null,
                selectedContact: null,
                newContact: null,
                emailRecipientsOpen: true,
                currentEmailAddress: null,
                showSigantureModal: false,
                selectedReviewSignature: null,
                contactFormIsReadOnly: false,
                timesheetInputsOpen: true,
                showJobCityMissingModal: false,
                isCancellingJob: false,
                showCancelJobModal: false,
                selectedJobCancellationNotes: '',
                timesheetInputs: [],
                usStates: [],
                usCounties: [],
                showRollBackApprovedModal: false,
                showRollBackExportedModal: false,
            },
            new BaseFormViewModel()
        );

        this.state = stateBase;
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    //#region METHODS

    componentDidMount = () => {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    };

    componentWillUnmount = () =>
        (this.setState = (state, callback) => {
            return;
        });

    async populateState() {
        const [tenantUserProfile, usStates, usCounties] = await Promise.all([
            getTenantUserProfile(),
            util.fetch.js(ApiRoutes.USStates()),
            util.fetch.js(ApiRoutes.USCounties()),
        ]);

        const { currentUser, userPermissions } = { ...tenantUserProfile };
        const isApprover =
            userPermissions.includes(ApplicationPermissions.timesheet_accept) ||
            userPermissions.includes(ApplicationPermissions.timesheet_reject);

        this.setState({
            isTimesheetApprover: isApprover,
            currentUser: currentUser,
            usStates,
            usCounties,
        });
    }

    onChange = onFieldChange;
    onSelectChange = onReactSelectChanged;
    handleSaveError = (err) => handleFormSaveError(this, err);

    open = async (timesheetId) => {
        //reset adjustments and validation on the way back in.
        this.props.toggleShow(true);
        this.context.setFormOpened(true);
        await this.setState({ id: timesheetId });
        this.getTimesheet();
    };

    getTimesheet = async () => {
        const { id } = { ...this.state };
        await this.setState({
            loading: true,
            adjustments: [],
            formValidated: false,
            rejectionNotes: '',
            showRejectTimesheetModal: false,
        });
        await this.populateState();

        let payroll = !!this.props.isPayroll && this.props.isPayroll == true;
        let billing = !!this.props.isBilling && this.props.isBilling == true;
        let url = ApiRoutes.timesheet.slideout(id, payroll, billing);
        let tsInputs = [];

        let [details] = await Promise.all([util.fetch.js(url)]);

        let [chargeTypes, staff, equipment, customerContacts] =
            await Promise.all([
                util.fetch.js(
                    ApiRoutes.chargeType.byContract(details.contractId)
                ),
                util.fetch.js(
                    ApiRoutes.timesheet.slideoutStaff(details.dispatchCompanyId)
                ),
                util.fetch.js(
                    ApiRoutes.timesheet.slideoutEquipment(
                        details.dispatchCompanyId
                    )
                ),
                !!details.isSelfDispatching
                    ? []
                    : util.fetch.js(
                          ApiRoutes.company.contacts(details.customerCompanyId)
                      ),
            ]);

        // Duplicate chargeTypes where the first chargeTypeConfiguration has canBreak set to true, and append to the list
        let duplicatedChargeTypes = chargeTypes.reduce((acc, chargeType) => { 
            acc.push(chargeType);
        
            if (chargeType.chargeTypeConfigurations && chargeType.chargeTypeConfigurations[0] && chargeType.chargeTypeConfigurations[0].canBreak) {
                let duplicatedChargeType = {
                    ...chargeType,
                    description: "Lunch Break (" + chargeType.description + ")", 
                    id: chargeType.id + "lunch_break"
                };
                // Append the duplicated chargeType
                acc.push(duplicatedChargeType);
            }

            return acc;
        }, []);

        chargeTypes = duplicatedChargeTypes;

        const existingForemanOrRequestorIds =
            this.getExistingForemanOrRequestorIds(details);

        const customerContactsOptions = this.mapContacts(
            customerContacts,
            existingForemanOrRequestorIds
        );

        this.resetForm();

        //apply filter on details
        if (!!this.props.onlyBillable && this.props.onlyBillable === true) {
            details.timesheetDetails = details.timesheetDetails.filter(
                (x) => x.isBillable == true
            );
        }

        //If self dispatching//non-flagging, only a single emp should be selectable.
        if (!!details.isSelfDispatching) {
            const empId = [
                ...new Set(
                    details.timesheetDetails
                        .filter((td) => !!td.employeeId)
                        .map((td) => td.employeeId)
                ),
            ];
            staff = staff.filter((s) => empId.includes(s.id));
        }

        if (!!details?.timesheetInputQuestions.length) {
            details.timesheetInputQuestions.forEach((inQ, inQx) => {
                let answers = details.timesheetInputAnswers.filter(
                    (ia) => ia.collator.questionId == inQ.questionId
                );

                tsInputs.push({
                    question: inQ.questionName,
                    answers: answers.map((ans, anx) => {
                        let details = '';

                        //Check if this is a chargetype question
                        if (!!ans.chargeTypeName) {
                            details =
                                ans.chargeTypeName +
                                ' - from ' +
                                ans.timesheetStart +
                                ' to ' +
                                ans.timesheetEnd;
                        }

                        return {
                            employee: ans.employeeName,
                            details: details,
                            value: ans.answer,
                        };
                    }),
                });
            });
        }

        await this.setState({
            originalDetails: JSON.stringify(details),
            details: details,
            loading: false,
            chargeTypes: chargeTypes,
            staff: staff,
            equipment: equipment,
            customerContactsOptions: customerContactsOptions,
            timesheetInputs: tsInputs,
        });
    };

    onClose = () => {
        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    };

    onPrint = () => {
        //can print from TS regular, billing or payroll specific methods
        const { isBilling, isPayroll } = { ...this.props };
        let route = ApiRoutes.report.timesheet(this.state.id);
        if (isBilling) route = ApiRoutes.report.timesheetBilling(this.state.id);
        else if (isPayroll)
            route = ApiRoutes.report.timesheetPayroll(this.state.id);

        window.open(route, '_self');
    };

    resetForm = () => {
        this.setState({
            formValidated: false,
        });
    };

    onAdjustmentTimeEntryChanged = (i, ev) => {
        let { adjustments } = { ...this.state },
            val = ev.target.value,
            name = ev.target.name;
        //We display the options in 12-hour format, but the db has 24.
        let timeValue = !!ev.target.value
            ? moment(val, ['h:mm A']).format('HH:mm')
            : '';
        adjustments[i][name] = timeValue;
        this.setState({ adjustments });
        this.setAdjustmentTimeQuantity(i);
    };

    setAdjustmentTimeQuantity = (i) => {
        let { details, adjustments } = { ...this.state };
        let adj = new TimesheetDetailsAdjustment();
        adj = adjustments[i];

        //Calculate the duration between Start and End.  Factor in if this enters into the next day.
        //Use the job start date/time as opposed to moment defaulting to the current day when getting duration.
        if (!!adj.start && !!adj.end) {
            const time_format = 'HH:mm';
            const date_format = 'YYYY-MM-DD';
            const mStartDate = moment(details.jobStartTimeRaw);
            let mEndDate = moment(details.jobStartTimeRaw);

            let mStartTime = moment(adj.start, time_format),
                mEndTime = moment(adj.end, time_format);
            if (mEndTime.isBefore(mStartTime)) {
                mEndTime.add(1, 'day');
                mEndDate.add(1, 'day');
            }

            const dur = moment.duration(mEndTime.diff(mStartTime));

            adj.startDate = moment(
                `${mStartDate.format(date_format)} ${mStartTime.format(
                    time_format
                )}`
            ).valueOf();
            adj.endDate = moment(
                `${mEndDate.format(date_format)} ${mEndTime.format(
                    time_format
                )}`
            ).valueOf();
            adj.quantity = dur.asHours().toFixed(2);

            adjustments[i] = adj;

            this.setState({ adjustments });
        }
    };

    onSubmit = async () => {
        //await this.setState({ saving: true });
        await this.setState({ saving: true });
        let { adjustments, details } = { ...this.state };

        //clean notes fields from user input before chucking them at the API.
        let notesDisplay = !!details.notesDisplay
            ? util.string.cleanText(details.notesDisplay)
            : null;
        let notesInternal = !!details.notesInternal
            ? util.string.cleanText(details.notesInternal)
            : null;
        let workOrderNum = !!details.workOrderNumber
            ? util.string.cleanText(details.workOrderNumber)
            : null;
        let jobCity = !!details.jobCity
            ? util.string.cleanText(details.jobCity)
            : null;
        let jobLoc = !!details.jobLocation
            ? util.string.cleanText(details.jobLocation)
            : null;
        let meetingLoc = !!details.meetingLocation
            ? util.string.cleanText(details.meetingLocation)
            : null;
        let emailAddresses = details.emailAddresses ?? null;

        //let mtgLoc = util.string.cleanText(details.meetingLocation);

        //Per CW, allow saving past the point of approval.
        //Only editable fields post approval should be notes and print checkboxes.
        //if (!!(details.timesheetStatusId != TimesheetStatus.Approved)) {

        //Any posted charges the user wants struck
        let removals = details.timesheetDetails
            .filter((x) => !!x.removed)
            .map((x) => x.id);
        //Any strikes the user undoes
        let includes = details.timesheetDetails
            .filter((x) => !x.removed)
            .map((x) => x.id);

        //clean up the vm objects before sending.
        let adjData = [];
        
        adjustments.forEach(x => {
            const adjustment = {
                timesheetId: details.timesheetId,
                id: !!x.adjustmentType ? null : x.id,
                start: !!x.startDate
                    ? moment(x.startDate).toDate().toISOString()
                    : null,
                end: !!x.endDate ? moment(x.endDate).toDate().toISOString() : null,
                resourceid: !!x.resourceId ? x.resourceId : 0,
                chargeTypeId: x.chargeTypeId,
                quantity: parseFloat(x.quantity),
                notes: x.notes,
            };

            if (x.resources.length) {
                x.resources.forEach(resource => {
                    if (resource) {
                        adjData.push({
                            ...adjustment,
                            resourceid: resource.id,
                        });
                    }
                });
            } else {
                adjData.push(adjustment);
            }
        });

        let updatedAdjustments = adjData.map(adjustment => {
            let chargeTypeIdStr = String(adjustment.chargeTypeId);
            if (chargeTypeIdStr.includes("lunch_break")) {
                // Remove the "lunch_break" string from the id
                adjustment.chargeTypeId = adjustment.chargeTypeId.replace("lunch_break", "");
                adjustment.isLunchBreak = true;
            }
            return adjustment;
        });

        let data = {
            timesheetId: details.timesheetId,
            adjustments: updatedAdjustments,
            removals: removals,
            includes: includes,
            notesDisplay: notesDisplay,
            notesInternal: notesInternal,
            workOrderNumber: workOrderNum,
            jobCity: jobCity,
            foremanId: details.foremanId,
            requestorId: details.requestorId,
            jobLocation: jobLoc,
            meetingLocation: meetingLoc,
            doNotPrintNotesDisplay: details.doNotPrintNotesDisplay,
            doNotPrintFieldNotes: details.doNotPrintFieldNotes,
            emailAddresses: emailAddresses,
            usCountyId: details.usCountyId,
        };
        let url = ApiRoutes.timesheetManagement.modify(details.timesheetId);

        let response = await util.fetch
            .andGetResponse(
                util.fetch.types.post,
                url,
                data,
                'Failed to Update Timesheet'
            )
            .catch(this.handleSaveError);

        if (response) {
            toasty.success('Timesheet Saved');
            this.open(response);
        }
        this.setState({ saving: false });
        //}
    };

    removeCharge = (resources) => {
        const timesheetDetails = resources.reduce((results, resource) => {
            const index = results.findIndex(x => x.id === resource.id);

            results[index] = {
                ...results[index],
                removed: !results[index].removed,
                show: !results[index].isAdjustment,
            };

            return results;
        }, this.state.details.timesheetDetails);

        this.setState({ details: { ...this.state.details, timesheetDetails } });
    };

    acceptTimesheet = async () => {
        const tenantSettings =
            ((this.context ?? {}).tenant ?? {}).tenantSettings ?? {};

        if (
            !!tenantSettings.timesheetUseJobCity &&
            !this.state.details.isSelfDispatching
        ) {
            let jobCity = this.state.details.jobCity;
            if (jobCity == null || jobCity.length == 0) {
                this.setState({ showJobCityMissingModal: true });

                if (document.getElementById('jobCity')) {
                    document.getElementById('jobCity').focus();
                }

                return;
            }
        }

        if (!!this.state.saving) return false;
        else await this.setState({ saving: true });

        let timesheetId = this.state.details.timesheetId;
        try {
            await util.fetch.put(
                ApiRoutes.timesheetManagement.accept(timesheetId)
            );
            toasty.success('Timesheet Approved');
            this.open(timesheetId);
        } catch (err) {
            toasty.error(`Error Approving Timesheet`);
        } finally {
            this.setState({ saving: false });
        }
    };

    rollBackTimesheet = async () => {
        await this.setState({ saving: true });
        const { details } = this.state;
        const timesheetId = details.timesheetId;

        try {
            const response = await util.fetch.andGetResponse(
                util.fetch.types.post,
                ApiRoutes.timesheet.rollBackApproved(timesheetId),
                null,
                'Error Resetting Timesheet'
            );

            if (response) {
                toasty.success('Timesheet Reset to Submitted');
                this.open(timesheetId);
            }
        } finally {
            this.setState({ saving: false, showRollBackApprovedModal: false });
        }
    };

    rollBackExportedTimesheet = async () => {
        await this.setState({ saving: true });
        const { details } = this.state;
        const timesheetId = details.timesheetId;

        try {
            const response = await util.fetch.andGetResponse(
                util.fetch.types.post,
                ApiRoutes.timesheet.rollBackExported(timesheetId),
                null,
                'Error Resetting Timesheet'
            );

            if (response) {
                toasty.success('Timesheet Reset to Submitted');
                this.open(timesheetId);
            }
        } finally {
            this.setState({ saving: false, showRollBackExportedModal: false });
        }
    };

    getRejectionNote = () => this.setState({ showRejectTimesheetModal: true });
    getDeletionNote = () => this.setState({ showDeleteTimesheetModal: true });

    rejectTimesheet = async () => {
        let success = true;
        await this.setState({ rejectionNotesInvalid: false });

        const { details, rejectionNotes } = { ...this.state };

        //Check required rejection note.
        if (!rejectionNotes) {
            this.setState({ rejectionNotesInvalid: true });
            return false;
        }

        //Prevent spam clicks
        if (!!this.state.saving) return false;
        else await this.setState({ saving: true });

        //Submit the timesheet if we have the note filled out.
        let timesheetId = details.timesheetId;
        let data = {
            timesheetId: timesheetId,
            note: rejectionNotes,
        };

        try {
            let response = await util.fetch.post(
                ApiRoutes.timesheetManagement.reject(),
                data,
                util.fetch.format.none
            );
            if (response.redirected) window.location.href = response.url;
            else if (response.ok) success = response.ok;
        } catch (ex) {
            success = false;
        } finally {
            this.setState({ saving: false });
        }

        if (!!success) {
            toasty.success('Timesheet rejected.');
            //Reload the window.
            await this.setState({
                showRejectTimesheetModal: false,
                selectedRowRejectionNotes: '',
            });
            this.getTimesheet();
        } else {
            toasty.error(
                `There were errors when rejecting the timesheet. Please try your submission again or contact support for assistance.`
            );
        }
    };

    deleteTimesheet = async () => {
        let success = true;
        await this.setState({ deletionNotesInvalid: false });

        const { details, deletionNotes } = { ...this.state };

        //Check required rejection note.
        if (!deletionNotes) {
            this.setState({ deletionNotesInvalid: true });
            return false;
        }

        //Prevent spam clicks
        if (!!this.state.saving) return false;
        else await this.setState({ saving: true });

        //Submit the timesheet if we have the note filled out.
        let timesheetId = details.timesheetId;
        let data = {
            timesheetId: timesheetId,
            note: deletionNotes,
        };

        let error = '';
        try {
            await util.fetch.andGetResponse(
                util.fetch.types.post,
                ApiRoutes.timesheetManagement.softDelete(),
                data,
                'Error Soft Deleting Timesheet'
            );
        } catch (ex) {
            error = ex;
            success = false;
        } finally {
            await this.setState({
                showDeleteTimesheetModal: false,
                deletionNotes: '',
                saving: false,
            });
        }

        if (success) {
            toasty.success('Timesheet deleted.');
        } else {
            toasty.error('Error Soft Deleting Timesheet: ' + error);
        }

        this.onClose();
    };

    onJobCityChanged = (event) => {
        const value = event.value;
        const { details } = { ...this.state };
        details.jobCity = value;
        this.setState({ details: { ...details } });
    };

    onWorkOrderChanged = (event) => {
        const value = event.target.value;
        const { details } = { ...this.state };
        details.workOrderNumber = value;
        this.setState({ details: { ...details } });
    };

    onRefresh = (event) => {
        this.open(this.state.details.timesheetId);
    };

    onEmergencyClicked = () => {};

    //#endregion

    getExistingForemanOrRequestorIds = (details) => {
        if (!details) {
            return [];
        }

        return Array.from(
            new Set([
                details.requestorId,
                details.initialRequestorId,
                details.foremanId,
                details.initialForemanId,
            ])
        );
    };

    //#region CONTACTS EDITING
    mapContacts = (customerContacts, existingForemanOrRequestorIds) => {
        return [
            ...(customerContacts ?? [])
                .filter(
                    (c) =>
                        c.isActive ||
                        (existingForemanOrRequestorIds || []).includes(c.id)
                )
                .map((x) => {
                    return { label: x.contactNameAndPhone, value: x.id };
                }),
        ];
    };

    refreshContacts = async () => {
        const { details } = { ...this.state };
        let customerContacts = await util.fetch.js(
            ApiRoutes.company.contacts(details.customerCompanyId)
        );

        const existingForemanOrRequestorIds =
            this.getExistingForemanOrRequestorIds(details);

        let options = this.mapContacts(
            customerContacts,
            existingForemanOrRequestorIds
        );
        this.setState({ customerContactsOptions: options });
    };

    onContactFormOpened = () => this.setState({ saving: true });
    onContactFormClosed = () => this.setState({ saving: false });
    onContactSavedComplete = async (e) => {
        const { details } = { ...this.state };
        let newContactDetails = { ...e };
        await this.refreshContacts();
        //select new contact in the dropdown. (details.requestorId or details.foremanId)
        details[newContactDetails.idType] = newContactDetails.id;
        this.setState({ details: details });
    };

    onEditContact = async (idType) => {
        const { details } = { ...this.state };
        const id =
            idType === 'foremanId' ? details.foremanId : details.requestorId;
        const selectedContact = await util.fetch.js(
            ApiRoutes.locationContact.byId(id)
        );
        this.contactFormRef.current.resetForm();
        this.setState({
            selectedContact: { ...selectedContact },
            showContactsForm: true,
            selectedContactIdType: idType,
        });
    };

    onOrganizationContactSaved = async () => {
        await this.setState({ contactFormIsReadOnly: true });

        const { selectedContact, selectedContactIdType } = { ...this.state };
        const isNew = parseInt(selectedContact.id ?? 0) <= 0;
        const result = await util.fetch.andGetResponse(
            !!isNew ? util.fetch.types.post : util.fetch.types.put,
            !!isNew
                ? ApiRoutes.locationContact.create()
                : ApiRoutes.locationContact.update(selectedContact.id),
            { ...selectedContact },
            'Error Saving Contact'
        );

        if (!!result) {
            toasty.success('Contact saved.');
            let idType = selectedContactIdType;
            this.setState({
                showContactsForm: false,
                saving: false,
                selectedContact: null,
                newContact: null,
                selectedContactIdType: null,
            });
            this.onContactSavedComplete({ id: result.id, idType: idType });
        } else {
            toasty.error('Server Error when saving Contact.');
        }

        await this.setState({ contactFormIsReadOnly: false });
    };

    onForemanChanged = (e) => {
        let { details } = { ...this.state };
        details.foremanId = e.value;
        this.setState({ details: details });
    };

    onRequestorChanged = (e) => {
        let { details } = { ...this.state };
        details.requestorId = e.value;
        this.setState({ details: details });
    };

    onJobLocationChanged = (e) => {
        let { details } = { ...this.state };
        details.jobLocation = e.target.value;
        this.setState({ details: details });
    };

    onMeetingLocationChanged = (e) => {
        let { details } = { ...this.state };
        details.meetingLocation = e.target.value;
        this.setState({ details: details });
    };

    getCountyName = () => {
        const { usCounties, details } = this.state;

        if (!details || !details.jobLocationCountyId) {
            return '';
        }

        const usCounty = usCounties.find(
            (c) => c.id == details.jobLocationCountyId
        );

        return Boolean(usCounty) ? ` (Original County: ${usCounty.name})` : '';
    };

    useCountyCapture = (tenantSettings) => {
        return (
            tenantSettings.captureCounty == CountyCaptureType.AllTimesheets ||
            tenantSettings.captureCounty ==
                CountyCaptureType.PrevailingWageTimesheets
        );
    };

    groupTimesheetDetails = () => {
        const { details } = this.state;
        const { timesheetDetails } = details;

        let i = 0;
        let totalAFADs = 0;
        const len = (timesheetDetails ?? []).length;

        const results = [];

        for(; i < len; i += 1) {
            const detail = timesheetDetails[i];
            let description = detail.resourceName;

            const resources = [detail];

            if (detail.isAFAD) {
                totalAFADs += 1;

                if (detail.numAFADsInSet > 1) {
                    // Group everything under the first item in the set and discard all other items in the set
                    const isFirstInSet = totalAFADs % detail.numAFADsInSet == 1;

                    if (!isFirstInSet) {
                        continue;
                    }

                    let j = 1;
                                
                    for(; j < detail.numAFADsInSet; j += 1) {
                        var next = timesheetDetails[i + j];

                        if (!next?.isAFAD) {
                            break;
                        }

                        description = `${description}, ${next.resourceName}`;
                        resources.push(next);
                    }
                }
            }

            results.push({ ...detail, resources, resourceName: description });
        }

        return results;
    };

    //#endregion

    render() {
        let {
            originalDetails,
            originalAdjustments,
            details,
            adjustments,
            formValidated,
            validationMessage,
            jobDetailsOpen,
            loading,
            timesheetDetailsOpen,
            reviewDetailsOpen,
            isPrinting,
            saving,
            staff,
            equipment,
            chargeTypes,
            showRejectTimesheetModal,
            isRejectingTimesheet,
            rejectionNotes,
            rejectionNotesInvalid,
            isDeletingTimesheet,
            deletionNotes,
            deletionNotesInvalid,
            showDeleteTimesheetModal,
            isTimesheetApprover,
            customerContactsOptions,
            selectedContact,
            showContactsForm,
            emailRecipientsOpen,
            currentEmailAddress,
            showSignatureModal,
            selectedReviewSignature,
            contactFormIsReadOnly,
            showJobCityMissingModal,
            usCounties,
            showRollBackApprovedModal,
            showRollBackExportedModal,
            timesheetInputs,
        } = this.state;

        const { isBilling, isPayroll, isTimesheet, isTimesheetForm, showNav } =
            { ...this.props };

        const tenantSettings =
            ((this.context ?? {}).tenant ?? {}).tenantSettings ?? {};
        const s_Details = JSON.stringify(details);
        const s_Adjustments = JSON.stringify(adjustments);
        const timesheetApproved = isBilling
            ? false
            : details?.timesheetStatusId === TimesheetStatus.Approved;

        const timesheetExported =
            details?.timesheetStatusId === TimesheetStatus.Exported;

        const countyCaptureEnabled =
            Boolean(details.jobLocationCountyId) &&
            this.useCountyCapture(tenantSettings);

        const timesheetStatusRollbackAllowed =
            tenantSettings.allowTimesheetStatusRollbacks;

        const activeCountyId =
            details.usCountyId ?? details.jobLocationCountyId;

        const showConfirmationPrompt =
            !loading &&
            !saving &&
            (!isEqual(originalDetails, s_Details) ||
                !isEqual(originalAdjustments, s_Adjustments));

        let jobDetailsHeader = !!details?.isSelfDispatching
            ? 'Work and Personnel Details'
            : 'Job Details';

        if (
            tenantSettings.showContractDescriptionInTimesheetView &&
            !details.isSelfDispatching &&
            details.jobName
        ) {
            jobDetailsHeader = `Job Details - ${details.jobName}`;
        }

        return (
            <>
                <SlideForm
                    size="col-xl-12 col-md-12 col-xs-12"
                    loading={loading || !tenantSettings}
                    show={this.props.show}
                    id={'timesheetForm'}
                    formIcon={faUserClock}
                    formTitle="Timesheet Details"
                    ref={this.formRef}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={formValidated}
                    isValid={(value) => {
                        if (!value) {
                            toasty.error(
                                'Validation Failed. Please review and complete all required data'
                            );
                        }
                    }}
                    className="pb-2 w-100"
                    hideToolbar={true}
                    onSubmit={this.onSubmit}
                    onClose={this.onClose}
                    errors={this.state.errors}
                    onClearErrors={this.onClearErrors}
                    validationMessage={validationMessage}
                    readOnly={!!saving}
                    showNav={showNav}
                    onNavBack={this.props.onNavBack}
                    onNavForward={this.props.onNavForward}
                    saveButtonText={'Save Timesheet'}
                >
                    <FormBlocker showProgress={true} show={saving} />
                    <FormBlocker showProgress={false} show={showContactsForm} />

                    <CollapseUnderlineHeader
                        headerText={jobDetailsHeader}
                        isOpen={jobDetailsOpen}
                        toggleCollapse={() =>
                            this.setState({ jobDetailsOpen: !jobDetailsOpen })
                        }
                    >
                        <JobDetails
                            isSelfDispatching={details.isSelfDispatching}
                            isNonFlagging={details.isNonFlagging}
                            customerName={details?.customerName}
                            subcontractorName={details?.subcontractorName}
                            customerContactsOptions={customerContactsOptions}
                            enableForemanEditing={!timesheetApproved}
                            foremanId={details?.foremanId}
                            initialForemanId={details?.initialForemanId}
                            foremanName={details?.foremanName}
                            foremanPhone={details?.foremanPhone}
                            foremanChanged={details?.foremanChanged} //when there are system log changes
                            foremanChanges={details?.foremanChanges}
                            onEditForeman={() =>
                                this.setState({ showContactsForm: true })
                            }
                            onForemanChanged={this.onForemanChanged}
                            enableRequestorEditing={!timesheetApproved}
                            requestorId={details?.requestorId}
                            initialRequestorId={details?.initialRequestorId}
                            requestorName={details?.requestorName}
                            requestorPhone={details?.requestorPhone}
                            requestorChanged={details?.requestorChanged}
                            requestorChanges={details?.requestorChanges}
                            onEditRequestor={() =>
                                this.setState({ showContactsForm: true })
                            }
                            onRequestorChanged={this.onRequestorChanged}
                            onEditContact={this.onEditContact}
                            onContactSavedComplete={this.onContactSavedComplete}
                            onOrganizationContactSaved={
                                this.onOrganizationContactSaved
                            }
                            jobNotes={details?.jobNotes}
                            jobNumber={details?.jobNumber}
                            jobCity={details?.jobCity}
                            jobStartTime={details?.jobStartTime}
                            jobStartTimeRaw={details?.jobStartTimeRaw}
                            lastRejectionNote={details?.lastRejectionNote}
                            onWorkOrderEditClicked={
                                this.onWorkOrderNumberEditClicked
                            }
                            timesheetNumber={details?.timesheetNumber}
                            timesheetStatus={details?.timesheetStatus}
                            timesheetStatusId={details?.timesheetStatusId}
                            statusRejectionNote={details?.statusRejectionNote}
                            workOrderNumber={details?.workOrderNumber}
                            initialWorkOrderNumber={
                                details?.initialWorkOrderNumber
                            }
                            workOrderNumberChanged={
                                details?.workOrderNumberChanged
                            }
                            workOrderNumberChanges={
                                details?.workOrderNumberChanges
                            }
                            disabledProperties={details?.disabledProperies}
                            timesheetId={details?.timesheetId}
                            jobId={details?.jobId}
                            enableWorkOrderNumberEditing={true} //{!timesheetApproved}
                            workOrderType={details?.contractWorkOrderType}
                            onWorkOrderChanged={this.onWorkOrderChanged}
                            onJobCityChanged={this.onJobCityChanged}
                            onRefresh={this.onRefresh}
                            permissions={this.context?.permissions}
                            isAdmin={this.context?.user?.isAdmin}
                            isEmergency={details?.isEmergency}
                            emergencyEmployee={details?.emergencyEmployee}
                            billingEmergencyEmployee={
                                details?.billingEmergencyEmployee
                            }
                            isBillingEmergency={details?.isBillingEmergency}
                            payrollEmergencyEmployee={
                                details?.payrollEmergencyEmployee
                            }
                            isPayrollEmergency={details?.isPayrollEmergency}
                            tenantSettings={tenantSettings}
                            isTimesheetForm={isTimesheetForm}
                            isTimesheet={isTimesheet}
                            isBilling={isBilling}
                            isPayroll={isPayroll}
                            billingStatusId={details?.billingStatusId}
                            payrollStatusId={details?.payrollStatusId}
                            canEmergency={details?.canEmergency}
                            isClientCancelled={details?.isClientCancelled}
                            jobName={details?.jobName}
                        />
                        {!details?.isSelfDispatching &&
                            this.renderLocationDetails(
                                details,
                                timesheetApproved,
                                countyCaptureEnabled,
                                usCounties,
                                activeCountyId
                            )}
                    </CollapseUnderlineHeader>

                    <CollapseUnderlineHeader
                        className="timesheet-details-collapse"
                        headerText="Timesheet Details"
                        isOpen={timesheetDetailsOpen}
                        toggleCollapse={() =>
                            this.setState({
                                timesheetDetailsOpen: !timesheetDetailsOpen,
                            })
                        }
                    >
                        <div className="details table-responsive">
                            <table className="table table-sm table-bordered table-striped timesheet-details-table">
                                <thead className="text-muted">
                                    <tr>
                                        {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                            <th className="isadjusted"></th>
                                        )}
                                        <th className="detailscharge">
                                            Charge
                                        </th>
                                        <th className="detailsname">Name</th>
                                        <th className="detailsnote">Notes</th>
                                        <th className="detailsstart">Start</th>
                                        <th className="detailsend">End</th>
                                        <th className="detailsqty">Quantity</th>
                                        {!!isBilling === true && (
                                            <th className="detailsqty">
                                                Bill Qty
                                            </th>
                                        )}
                                        <th className="detailsunits">Units</th>
                                        <th className="detailsbillable">
                                            Billable
                                        </th>
                                        <th className="detailsbreak">Break</th>
                                        {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                            <Can do="accept" on="timesheet">
                                                <th className="detailsdelete"></th>
                                            </Can>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {!(details.timesheetDetails ?? [])
                                        .length ? (
                                        <tr>
                                            <td
                                                colSpan="5"
                                                className="text-center"
                                            >
                                                No details found.
                                            </td>
                                        </tr>
                                    ) : (
                                        this.groupTimesheetDetails()
                                            .filter((x) => x.show)
                                            .map((detail, detail_index) => {
                                                let chargeExceedsThreshold =
                                                    detail.unitsName ===
                                                        'Hours' &&
                                                    !detail.isBreak &&
                                                    detail.hoursExceededThreshold >
                                                        0 &&
                                                    parseFloat(
                                                        detail.quantity
                                                    ) >=
                                                        detail.hoursExceededThreshold;

                                                let breakExceedsThreshold =
                                                    detail.unitsName ===
                                                        'Hours' &&
                                                    detail.isBreak &&
                                                    detail.breakHoursExceededThreshold >
                                                        0 &&
                                                    Math.abs(
                                                        parseFloat(
                                                            detail.quantity
                                                        )
                                                    ) >=
                                                        detail.breakHoursExceededThreshold;

                                                return (
                                                    <tr
                                                        key={detail.id}
                                                        className={`timesheet-details-table-row${
                                                            !!detail.removed
                                                                ? ' strikeout'
                                                                : ''
                                                        }`}
                                                    >
                                                        {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                            <td className="isadjusted">
                                                                {!!detail.isAdjustment && (
                                                                    <Badge className="p-1">
                                                                        ADJ
                                                                    </Badge>
                                                                )}
                                                            </td>
                                                        )}
                                                        <td className="detailscharge">
                                                            {
                                                                detail.chargeTypeName
                                                            }
                                                        </td>
                                                        <td className="detailsname">
                                                            {!!detail.onBoarding && (
                                                                <span
                                                                    style={{
                                                                        color: 'red',
                                                                        fontWeight:
                                                                            'bold',
                                                                    }}
                                                                >
                                                                    {
                                                                        detail.resourceName
                                                                    }
                                                                </span>
                                                            )}
                                                            {!!!detail.onBoarding && (
                                                                <span>
                                                                    {
                                                                        detail.resourceName
                                                                    }
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td className="detailsnote">
                                                            {!!detail.notes && (
                                                                <div className="userNote">
                                                                    <div className="noteContent">
                                                                        {
                                                                            detail.notes
                                                                        }
                                                                    </div>
                                                                    <div className="noteContentByLine">
                                                                        {`${
                                                                            detail.createdBy
                                                                        }, ${moment(
                                                                            detail.createdOn
                                                                        ).fromNow()}`}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td className="detailsstart">
                                                            {detail.start}
                                                        </td>
                                                        <td className="detailsend">
                                                            {detail.end}
                                                        </td>
                                                        <td className="detailsqty">
                                                            {chargeExceedsThreshold && (
                                                                <i className="fa fa-exclamation-triangle text-warning mr-1"></i>
                                                            )}
                                                            {breakExceedsThreshold && (
                                                                <i className="fa fa-exclamation-triangle text-danger mr-1"></i>
                                                            )}
                                                            <span
                                                                className={cls({
                                                                    'text-danger':
                                                                        !!detail.isBreak,
                                                                    'text-warning font-weight-bold':
                                                                        chargeExceedsThreshold,
                                                                    'text-danger font-weight-bold':
                                                                        breakExceedsThreshold,
                                                                })}
                                                            >
                                                                {parseFloat(
                                                                    detail.quantity
                                                                ).toFixed(2)}
                                                            </span>
                                                        </td>

                                                        {!!isBilling ===
                                                            true && (
                                                            <td className="detailsqty">
                                                                {!!detail.billingQuantity &&
                                                                    parseFloat(
                                                                        detail.billingQuantity
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                            </td>
                                                        )}
                                                        <td className="detailsunits">
                                                            {detail.unitsName}
                                                        </td>
                                                        <td className="detailsbillable">
                                                            {detail.billable}
                                                        </td>
                                                        <td className="detailsbreak">
                                                            {detail.isBreak
                                                                ? detail.isPaidBreak
                                                                    ? 'Paid'
                                                                    : 'Unpaid'
                                                                : ''}
                                                        </td>
                                                        {!!tenantSettings.timesheetAdjustmentsEnabled && (
                                                            <Can
                                                                do="accept"
                                                                on="timesheet"
                                                            >
                                                                <td className="detailsdelete">
                                                                    {!detail.isAdjustment &&
                                                                        details.timesheetStatusId !=
                                                                            TimesheetStatus.Approved && (
                                                                            <FontAwesomeIcon
                                                                                className={`details-remove-button text-danger mt-1 cursor-pointer${
                                                                                    !!detail.removed
                                                                                        ? ' removed'
                                                                                        : ''
                                                                                }`}
                                                                                title="Strike this charge"
                                                                                onClick={() =>
                                                                                    this.removeCharge(
                                                                                        detail.resources
                                                                                    )
                                                                                }
                                                                                icon={
                                                                                    faTrash
                                                                                }
                                                                            />
                                                                        )}
                                                                    {!!detail.isAdjustment &&
                                                                        details.timesheetStatusId !=
                                                                            TimesheetStatus.Approved && (
                                                                            <FontAwesomeIcon
                                                                                className={`details-remove-adjustment-button text-danger mt-1 cursor-pointer${
                                                                                    !!detail.removed
                                                                                        ? ' removed'
                                                                                        : ''
                                                                                }`}
                                                                                title="Remove this adjustment"
                                                                                onClick={() =>
                                                                                    this.removeCharge(
                                                                                        detail.resources
                                                                                    )
                                                                                }
                                                                                icon={
                                                                                    faEraser
                                                                                }
                                                                            />
                                                                        )}
                                                                </td>
                                                            </Can>
                                                        )}
                                                    </tr>
                                                );
                                            })
                                    )}
                                </tbody>
                            </table>
                        </div>
                        {!!tenantSettings.timesheetAdjustmentsEnabled &&
                            !!(adjustments ?? []).length && (
                                <div className="details">
                                    <small className="text-warning font-weight-bold">
                                        ADD ADJUSTMENTS
                                    </small>
                                    <table className="table table-sm table-bordered table-striped adjustment-table">
                                        <thead className="text-muted">
                                            <tr>
                                                <th className="detailscharge">
                                                    Charge
                                                </th>
                                                <th className="detailsname">
                                                    Name
                                                </th>
                                                <th className="detailsnote">
                                                    Notes
                                                </th>
                                                <th className="detailsstart">
                                                    Start
                                                </th>
                                                <th className="detailsend">
                                                    End
                                                </th>
                                                <th className="detailsqty">
                                                    Quantity
                                                </th>
                                                <th className="detailsunits">
                                                    Units
                                                </th>
                                                <th className="detailsbillable">
                                                    Billable
                                                </th>
                                                <th className="detailsbreak">
                                                    Break
                                                </th>
                                                <Can do="accept" on="timesheet">
                                                    <th className="detailsdelete"></th>
                                                </Can>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!(adjustments ?? []).length ? (
                                                <tr>
                                                    <td
                                                        colSpan="8"
                                                        className="text-center"
                                                    >
                                                        No adjustments found.
                                                    </td>
                                                </tr>
                                            ) : (
                                                adjustments
                                                    .filter(
                                                        (x) => x.show == true
                                                    )
                                                    .map((d, i) => {
                                                        //Filter the staff by eligible app groups on the selected charge type.
                                                        let charge_type_user_groups =
                                                            (
                                                                (
                                                                    d.chargeTypeObj ??
                                                                    {}
                                                                )
                                                                    .applicationGroup ??
                                                                {}
                                                            )
                                                                .applicationGroupUserGroups ??
                                                            [];
                                                        let charge_type_user_groups_ids =
                                                            charge_type_user_groups.map(
                                                                (x) =>
                                                                    x.applicationUserGroupId
                                                            );
                                                        let filtered_staff =
                                                            staff.filter(
                                                                (x) => {
                                                                    return !!(
                                                                        x.groupIds.filter(
                                                                            (
                                                                                value
                                                                            ) =>
                                                                                charge_type_user_groups_ids.includes(
                                                                                    value
                                                                                )
                                                                        ) ?? []
                                                                    ).length;
                                                                }
                                                            );

                                                        //Filter the equipment by eligible types on the selected charge type.
                                                        let charge_type_eq_types =
                                                            (
                                                                d.chargeTypeObj ??
                                                                {}
                                                            ).equipmentTypes ??
                                                            [];
                                                        let charge_type_eq_types_ids =
                                                            charge_type_eq_types.map(
                                                                (x) => x.id
                                                            );
                                                        let filtered_equipment =
                                                            equipment.filter(
                                                                (x) =>
                                                                    charge_type_eq_types_ids.includes(
                                                                        x.equipmentTypeId
                                                                    )
                                                            );

                                                        let isSurcharge = false;
                                                        let chargeTypeDefaultValue =
                                                            '';
                                                        if (
                                                            d.chargeTypeObj &&
                                                            d.chargeTypeObj
                                                                .chargeTypeConfigurations &&
                                                            d.chargeTypeObj
                                                                .chargeTypeConfigurations
                                                                .length > 0
                                                        ) {
                                                            const chargeTypeConfiguration =
                                                                d.chargeTypeObj
                                                                    .chargeTypeConfigurations[0];
                                                            isSurcharge =
                                                                chargeTypeConfiguration.isSurcharge;

                                                            if (
                                                                tenantSettings.allowDefaultChargeTypeValues &&
                                                                chargeTypeConfiguration.defaultValue
                                                            ) {
                                                                chargeTypeDefaultValue =
                                                                    chargeTypeConfiguration.defaultValue;
                                                            }
                                                        }

                                                        const numAFADsInSet = d?.chargeTypeObj?.chargeTypeConfigurations[0]?.numAFADsInSet || 1;

                                                        if (!!d.adjustmentType)
                                                            return (
                                                                <tr
                                                                    key={d.id}
                                                                    className="adjustments-details"
                                                                >
                                                                    <td className="detailscharge">
                                                                        <FormGroup className="mb-0">
                                                                            <ValidatedSelect
                                                                                styles={
                                                                                    CompactSelectStyles
                                                                                }
                                                                                minMenuHeight={
                                                                                    100
                                                                                }
                                                                                maxMenuHeight={
                                                                                    200
                                                                                }
                                                                                name="adjustmentChargeTypeDropdown"
                                                                                required
                                                                                hideClear={
                                                                                    true
                                                                                }
                                                                                options={
                                                                                    chargeTypes
                                                                                }
                                                                                getOptionLabel={(
                                                                                    option
                                                                                ) =>
                                                                                    option.description
                                                                                }
                                                                                getOptionValue={(
                                                                                    option
                                                                                ) =>
                                                                                    option.id
                                                                                }
                                                                                value={
                                                                                    (
                                                                                        chargeTypes ??
                                                                                        []
                                                                                    ).find(
                                                                                        (
                                                                                            x
                                                                                        ) =>
                                                                                            x.id ===
                                                                                            d.chargeTypeId
                                                                                    ) ??
                                                                                    ''
                                                                                }
                                                                                onChange={(
                                                                                    selection,
                                                                                    event
                                                                                ) => {
                                                                                    let {
                                                                                        details,
                                                                                    } =
                                                                                        {
                                                                                            ...this
                                                                                                .state,
                                                                                        };
                                                                                    //when switching charge types, clear any previously selected values.
                                                                                    let adj =
                                                                                        new TimesheetDetailsAdjustment();
                                                                                    adj.chargeTypeId =
                                                                                        selection.id;
                                                                                    adj.chargeTypeObj =
                                                                                        selection;

                                                                                    // for quantity, either the config's default value or a safe fallback of 0
                                                                                    if (
                                                                                        adj.chargeTypeObj &&
                                                                                        adj
                                                                                            .chargeTypeObj
                                                                                            .chargeTypeConfigurations &&
                                                                                        adj
                                                                                            .chargeTypeObj
                                                                                            .chargeTypeConfigurations
                                                                                            .length
                                                                                    ) {
                                                                                        const chargeTypeConfiguration =
                                                                                            adj
                                                                                                .chargeTypeObj
                                                                                                .chargeTypeConfigurations[0];

                                                                                        adj.quantity =
                                                                                            chargeTypeConfiguration.defaultValue;
                                                                                    } else {
                                                                                        adj.quantity = 0;
                                                                                    }

                                                                                    adjustments[
                                                                                        i
                                                                                    ] =
                                                                                        adj;
                                                                                    this.setState(
                                                                                        {
                                                                                            details,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                                validationMessage="Charge type is required."
                                                                            />
                                                                        </FormGroup>
                                                                    </td>
                                                                    <td className="detailsname">
                                                                        {!!d.chargeTypeObj &&
                                                                            !!filtered_staff.length && (
                                                                                <FormGroup className="mb-0">
                                                                                    <ValidatedSelect
                                                                                        styles={
                                                                                            CompactSelectStyles
                                                                                        }
                                                                                        minMenuHeight={
                                                                                            100
                                                                                        }
                                                                                        maxMenuHeight={
                                                                                            200
                                                                                        }
                                                                                        name="staffDropdown"
                                                                                        required
                                                                                        hideClear={
                                                                                            true
                                                                                        }
                                                                                        options={
                                                                                            filtered_staff
                                                                                        }
                                                                                        getOptionLabel={(
                                                                                            option
                                                                                        ) =>
                                                                                            option.name
                                                                                        }
                                                                                        getOptionValue={(
                                                                                            option
                                                                                        ) =>
                                                                                            option.id
                                                                                        }
                                                                                        value={
                                                                                            filtered_staff.find(
                                                                                                (
                                                                                                    x
                                                                                                ) =>
                                                                                                    x.id ===
                                                                                                    d.resourceId
                                                                                            ) ??
                                                                                            ''
                                                                                        }
                                                                                        onChange={(
                                                                                            selection,
                                                                                            event
                                                                                        ) => {
                                                                                            let {
                                                                                                adjustments,
                                                                                            } =
                                                                                                {
                                                                                                    ...this
                                                                                                        .state,
                                                                                                };
                                                                                            adjustments[
                                                                                                i
                                                                                            ].resourceId =
                                                                                                selection.id;
                                                                                            adjustments[
                                                                                                i
                                                                                            ].resourceName =
                                                                                                selection.name;
                                                                                            this.setState(
                                                                                                {
                                                                                                    adjustments:
                                                                                                        adjustments,
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                        validationMessage="Employee is required."
                                                                                    />
                                                                                </FormGroup>
                                                                            )}
                                                                        {d.chargeTypeObj &&
                                                                            !!(
                                                                                (
                                                                                    d.chargeTypeObj ??
                                                                                    {}
                                                                                )
                                                                                    .equipmentTypes ??
                                                                                []
                                                                            )
                                                                                .length && (
                                                                                <FormGroup className="mb-0">
                                                                                    {Array.from(Array(numAFADsInSet).keys()).map(index => {
                                                                                        const resource = d.resources && d.resources[index];
                                                                                        
                                                                                        const adjusted = filtered_equipment.map(item => {
                                                                                            const isVisible = !d.resources
                                                                                            || !d.resources.length
                                                                                            || !d.resources.find(r => r.id === item.id)
                                                                                            || ( d.resources.find(r => r.id === item.id) && item.id === resource?.id);

                                                                                            return { 
                                                                                                ...item,
                                                                                                isDisabled: !isVisible,
                                                                                            };
                                                                                        });

                                                                                        return (
                                                                                            <ValidatedSelect
                                                                                                styles={
                                                                                                    CompactSelectStyles
                                                                                                }
                                                                                                minMenuHeight={
                                                                                                    100
                                                                                                }
                                                                                                maxMenuHeight={
                                                                                                    200
                                                                                                }
                                                                                                name={`equipmentDropdown[${index}]`}
                                                                                                required={numAFADsInSet === 1}
                                                                                                key={`equipmentDropdown[${index}]`}
                                                                                                hideClear={
                                                                                                    true
                                                                                                }
                                                                                                options={
                                                                                                    adjusted
                                                                                                }
                                                                                                getOptionLabel={(
                                                                                                    option
                                                                                                ) =>
                                                                                                    option.description
                                                                                                }
                                                                                                getOptionValue={(
                                                                                                    option
                                                                                                ) =>
                                                                                                    option.id
                                                                                                }
                                                                                                value={
                                                                                                    (
                                                                                                        equipment ??
                                                                                                        []
                                                                                                    ).find(
                                                                                                        (
                                                                                                            x
                                                                                                        ) =>
                                                                                                            x.id ===
                                                                                                            resource?.id
                                                                                                    ) ??
                                                                                                    ''
                                                                                                }
                                                                                                onChange={(
                                                                                                    selection,
                                                                                                    event
                                                                                                ) => {
                                                                                                    let {
                                                                                                        adjustments,
                                                                                                    } =
                                                                                                        {
                                                                                                            ...this
                                                                                                                .state,
                                                                                                        };

                                                                                                    adjustments[
                                                                                                        i
                                                                                                    ].resourceId =
                                                                                                        selection.id;

                                                                                                    adjustments[
                                                                                                        i
                                                                                                    ].resourceName =
                                                                                                        selection.description;

                                                                                                    adjustments[i].resources[index] = selection;

                                                                                                    this.setState(
                                                                                                        {
                                                                                                            adjustments:
                                                                                                                adjustments,
                                                                                                        }
                                                                                                    );
                                                                                                }}
                                                                                                validationMessage="Equipment is required."
                                                                                            />
                                                                                        );
                                                                                    })}
                                                                                </FormGroup>
                                                                            )}
                                                                    </td>
                                                                    <td className="detailsnotes">
                                                                        <FormGroup className="mb-0">
                                                                            <Input
                                                                                disabled={
                                                                                    !d.chargeTypeObj
                                                                                }
                                                                                required
                                                                                type="textarea"
                                                                                name="notes"
                                                                                value={
                                                                                    d.notes ??
                                                                                    ''
                                                                                }
                                                                                onChange={(
                                                                                    evt
                                                                                ) => {
                                                                                    let value =
                                                                                        evt
                                                                                            .target
                                                                                            .value;
                                                                                    let {
                                                                                        adjustments,
                                                                                    } =
                                                                                        {
                                                                                            ...this
                                                                                                .state,
                                                                                        };
                                                                                    adjustments[
                                                                                        i
                                                                                    ].notes =
                                                                                        value;
                                                                                    this.setState(
                                                                                        {
                                                                                            adjustments:
                                                                                                adjustments,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <small className="invalid-feedback text-danger">
                                                                                Adjustment
                                                                                notes
                                                                                are
                                                                                required.
                                                                            </small>
                                                                        </FormGroup>
                                                                    </td>
                                                                    <td className="detailsstart">
                                                                        {(
                                                                            d.chargeTypeObj ??
                                                                            {}
                                                                        )
                                                                            .unitsId ==
                                                                            ChargeTypeUnits.Hours &&
                                                                            !isSurcharge && (
                                                                                <TimeEntry
                                                                                    required
                                                                                    name="start"
                                                                                    value={
                                                                                        d.start
                                                                                    }
                                                                                    increment={
                                                                                        d
                                                                                            .chargeTypeObj
                                                                                            .chargeTypeConfigurations[0]
                                                                                            .increment
                                                                                    }
                                                                                    onChange={(
                                                                                        ev
                                                                                    ) =>
                                                                                        this.onAdjustmentTimeEntryChanged(
                                                                                            i,
                                                                                            ev
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                    </td>
                                                                    <td className="detailsend">
                                                                        {(
                                                                            d.chargeTypeObj ??
                                                                            {}
                                                                        )
                                                                            .unitsId ==
                                                                            ChargeTypeUnits.Hours &&
                                                                            !isSurcharge && (
                                                                                <TimeEntry
                                                                                    required
                                                                                    name="end"
                                                                                    value={
                                                                                        d.end
                                                                                    }
                                                                                    increment={
                                                                                        d
                                                                                            .chargeTypeObj
                                                                                            .chargeTypeConfigurations[0]
                                                                                            .increment
                                                                                    }
                                                                                    onChange={(
                                                                                        ev
                                                                                    ) =>
                                                                                        this.onAdjustmentTimeEntryChanged(
                                                                                            i,
                                                                                            ev
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                    </td>
                                                                    <td className="detailsqty">
                                                                        {(
                                                                            d.chargeTypeObj ??
                                                                            {}
                                                                        )
                                                                            .unitsId ===
                                                                            ChargeTypeUnits.Hours &&
                                                                            !isSurcharge && (
                                                                                <>
                                                                                    {parseFloat(
                                                                                        d.quantity ??
                                                                                            0
                                                                                    ) >
                                                                                        16.0 && (
                                                                                        <i className="fa fa-exclamation-triangle text-warning mr-1"></i>
                                                                                    )}
                                                                                    <span
                                                                                        className={cls(
                                                                                            {
                                                                                                'text-warning font-weight-bold':
                                                                                                    parseFloat(
                                                                                                        d?.quantity ??
                                                                                                            0
                                                                                                    ) >
                                                                                                    16.0,
                                                                                            }
                                                                                        )}
                                                                                    >
                                                                                        {d.quantity ??
                                                                                            '-'}
                                                                                    </span>
                                                                                </>
                                                                            )}
                                                                        {((
                                                                            d.chargeTypeObj ??
                                                                            {}
                                                                        )
                                                                            .unitsId ===
                                                                            ChargeTypeUnits.Flat ||
                                                                            isSurcharge) && (
                                                                            <NumericInput
                                                                                className="form-control-sm text-right"
                                                                                name="quantity"
                                                                                required
                                                                                value={
                                                                                    d.quantity ??
                                                                                    chargeTypeDefaultValue
                                                                                }
                                                                                onChange={(
                                                                                    event
                                                                                ) => {
                                                                                    let val =
                                                                                            event
                                                                                                .target
                                                                                                .value,
                                                                                        {
                                                                                            adjustments,
                                                                                        } =
                                                                                            {
                                                                                                ...this
                                                                                                    .state,
                                                                                            };
                                                                                    adjustments[
                                                                                        i
                                                                                    ].quantity =
                                                                                        val;
                                                                                    this.setState(
                                                                                        {
                                                                                            adjustments,
                                                                                        }
                                                                                    );
                                                                                }}
                                                                                type="number"
                                                                                min={0}
                                                                                max={(numAFADsInSet ?? 1) > 1 ? 1 : undefined}
                                                                                step={(numAFADsInSet ?? 1) > 1 ? 1 : 0.5} // Should probably be d.chargeTypeIncrement here
                                                                                preventNegative={
                                                                                    true
                                                                                }
                                                                            />
                                                                        )}
                                                                        {!d.chargeTypeObj &&
                                                                            !isSurcharge && (
                                                                                <span>
                                                                                    {
                                                                                        ' - '
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                    </td>
                                                                    <td className="detailsunits">
                                                                        {(
                                                                            (
                                                                                d.chargeTypeObj ??
                                                                                {}
                                                                            )
                                                                                .units ??
                                                                            {}
                                                                        )
                                                                            .description ??
                                                                            '-'}
                                                                    </td>
                                                                    <td className="detailsbillable">
                                                                        {!!d.chargeTypeObj &&
                                                                            (d
                                                                                .chargeTypeObj
                                                                                .isBillable
                                                                                ? 'Yes'
                                                                                : 'No')}
                                                                        {!d.chargeTypeObj &&
                                                                            '-'}
                                                                    </td>
                                                                    <td className="detailsbreak">
                                                                        {!!d.chargeTypeObj &&
                                                                            (d
                                                                                .chargeTypeObj
                                                                                .isBreak
                                                                                ? d
                                                                                      .chargeTypeObj
                                                                                      .isPaidBreak
                                                                                    ? 'Paid'
                                                                                    : 'Unpaid'
                                                                                : '')}
                                                                        {!d.chargeTypeObj &&
                                                                            '-'}
                                                                    </td>
                                                                    <Can
                                                                        do="accept"
                                                                        on="timesheet"
                                                                    >
                                                                        <td className="detailsdelete">
                                                                            {details.timesheetStatusId !=
                                                                                TimesheetStatus.Approved && (
                                                                                <FontAwesomeIcon
                                                                                    className="text-danger mt-1 cursor-pointer"
                                                                                    title="Remove this charge"
                                                                                    onClick={() => {
                                                                                        let {
                                                                                            adjustments,
                                                                                        } =
                                                                                            {
                                                                                                ...this
                                                                                                    .state,
                                                                                            };
                                                                                        adjustments.splice(
                                                                                            i,
                                                                                            1
                                                                                        );
                                                                                        this.setState(
                                                                                            {
                                                                                                adjustments:
                                                                                                    adjustments,
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    icon={
                                                                                        faTrash
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </td>
                                                                    </Can>
                                                                </tr>
                                                            );
                                                        else
                                                            return (
                                                                <tr key={d.id}>
                                                                    <td className="detailscharge">
                                                                        {
                                                                            d.chargeTypeName
                                                                        }
                                                                    </td>
                                                                    <td className="detailsname">
                                                                        {
                                                                            d.resourceName
                                                                        }
                                                                    </td>
                                                                    <td className="detailsstart"></td>
                                                                    <td className="detailsend"></td>
                                                                    <td className="detailsqty">
                                                                        <>
                                                                            {parseFloat(
                                                                                d.quantity ??
                                                                                    0
                                                                            ) >
                                                                                16.0 && (
                                                                                <i className="fa fa-exclamation-triangle text-warning mr-1"></i>
                                                                            )}
                                                                            <span
                                                                                className={cls(
                                                                                    {
                                                                                        'text-warning font-weight-bold':
                                                                                            parseFloat(
                                                                                                d?.quantity ??
                                                                                                    0
                                                                                            ) >
                                                                                            16.0,
                                                                                    }
                                                                                )}
                                                                            >
                                                                                {d.quantity ??
                                                                                    '-'}
                                                                            </span>
                                                                        </>
                                                                    </td>
                                                                    <td className="detailsunits">
                                                                        {
                                                                            d.unitsName
                                                                        }
                                                                    </td>
                                                                    <td className="detailsbillable">
                                                                        {
                                                                            d.billable
                                                                        }
                                                                    </td>
                                                                    <td className="detailsbreak">
                                                                        {d.isBreak
                                                                            ? d.isPaidBreak
                                                                                ? 'Paid'
                                                                                : 'Unpaid'
                                                                            : ''}
                                                                    </td>
                                                                </tr>
                                                            );
                                                    })
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        {!!tenantSettings.timesheetAdjustmentsEnabled &&
                            (details.timesheetStatusId ==
                                TimesheetStatus.Submitted ||
                                details.timesheetStatusId ==
                                    TimesheetStatus.BillingPayrollRejected) &&
                            isTimesheet === true && (
                                <Can do="accept" on="timesheet">
                                    <FlexCenterRow>
                                        <Button
                                            className="mb-2"
                                            size="sm"
                                            type="button"
                                            color="secondary"
                                            onClick={() => {
                                                let { adjustments } = {
                                                    ...this.state,
                                                };
                                                let newRecord =
                                                    new TimesheetDetailsAdjustment();
                                                adjustments.push(newRecord);
                                                this.setState({
                                                    adjustments,
                                                });
                                            }}
                                        >
                                            <i className="fa fa-pen-square fa-lg mr-2" />
                                            Add Adjustment
                                        </Button>
                                    </FlexCenterRow>
                                </Can>
                            )}
                    </CollapseUnderlineHeader>

                    {!!timesheetInputs.length && (
                        <CollapseUnderlineHeader
                            className="mb-3"
                            headerText="Timesheet Inputs"
                            isOpen={this.state.timesheetInputsOpen}
                            toggleCollapse={() => {
                                this.setState((state) => {
                                    return {
                                        timesheetInputsOpen:
                                            !state.timesheetInputsOpen,
                                    };
                                });
                            }}
                        >
                            {timesheetInputs.map((inp, inpx) => {
                                return (
                                    <table
                                        key={inp.question + inpx}
                                        className="table table-sm table-bordered table-striped"
                                    >
                                        <thead>
                                            <tr>
                                                <th colSpan={3}>
                                                    <h6
                                                        style={{
                                                            marginBottom: 0,
                                                        }}
                                                    >{`${inp.question}`}</h6>
                                                </th>
                                            </tr>
                                        </thead>
                                        <thead className="text-muted">
                                            <tr>
                                                <th>Employee</th>
                                                <th>Details</th>
                                                <th>Answer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(inp.answers ?? []).map(
                                                (ans, ansx) => {
                                                    return (
                                                        <tr
                                                            key={
                                                                ans.employee +
                                                                ans.details +
                                                                ans.value
                                                            }
                                                        >
                                                            <td>{`${ans.employee}`}</td>
                                                            <td>{`${ans.details}`}</td>
                                                            <td>{`${ans.value}`}</td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                );
                            })}
                        </CollapseUnderlineHeader>
                    )}

                    <CollapseUnderlineHeader
                        headerText="Signatures"
                        isOpen={reviewDetailsOpen}
                        toggleCollapse={() =>
                            this.setState({
                                reviewDetailsOpen: !reviewDetailsOpen,
                            })
                        }
                    >
                        <table className="table table-sm table-bordered table-striped">
                            <thead className="text-muted">
                                <tr>
                                    <th>Review Type</th>
                                    <th>Signature Type</th>
                                    <th>Name</th>
                                    <th>Notes</th>
                                    <th>Signed On</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!(details.reviewDetails ?? []).length ? (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            No signatures found.
                                        </td>
                                    </tr>
                                ) : (
                                    details.reviewDetails.map((d, i) => {
                                        return (
                                            <tr key={d.id}>
                                                <td>{d.reviewType}</td>
                                                <td>{d.signatureType}</td>
                                                <td>{d.name}</td>
                                                <td>{d.notes}</td>
                                                <td>{d.signedOn}</td>
                                                <td className="timesheet-details-signature-image">
                                                    {
                                                        d.signatureTypeId !=
                                                            3 &&
                                                            d.signatureTypeId !=
                                                                5 && (
                                                                <img
                                                                    alt="Signature Preview"
                                                                    id="sigPreview"
                                                                    style={{
                                                                        maxWidth:
                                                                            '100%',
                                                                        height: 'auto',
                                                                        border: '1px solid #ccc',
                                                                    }}
                                                                    src={
                                                                        (
                                                                            d ??
                                                                            {}
                                                                        )
                                                                            .signatureData
                                                                    }
                                                                />
                                                            )
                                                        //<Button
                                                        //    type="button"
                                                        //    color={"success"}
                                                        //    className={"btn-sm"}
                                                        //    onClick={() => {
                                                        //        this.setState({
                                                        //            showSignatureModal: true,
                                                        //            selectedReviewSignature: d
                                                        //        });
                                                        //    }}>
                                                        //    <span className="fa fa-signature"></span>
                                                        //</Button>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                            </tbody>
                        </table>
                    </CollapseUnderlineHeader>

                    {
                        <CollapseUnderlineHeader
                            className="mb-3"
                            headerText="Field Notes"
                            isOpen={this.state.notesOpen}
                            toggleCollapse={() =>
                                this.setState({
                                    notesOpen: !this.state.notesOpen,
                                })
                            }
                        >
                            <FormGroup>
                                <FormCheckbox
                                    className="font-weight-bold"
                                    id="doNotPrintFieldNotes"
                                    disabled={!!saving || !isTimesheetApprover}
                                    readOnly={!!saving || !isTimesheetApprover}
                                    checked={
                                        details?.doNotPrintFieldNotes ?? ''
                                    }
                                    onChange={(e) => {
                                        let checked = e.target.checked;
                                        this.setState(
                                            (state) => (
                                                (details.doNotPrintFieldNotes =
                                                    checked),
                                                state
                                            )
                                        );
                                    }}
                                    labelText={'Do Not Print Field Notes'}
                                />
                            </FormGroup>
                            <FormGroup>
                                {details.notes ?? 'No notes to display.'}
                            </FormGroup>
                        </CollapseUnderlineHeader>
                    }
                    {!details.isSelfDispatching && (
                        <CollapseUnderlineHeader
                            className="mb-3"
                            headerText="Job Assignment Workflow Notes"
                            isOpen={this.state.jobAssignmentNotesOpen}
                            toggleCollapse={() =>
                                this.setState({
                                    jobAssignmentNotesOpen:
                                        !this.state.jobAssignmentNotesOpen,
                                })
                            }
                        >
                            <FormGroup>
                                {(details.workflowNotes ?? []).map((x, i) => (
                                    <FlexStartRow key={i}>{x}</FlexStartRow>
                                ))}
                                {!(details?.workflowNotes ?? []).length && (
                                    <span>
                                        No job workflow notes to display.
                                    </span>
                                )}
                            </FormGroup>
                        </CollapseUnderlineHeader>
                    )}
                    {!details.isSelfDispatching && (
                        <CollapseUnderlineHeader
                            className="mb-3"
                            headerText="Cancellation Notes"
                            isOpen={this.state.cancellationNotesOpen}
                            toggleCollapse={() =>
                                this.setState({
                                    cancellationNotesOpen:
                                        !this.state.cancellationNotesOpen,
                                })
                            }
                        >
                            <FormGroup>
                                {(details.cancellationNotes ?? []).map(
                                    (x, i) => (
                                        <FlexStartRow key={i}>{x}</FlexStartRow>
                                    )
                                )}
                                {!(details?.cancellationNotes ?? []).length && (
                                    <span>
                                        No cancellation notes to display.
                                    </span>
                                )}
                            </FormGroup>
                        </CollapseUnderlineHeader>
                    )}
                    {!details.isSelfDispatching && (
                        <CollapseUnderlineHeader
                            className="mb-3"
                            headerText="Billable Notes"
                            isOpen={this.state.displayNotesOpen}
                            toggleCollapse={() =>
                                this.setState({
                                    displayNotesOpen:
                                        !this.state.displayNotesOpen,
                                })
                            }
                        >
                            <FormGroup>
                                <FormCheckbox
                                    className="font-weight-bold"
                                    id="doNotPrintNotesDisplay"
                                    disabled={!!saving || !isTimesheetApprover}
                                    readOnly={!!saving || !isTimesheetApprover}
                                    checked={
                                        details?.doNotPrintNotesDisplay ?? ''
                                    }
                                    onChange={(e) => {
                                        let checked = e.target.checked;
                                        this.setState(
                                            (state) => (
                                                (details.doNotPrintNotesDisplay =
                                                    checked),
                                                state
                                            )
                                        );
                                    }}
                                    labelText={'Do Not Print Billable Notes'}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    disabled={!!saving || !isTimesheetApprover}
                                    readOnly={!!saving || !isTimesheetApprover}
                                    name="notesDisplay"
                                    id="notesDisplayTextArea"
                                    placeholder={
                                        !details?.doNotPrintNotesDisplay
                                            ? 'This note will display on the printed timesheet.'
                                            : 'This note will not display on the printed timesheet.'
                                    }
                                    className={'form-control'}
                                    defaultValue={details.notesDisplay ?? ''}
                                    onChange={(event) => {
                                        let { details } = { ...this.state };
                                        const value = event.target.value;
                                        details.notesDisplay = value;
                                        this.setState({
                                            details: { ...details },
                                        });
                                    }}
                                    rows="5"
                                    maxLength="500"
                                />
                                <small
                                    className={`text-right text-field-count ${
                                        (details?.notesDisplay?.length ?? 0) <
                                        500
                                            ? 'text-success'
                                            : 'text-danger'
                                    }`}
                                >{`${
                                    500 - (details?.notesDisplay?.length ?? 0)
                                } remaining`}</small>
                            </FormGroup>
                        </CollapseUnderlineHeader>
                    )}
                    {
                        <CollapseUnderlineHeader
                            className="mb-3"
                            headerText="Internal Notes"
                            isOpen={this.state.internalNotesOpen}
                            toggleCollapse={() =>
                                this.setState({
                                    internalNotesOpen:
                                        !this.state.internalNotesOpen,
                                })
                            }
                        >
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    disabled={!!saving || !isTimesheetApprover}
                                    readOnly={!!saving || !isTimesheetApprover}
                                    name="notesInternal"
                                    id="notesInternalTextArea"
                                    placeholder="This note will NOT display on the printed time sheet and is for INTERNAL USE only."
                                    className={'form-control'}
                                    defaultValue={details.notesInternal ?? ''}
                                    onChange={(event) => {
                                        let { details } = { ...this.state };
                                        const value = event.target.value;
                                        details.notesInternal = value;
                                        this.setState({
                                            details: { ...details },
                                        });
                                    }}
                                    rows="5"
                                    maxLength="500"
                                />
                                <small
                                    className={`text-right text-field-count ${
                                        (details?.notesInternal?.length ?? 0) <
                                        500
                                            ? 'text-success'
                                            : 'text-danger'
                                    }`}
                                >{`${
                                    500 - (details?.notesInternal?.length ?? 0)
                                } remaining`}</small>
                            </FormGroup>
                        </CollapseUnderlineHeader>
                    }
                    {!!tenantSettings?.timesheetEmailEnabled &&
                        !details.isSelfDispatching && (
                            <CollapseUnderlineHeader
                                className="mb-3"
                                headerText="Email Recipients"
                                isOpen={emailRecipientsOpen}
                                toggleCollapse={() =>
                                    this.setState({
                                        emailRecipientsOpen:
                                            !emailRecipientsOpen,
                                    })
                                }
                            >
                                <FormGroup>
                                    <small className="pl-2 pb-2 text-success">
                                        The below addresses will receive a copy
                                        of this timesheet.
                                    </small>
                                    <EmailRecipients
                                        currentEmailAddress={
                                            currentEmailAddress
                                        }
                                        emailAddresses={
                                            details.emailAddresses ?? []
                                        }
                                        readOnly={true}
                                    />
                                </FormGroup>
                            </CollapseUnderlineHeader>
                        )}
                    <FlexCenterRow className="pb-3">
                        {(isTimesheet === true || isBilling === true) &&
                            details.canEditChargeTypes && (
                                <Button
                                    type="submit"
                                    className="mr-3"
                                    color="primary"
                                    disabled={!!saving}
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={saving ? faCircleNotch : faSave}
                                        className="mr-2"
                                    />
                                    {saving
                                        ? 'Saving, Please Wait...'
                                        : 'Save Timesheet'}
                                </Button>
                            )}
                        {((details ?? {}).timesheetStatusId ===
                            TimesheetStatus.Submitted ||
                            (details ?? {}).timesheetStatusId ===
                                TimesheetStatus.BillingPayrollRejected) &&
                            isTimesheet === true &&
                            isPayroll == false && (
                                <>
                                    {details.canRejectChargeTypes && (
                                        <Can do="reject" on="timesheet">
                                            <Button
                                                className="mr-3"
                                                style={{ marginRight: '10px' }}
                                                color="danger"
                                                onClick={() => {
                                                    if (
                                                        !!showConfirmationPrompt
                                                    ) {
                                                        let confirmed =
                                                            window.confirm(
                                                                'You have unsaved changes.  Would you like to reject this timesheet anyway?'
                                                            );
                                                        !!confirmed &&
                                                            this.getRejectionNote();
                                                    } else {
                                                        this.getRejectionNote();
                                                    }
                                                }}
                                            >
                                                <i className="fa fa-lg fa-times mr-2"></i>
                                                Reject
                                            </Button>
                                        </Can>
                                    )}
                                    {details.canApproveChargeTypes && (
                                        <Can do="accept" on="timesheet">
                                            <Button
                                                className="mr-3"
                                                color="success"
                                                onClick={() => {
                                                    if (
                                                        !!showConfirmationPrompt
                                                    ) {
                                                        let confirmed =
                                                            window.confirm(
                                                                'You have unsaved changes.  Would you like to approve this timesheet anyway?'
                                                            );
                                                        !!confirmed &&
                                                            this.acceptTimesheet();
                                                    } else {
                                                        this.acceptTimesheet();
                                                    }
                                                }}
                                            >
                                                <i className="fa fa-lg fa-check mr-2"></i>
                                                Approve
                                            </Button>
                                        </Can>
                                    )}
                                </>
                            )}

                        {Boolean(
                            timesheetApproved && timesheetStatusRollbackAllowed
                        ) && (
                            <Can do="accept" on="timesheet">
                                <Button
                                    className="mr-3"
                                    color="danger"
                                    onClick={() =>
                                        this.setState({
                                            showRollBackApprovedModal: true,
                                        })
                                    }
                                >
                                    <i className="fa fa-lg fa-undo mr-2"></i>
                                    Reset To Submitted
                                </Button>
                            </Can>
                        )}

                        {Boolean(
                            timesheetExported && timesheetStatusRollbackAllowed
                        ) && (
                            <Can do="accept" on="timesheet">
                                <Button
                                    className="mr-3"
                                    color="danger"
                                    onClick={() =>
                                        this.setState({
                                            showRollBackExportedModal: true,
                                        })
                                    }
                                >
                                    <i className="fa fa-lg fa-undo mr-2"></i>
                                    Reset To Submitted
                                </Button>
                            </Can>
                        )}

                        {(details ?? {}).timesheetStatusId !=
                            TimesheetStatus.Submitted &&
                            (details ?? {}).timesheetStatusId !=
                                TimesheetStatus.BillingPayrollRejected &&
                            this.props.payroll !== true && (
                                <Button
                                    type="button"
                                    color="secondary"
                                    className="mr-3"
                                    disabled={isPrinting}
                                    onClick={() => {
                                        if (!!showConfirmationPrompt) {
                                            let confirmed = window.confirm(
                                                'You have unsaved changes.  Would you like to print this timesheet without your changes?'
                                            );
                                            !!confirmed && this.onPrint();
                                        } else {
                                            this.onPrint();
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={
                                            isPrinting ? faCircleNotch : faPrint
                                        }
                                        className="mr-2"
                                    />
                                    Print
                                </Button>
                            )}

                        {((details ?? {}).timesheetStatusId <
                            TimesheetStatus.Approved ||
                            (details ?? {}).timesheetStatusId ==
                                TimesheetStatus.BillingPayrollRejected) &&
                            isTimesheet === true &&
                            details.canApproveChargeTypes && (
                                <>
                                    <Can do="delete" on="timesheet">
                                        <Button
                                            color="danger"
                                            onClick={() =>
                                                this.getDeletionNote()
                                            }
                                            className="mr-3"
                                        >
                                            <i className="fa fa-lg fa-trash mr-2"></i>
                                            Delete
                                        </Button>
                                    </Can>
                                </>
                            )}
                    </FlexCenterRow>
                </SlideForm>

                <Modal
                    backdrop="static"
                    keyboard={false}
                    isOpen={showJobCityMissingModal}
                >
                    <ModalHeader>Approval Error</ModalHeader>
                    <ModalBody>
                        Job City must have a value before approving.
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="danger"
                            onClick={() => {
                                this.setState({
                                    showJobCityMissingModal: false,
                                });
                            }}
                        >
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    backdrop="static"
                    keyboard={false}
                    isOpen={showRollBackApprovedModal}
                >
                    <ModalHeader>Reset Approved Time Charge?</ModalHeader>
                    <ModalBody>
                        Are you sure you want to reset Approved timesheet{' '}
                        {details.timesheetNumber} to a Submitted state?
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="danger"
                            onClick={() => {
                                this.rollBackTimesheet();
                            }}
                        >
                            OK
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => {
                                this.setState({
                                    showRollBackApprovedModal: false,
                                });
                            }}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    backdrop="static"
                    keyboard={false}
                    isOpen={showRollBackExportedModal}
                >
                    <ModalHeader>Reset Exported Time Charge?</ModalHeader>
                    <ModalBody>
                        Are you sure you want to reset Exported timesheet{' '}
                        {details.timesheetNumber} to a Submitted state?
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="danger"
                            onClick={() => {
                                this.rollBackExportedTimesheet();
                            }}
                        >
                            OK
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => {
                                this.setState({
                                    showRollBackExportedModal: false,
                                });
                            }}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* FYI: timesheet reject is misspelled as a claim. */}
                <Can do="reject" on="timesheet">
                    <Modal
                        backdrop="static"
                        keyboard={false}
                        isOpen={showRejectTimesheetModal}
                    >
                        <ModalHeader>Reject Timesheet</ModalHeader>
                        <ModalBody>
                            <Form id="timeheetRejectionForm">
                                <FormGroup>
                                    <FormLabel
                                        required={true}
                                        text="Notes"
                                    ></FormLabel>
                                    <textarea
                                        disabled={!!saving}
                                        id="timesheetRejectionNotes"
                                        name="timesheetRejectionNotes"
                                        className={cls('form-control', {
                                            'border-danger':
                                                !!rejectionNotesInvalid,
                                        })}
                                        defaultValue={rejectionNotes ?? ''}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            this.setState({
                                                rejectionNotes: value,
                                            });
                                        }}
                                        required
                                        placeholder="Enter notes regarding the rejection."
                                        type="text"
                                        maxLength="500"
                                        rows="5"
                                    />
                                    <small
                                        className="text-danger"
                                        hidden={!rejectionNotesInvalid}
                                    >
                                        Notes are required.
                                    </small>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            {isRejectingTimesheet && (
                                <FontAwesomeIcon
                                    icon={faCircleNotch}
                                    className="fa-spin mr-2"
                                    size="sm"
                                />
                            )}
                            <Button
                                className="d-flex flex-row flex-nowrap align-items-center"
                                color={!!saving ? 'secondary' : 'primary'}
                                disabled={
                                    !!saving ||
                                    !!loading ||
                                    !!isRejectingTimesheet
                                }
                                onClick={this.rejectTimesheet}
                            >
                                {!!this.state.saving && (
                                    <>
                                        <CircularProgress
                                            size={24}
                                            className="saving-button-progress text-success mr-2"
                                        />
                                        <span>Saving, please wait...</span>
                                    </>
                                )}
                                {!this.state.saving && (
                                    <span>Save and Reject Timesheet</span>
                                )}
                            </Button>
                            <Button
                                disabled={!!saving}
                                color="secondary"
                                onClick={() => {
                                    if (!saving) {
                                        this.setState({
                                            selectedRowRejectionNotes: '',
                                            showRejectTimesheetModal: false,
                                        });
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Can>

                <Can do="delete" on="timesheet">
                    <Modal
                        backdrop="static"
                        keyboard={false}
                        isOpen={showDeleteTimesheetModal}
                    >
                        <ModalHeader>Delete Timesheet</ModalHeader>
                        <ModalBody>
                            <Form id="timeheetDeletionForm">
                                <FormGroup>
                                    <FormLabel
                                        required={true}
                                        text="Notes"
                                    ></FormLabel>
                                    <textarea
                                        disabled={!!saving}
                                        id="timesheetDeletionNotes"
                                        name="timesheetDeletionNotes"
                                        className={cls('form-control', {
                                            'border-danger':
                                                !!deletionNotesInvalid,
                                        })}
                                        defaultValue={deletionNotes ?? ''}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            this.setState({
                                                deletionNotes: value,
                                            });
                                        }}
                                        required
                                        placeholder="Enter notes regarding the deletion."
                                        type="text"
                                        maxLength="500"
                                        rows="5"
                                    />
                                    <small
                                        className="text-danger"
                                        hidden={!deletionNotesInvalid}
                                    >
                                        Notes are required.
                                    </small>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            {isDeletingTimesheet && (
                                <FontAwesomeIcon
                                    icon={faCircleNotch}
                                    className="fa-spin mr-2"
                                    size="sm"
                                />
                            )}
                            <Button
                                className="d-flex flex-row flex-nowrap align-items-center"
                                color={!!saving ? 'secondary' : 'primary'}
                                disabled={
                                    !!saving ||
                                    !!loading ||
                                    !!isRejectingTimesheet ||
                                    !!isDeletingTimesheet
                                }
                                onClick={this.deleteTimesheet}
                            >
                                {!!this.state.saving && (
                                    <>
                                        <CircularProgress
                                            size={24}
                                            className="saving-button-progress text-success mr-2"
                                        />
                                        <span>Saving, please wait...</span>
                                    </>
                                )}
                                {!this.state.saving && (
                                    <span>Save and Delete Timesheet</span>
                                )}
                            </Button>
                            <Button
                                disabled={!!saving}
                                color="secondary"
                                onClick={() => {
                                    if (!saving) {
                                        this.setState({
                                            selectedRowDeletionNotes: '',
                                            showDeleteTimesheetModal: false,
                                        });
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Can>

                <Modal
                    backdrop="static"
                    keyboard={false}
                    isOpen={showSignatureModal}
                >
                    <ModalHeader>Signature</ModalHeader>
                    <ModalBody>
                        <img
                            alt="Signature Preview"
                            id="sigPreview"
                            style={{
                                maxWidth: '100%',
                                height: 'auto',
                                border: '1px solid #ccc',
                            }}
                            src={(selectedReviewSignature ?? {}).signatureData}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            disabled={!!saving}
                            color="secondary"
                            onClick={() => {
                                this.setState({ showSignatureModal: false });
                            }}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                <OrganizationContactForm
                    ref={this.contactFormRef}
                    show={showContactsForm}
                    contact={selectedContact}
                    readOnly={contactFormIsReadOnly}
                    onClose={() => {
                        this.setState({
                            showContactsForm: false,
                            saving: false,
                        });
                    }}
                    onChange={(e) => {
                        let { selectedContact } = { ...this.state };
                        selectedContact[e.target.name] = e.target.value;
                        this.setState({ newContact: { ...selectedContact } });
                    }}
                    onCheckedChanged={(e) => {
                        let { selectedContact } = { ...this.state };
                        selectedContact[e.target.name] = e.target.checked;
                        this.setState({ newContact: { ...selectedContact } });
                    }}
                    onContactTypeChanged={(items) => {
                        let { selectedContact } = this.state;
                        selectedContact.contactTypes = items.map(
                            (x) => x.value
                        );
                        this.setState({ newContact: { ...selectedContact } });
                    }}
                    onSaveCallback={this.onOrganizationContactSaved}
                />
            </>
        );
    }

    renderLocationDetails(
        details,
        timesheetApproved,
        countyCaptureEnabled,
        usCounties,
        activeCountyId
    ) {
        return (
            <>
                <div className="locationDetails">
                    <FormGroup className="mb-2">
                        <FormLabel
                            htmlFor="meetingLocation"
                            text="Meeting Location"
                            className="bottom-border"
                        />
                        <div className="d-flex flex-row w-100 align-items-start">
                            {!!(details ?? {}).meetingLocationUrlFormatted && (
                                <a
                                    rel="noopener noreferrer"
                                    className="text-white mr-2 btn btn-sm btn-success p-1"
                                    title="Open in Google Maps"
                                    href={`https://maps.google.com?q=${
                                        (details ?? {})
                                            .meetingLocationUrlFormatted
                                    }`}
                                    target="_blank"
                                >
                                    <i class="fa fa-map-marked-alt" />
                                </a>
                            )}
                            <div className="adj-indicator">
                                {!!details?.meetingLocationChanged && (
                                    <span
                                        title="This job location has been adjusted."
                                        className="badge badge-lg badge-warning adj-badge"
                                        style={{
                                            fontSize: '100% !important',
                                        }}
                                    >
                                        ADJ
                                    </span>
                                )}
                            </div>
                            <div className="d-flex flex-column flex-fill">
                                <FlexStartRow>
                                    <input
                                        style={{
                                            minWidth: '600px',
                                        }}
                                        autoComplete={'off'}
                                        required
                                        id="meetingLocation"
                                        maxLength="100"
                                        name="meetingLocation"
                                        className={cls(
                                            'p-1 form-control form-control-sm w-auto',
                                            {
                                                'form-control-readonly':
                                                    !!timesheetApproved,
                                            }
                                        )}
                                        readOnly={!!timesheetApproved}
                                        disabled={!!timesheetApproved}
                                        value={details?.meetingLocation ?? ''}
                                        onChange={this.onMeetingLocationChanged}
                                    />
                                </FlexStartRow>
                                {!!details?.meetingLocationChanged && (
                                    <small className="mt-1">
                                        <strong className="mr-1 text-muted">
                                            Initial Meeting Location:
                                        </strong>
                                        {`${details?.initialMeetingLocation}`}
                                    </small>
                                )}
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel
                            htmlFor="jobLocation"
                            text={`Job Location${this.getCountyName()}`}
                            className="bottom-border"
                        />
                        <div className="d-flex flex-row w-100 align-items-start">
                            {!!(details ?? {}).jobLocationUrlFormatted && (
                                <a
                                    rel="noopener noreferrer"
                                    className="text-white mr-2 btn btn-sm btn-success p-1"
                                    title="Open in Google Maps"
                                    href={`https://maps.google.com?q=${
                                        (details ?? {}).jobLocationUrlFormatted
                                    }`}
                                    target="_blank"
                                >
                                    <i class="fa fa-map-marked-alt" />
                                </a>
                            )}
                            <div className="adj-indicator">
                                {!!details?.jobLocationChanged && (
                                    <span
                                        title="This job location has been adjusted."
                                        className="badge badge-lg badge-warning adj-badge"
                                        style={{
                                            fontSize: '100% !important',
                                        }}
                                    >
                                        ADJ
                                    </span>
                                )}
                            </div>
                            <div className="d-flex flex-column flex-fill">
                                <FlexStartRow>
                                    <FormLabel
                                        htmlFor="jobLocation"
                                        text="Address"
                                    />

                                    <input
                                        style={{
                                            minWidth: '600px',
                                        }}
                                        autoComplete={'off'}
                                        required
                                        id="jobLocation"
                                        maxLength="100"
                                        name="jobLocation"
                                        className={cls(
                                            'p-1 form-control form-control-sm w-auto',
                                            {
                                                'form-control-readonly':
                                                    !!timesheetApproved,
                                            }
                                        )}
                                        readOnly={!!timesheetApproved}
                                        disabled={!!timesheetApproved}
                                        value={details?.jobLocation ?? ''}
                                        onChange={this.onJobLocationChanged}
                                    />

                                    {countyCaptureEnabled && (
                                        <FormGroup className="w-25 ml-2 flex-row">
                                            {Boolean(
                                                details.usCountyId &&
                                                    details.usCountyId !=
                                                        details.jobLocationCountyId
                                            ) && (
                                                <Badge
                                                    color="warning"
                                                    className="adj-badge"
                                                >
                                                    ADJ
                                                </Badge>
                                            )}

                                            <FormLabel
                                                htmlFor="usCountyId"
                                                text="County"
                                            />

                                            <ValidatedSelect
                                                name="usCountyId"
                                                className="smallReactSelect form-control-sm"
                                                hideClear={false}
                                                options={usCounties.filter(
                                                    (c) =>
                                                        c.usStateId ==
                                                        details.jobLocationStateId
                                                )}
                                                getOptionLabel={(option) =>
                                                    option.name
                                                }
                                                getOptionValue={(option) =>
                                                    option.id
                                                }
                                                value={
                                                    (usCounties ?? []).find(
                                                        (c) =>
                                                            c.id ===
                                                            activeCountyId
                                                    ) ?? ''
                                                }
                                                onChange={(selection) => {
                                                    let { details } =
                                                        this.state;

                                                    if (selection) {
                                                        details.usCountyId =
                                                            selection.id;
                                                    } else {
                                                        details.usCountyId =
                                                            null;
                                                    }

                                                    this.setState({
                                                        details,
                                                    });
                                                }}
                                            />
                                        </FormGroup>
                                    )}
                                </FlexStartRow>
                                {!!details?.jobLocationChanged && (
                                    <small className="mt-1">
                                        <strong className="mr-1 text-muted">
                                            Initial Job Location:
                                        </strong>
                                        {`${details?.initialJobLocation}`}
                                    </small>
                                )}
                            </div>
                        </div>
                    </FormGroup>
                </div>
            </>
        );
    }
}
